import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
@Component({
  selector: 'app-loadin-extra-data',
  templateUrl: './loadin-extra-data.component.html',
  styleUrls: ['./loadin-extra-data.component.css'],
})
export class LoadinExtraDataComponent implements OnInit {
  message: string;
  aboveCmpnnt = false;

  constructor(private _loadingService: LoadingService) {}

  ngOnInit() {
    this.getMessage();
  }

  getMessage() {
    this.message = this._loadingService.getMessage();
    if (this.message) {
      console.log('LoadinExtraDataComponent' + this.message);
      this.aboveCmpnnt = true;
    }
  }
}

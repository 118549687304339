import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ReplaySessionService } from './replay-session.service';

export interface AlertMessage {
  text: string;
  type: string;
  timeout: number;
}

@Injectable()
export class AlertService {
  private subject = new Subject<AlertMessage>();
  private defaultTimeout = 3000;
  private keepAfterNavigationChange = false;

  constructor(
    private router: Router,
    private replaySession: ReplaySessionService
  ) {
    // clear alert message on route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  clear() {
    this.subject.next();
  }

  success(
    message: string,
    keepAfterNavigationChange = false,
    timeout = this.defaultTimeout
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message, timeout: timeout });
  }

  // FIXME get error insted of string for better error handling
  error(
    message: string,
    keepAfterNavigationChange = false,
    timeout = this.defaultTimeout
  ) {
    if (message) {
      this.replaySession.sendEventToReplaySession('Alert Error', new Error(message));
      this.replaySession.sendErrorToReplaySession(new Error(message));
      console.error('message', message);
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({
        type: 'error',
        text: message,
        timeout: timeout,
      });
    }
  }

  getMessage(): Observable<AlertMessage> {
    return this.subject.asObservable();
  }
}

<div class="row">
  <a
    class="col-xs-1 search-icon"
    type="submit"
    (click)="selectAddress()"
    id="search-addr"
    ><img src="assets/images/search.png" style="padding-top: 7px; width: 25px"
  /></a>
  <div class="form-group col-xs-11">
    <input
      *ngIf="searchControl"
      [placeholder]="insert"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      class="map-search"
      #search
      [formControl]="searchControl"
    />
  </div>
</div>

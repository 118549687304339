import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-speech-bubble',
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.css'],
})
export class SpeechBubbleComponent implements OnInit {
  @Input()
  isShow = false;

  @Input()
  header = 'header';

  @Input()
  element: Element;

  ngOnInit() {
    if (this.element) {
      this.element.addEventListener('click', () => {
        this.isShow = !this.isShow;
      });
    }
  }
}

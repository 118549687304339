import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoadingService {
  private message: string;
  private subject: Subject<any> = new Subject<any>();

  getLoading(): Observable<any> {
    return this.subject.asObservable();
  }

  startLoading(): void {
    this.subject.next(true);
  }

  stopLoading(): void {
    this.subject.next(false);
  }

  setMessage(msg: string) {
    this.message = msg;
  }

  clearMessage() {
    this.message = undefined;
  }

  getMessage() {
    return this.message;
  }
}

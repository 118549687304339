import { Injectable } from '@angular/core';
import { UserStatus } from '../models/userStatus';
import { Observable } from 'rxjs/Observable';
import { HttpService } from './http.service';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';
import { of } from 'rxjs';

@Injectable()
export class StatusService {
  constructor(
    private httpService: HttpService,
    private _urlService: UrlService
  ) {}

  userStatus: UserStatus = new UserStatus();
  STUBUserStatus: UserStatus;

  getUserBalance(
    startTime: Date,
    endTime: Date,
    isShowLoading: boolean = true
  ): Observable<any> {
    const body = { startTime: startTime, endTime: endTime };
    return from(
      this.httpService.post(
        this._urlService.nodeUrl() + 'api/getUserStatus',
        body,
        isShowLoading
      )
    );
  }

  getStubUserStatus(): Observable<UserStatus> {
    this.STUBUserStatus = new UserStatus();
    console.log(this.STUBUserStatus);
    return of(this.STUBUserStatus);
  }
}

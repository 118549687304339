export class UserStatus {
  balanceOfCredit: number;
  balanceDetails: Balance[];
  coupons: Coupon[];
  gasFills: GasFill[];
  cashBanks: CashBank[];

  public constructor(init?: Partial<UserStatus>) {
    Object.assign(this, init);
  }
}

export class Balance {
  ORDER_ID: number;
  AMOUNT: number;
  TIME_CHARGED: Date;
  //TODO NOTES exists???
  NOTES
  public constructor(init?: Partial<Balance>) {
    Object.assign(this, init);
  }
}

export class Coupon {
  VALIDITY_END: Date;
  DATE_REDEEMED: Date;
  REDEEMED_ON_ORDER_ID: number;
  CREDIT_AMOUNT_TYPE: string;
  NOTE: string;

  public constructor(init?: Partial<Coupon>) {
    Object.assign(this, init);
  }
}

export class GasFill {
  COUPON_EXPIRE_DATE: Date;
  REFILL_DATE: Date;
  FILLED_IN_ORDER_ID: number;
  REDEEMED_IN_ORDER_ID: number;

  public constructor(init?: Partial<GasFill>) {
    Object.assign(this, init);
  }
}

export class CashBank {
  EXPIRY: Date;
  ADDED: Date;
  AMOUNT: number;

  public constructor(init?: Partial<CashBank>) {
    Object.assign(this, init);
  }
}

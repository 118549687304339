import { Injectable } from '@angular/core';
import { Period } from '../enums';
import { Observable } from 'rxjs/Observable';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SearchByLocationOptions } from '../enums';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';
import { timeSlot, CarInParking } from '../models/car';
import { ErrorResponse } from '../models/error-response';
import { MapLocation } from '../models/map-location';
import { EmployeeOrder, Order } from '../models/order';
import { Parking } from '../models/parking';
import { TimeSlotsPipe } from '../pipes/time-slots.pipe';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { currentDate, newDate } from '../shared/function';
import { DatesService } from './dates.service';
import { HttpService } from './http.service';
import { LoadingService } from './loading.service';
import { ParkingsService } from './parkings.service';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ReplaySessionService } from './replay-session.service';

class OrderBody {
  orderId: number;
  planDatetimeStart: string;
  planDatetimeEnd: string;
  hoursPriceId: number;
  orderStatus: number;
  startParkingId: number;
  endParkingId: number;
  insuranceType: number;
  createSource = 3;
  totalPrice: number;
  carTotalSeats: number;
  carType: string;
  carNumber: number;

  updateSource: number;
  unlockCode: number;
  isInsuranceRequired: boolean;
  cancelPrice: number;

  isIrregularOrder = false;
  realDatetimeStart: string;
  realDatetimeEnd: string;
  lateMinutes: number;
  lastCarLocation;
  priceDetails: PriceDetails[];
  markedAsFinished: string;
  orderStatusHebrew: string;
  notifyNewPrice: boolean;

  isOfficeDrive: boolean;
  officeDriveType: number;
  officeDriveReason: string;
}

class PriceDetails {
  detailsTypeHebrew: string;
  multiplier: number;
  pricePerUnit: number;
  total: number;
}

@Injectable()
export class OrderService {
  //private
  private order: EmployeeOrder | Order;
  baseOrderUrl = 'orders/';
  ordersList: (EmployeeOrder | Order)[];

  isShowPrice: boolean;
  openCurrenOrder: number = null;

  private _isOrderForNow: boolean;
  public get isOrderForNow(): boolean {
    return this._isOrderForNow;
  }
  public set isOrderForNow(v: boolean) {
    this._isOrderForNow = v;
  }

  isOrderForClosedShabbat = false;
  isOfficeOrder = false;

  isLastYearAndNextMonth: boolean;
  get getLastYearAndNextMonth() {
    if (this.isLastYearAndNextMonth) {
      this.isLastYearAndNextMonth = false;
      return true;
    } else return false;
  }

  get IsEditOrder(): boolean {
    return this.order.id ? true : false;
  }

  set Period(period: Period) {
    this.order.period = period;
  }
  get Period(): Period {
    return this.order.period;
  }

  set Dates(dates: Date[]) {
    this.StartTime = dates[0];
    this.EndTime = dates[1];
  }
  get lastYearOrders(): timeSlot {
    const start =
      moment(
        new Date(currentDate()).setFullYear(
          new Date(currentDate()).getFullYear() - 1
        )
      ).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const end = moment(currentDate()).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    return { start: start, end: end, odt: null };
  }
  get futureOrders(): timeSlot {
    const start = moment(currentDate()).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const end =
      moment(currentDate()).add(1, 'M').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    return { start: start, end: end, odt: null };
  }

  get lastYearAndNextMonth() {
    const start =
      moment(
        new Date(currentDate()).setFullYear(
          new Date(currentDate()).getFullYear() - 1
        )
      ).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const end =
      moment(currentDate()).add(1, 'M').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    return { start: start, end: end, odt: null };
  }

  get OrderTimeSlot(): timeSlot {
    return this.getOrderTimeSlot(this.order);
  }

  set StartTime(date: Date) {
    this.order.startTime = date;
  }

  set EndTime(date: Date) {
    this.order.endTime = date;
  }

  set StartParking(parking: Parking) {
    this.order.startParking = parking;
  }

  set Car(car: CarInParking) {
    this.order.car = car;
  }

  get Order() {
    return this.order;
  }

  notifyNewPrice = false;
  futureOrder: (EmployeeOrder | Order)[];
  historyOrder: (EmployeeOrder | Order)[];
  isOrderChanged = false;
  searchByOption: SearchByLocationOptions =
    SearchByLocationOptions.CurrentLocation;

  constructor(
    private _httpService: HttpService,
    private httpClient: HttpClient,
    private _datepipe: DatePipe,
    private _parkingsService: ParkingsService,
    private _loadingService: LoadingService,
    private _TimeSlotsPipe: TimeSlotsPipe,
    private _datesService: DatesService,
    private _urlService: UrlService,
    private replaySessionService: ReplaySessionService,
  ) {
    this.order = new Order();
    this._parkingsService.registerFromOrderService(() => {
      return this.order;
    });
    this._TimeSlotsPipe.registerFromOrderService(() => {
      return this.order;
    });
  }

  changeCurrentOrder(order: EmployeeOrder | Order): void {
    this.order = order;
  }

  saveOrder(isEmpOrder: boolean = false): Promise<EmployeeOrder | Order> {
    if (!isEmpOrder) {
      this.updateIfOrderForNow();
    }
    const url = !isEmpOrder ? this.baseOrderUrl : 'EmpOrders/';
    return this._httpService
      .post(
        this._urlService.baseUrl() + url + 'add?ShouldSave=true&pretty=0',
        this.custToOrderBody(),
        true,
      )
      .then((data: OrderBody) => {
        const order = this.addStartPakingToOrder(
          this.custFromOrderBody(data),
          data.startParkingId,
        );
        this._TimeSlotsPipe.currentOrderTimeSlot = this.OrderTimeSlot;
        this._parkingsService.addCatchedTimeSlotOfNewOrder(order);
        this.order = new Order();
        if (this.ordersList) {
          this.ordersList.unshift(order);
        } else {
          this.getAllOrders().subscribe();
        }

        this.isOrderForNow = null;

        return order;
      });
  }

  async updateOrder(
    isSave: boolean,
    order = this.order,
    isShowLoading: boolean = true,
    isIrregularOrder: boolean = false
  ): Promise<EmployeeOrder | Order> {
    this.updateIfOrderForNow();

    const orderBody: OrderBody = this.custToOrderBody(order);

    if (isIrregularOrder) {
      orderBody.isIrregularOrder = isIrregularOrder;
    }

    orderBody.updateSource = orderBody.createSource;
    const updatedOrder = this.custFromOrderBody(
      await this._httpService.post(
        this._urlService.baseUrl() +
        this.baseOrderUrl +
        'Update?ShouldSave=true&pretty=1',
        orderBody,
        isShowLoading
      )
    );

    if (this.ordersList) {
      // insert the new order instead of the last one:
      const lastOrder = this.ordersList.find((o) => o.id == updatedOrder.id);
      this._parkingsService.removeCatchedTimeSlotOfCancelledOrder(lastOrder);

      this.ordersList[this.ordersList.indexOf(lastOrder)] = updatedOrder;
    }
    this._parkingsService.addCatchedTimeSlotOfNewOrder(updatedOrder);
    return updatedOrder;
  }

  cancellOrder(
    reason: string,
    orderId: number = this.order.id,
    isShowLoading: boolean = true
  ): Observable<Order> {
    const body = {
      orderId: orderId,
      updateSource: 3,
      cancelReason: 1,
      notes: reason,
    };
    this.replaySessionService.sendEventToReplaySession('CancelOrder', body)
    return (
      from(
        this._httpService.post(
          this._urlService.baseUrl() + this.baseOrderUrl + 'CancelOrder',
          body,
          isShowLoading
        )
      )
        .pipe(
          map((isSaved: boolean) => {
            if (isSaved) {
              const lastOrder = this.ordersList.find((o) => o.id == orderId);
              lastOrder.isActive = false;

              this._parkingsService.removeCatchedTimeSlotOfCancelledOrder(
                lastOrder
              );

              return lastOrder;
            } else throw new ErrorResponse('אירעה שגיאה, נסה שנית');
          })
        )
    );
  }

  getClosedShabbatOrderTime(
    time: Date,
    isShowLoading: boolean = true
  ): Observable<timeSlot> {
    const body = {
      val: this._datesService.dateToString(time),
    };
    return from(
      this._httpService.put(
        this._urlService.baseUrl() + this.baseOrderUrl + 'ShabbatOrderTime',
        body,
        isShowLoading
      )
    );
  }

  getAllOrders(
    isHistory: boolean = null,
    isShowLoading: boolean = true,
  ): Observable<(EmployeeOrder | Order)[]> {
    console.log(this.ordersList);
    if (isShowLoading) this._loadingService.startLoading();

    const mappedOrders = this.getOrders(this.lastYearAndNextMonth, false).pipe(
      tap(
        (orders: (EmployeeOrder | Order)[]) => {
          this.ordersList = orders.sort((o1, o2) =>
            o1.id > o2.id ? -1 : o1.id == o2.id ? 0 : 1
          );
        }
      )
    );

    return mappedOrders
      .pipe(
        map((mappedOrders: (EmployeeOrder | Order)[]) => {
          if (isHistory == null) {
            return mappedOrders;
          }

          this.futureOrder = mappedOrders.filter(
            (o) =>
              //return future orders:
              o.startTime.getTime() > currentDate().getTime()
          );

          this.historyOrder = mappedOrders.filter(
            (o) =>
              //return history orders:
              (o.startTime.getTime() <= currentDate().getTime() &&
                o.endTime.getTime() <= currentDate().getTime()) ||
              !o.isActive
          );
        }),
        tap((orders: (EmployeeOrder | Order)[]) => {
          if (isShowLoading) this._loadingService.stopLoading();

          console.log('finish get all orders', orders);
        })
      );
  }

  getFutureOrders(isShowLoading: boolean = true): Observable<(EmployeeOrder | Order)[]> {
    const futureOrdersTimeSlot = new timeSlot();
    futureOrdersTimeSlot.start =
      moment(new Date(currentDate())).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    futureOrdersTimeSlot.end =
      moment(currentDate()).add(1, 'M').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    console.log(futureOrdersTimeSlot);
    return this.getOrders(futureOrdersTimeSlot, isShowLoading).pipe(
      map(
        (orders: (EmployeeOrder | Order)[]) => {
          this.futureOrder = orders;
          return orders;
        }
      )
    );
  }

  getHistoryOrders(
    historyTimeSlot: timeSlot,
    isShowLoading: boolean = true
  ): Observable<(EmployeeOrder | Order)[]> {
    return this.getOrders(historyTimeSlot, isShowLoading).pipe(
      map(
        (orders: (EmployeeOrder | Order)[]) => {
          this.historyOrder = orders;
          return orders;
        }
      )
    );
  }

  orderIsDoneReport(orderId: number, isWashed?: boolean): Promise<any> {
    const body: { orderId: number; updateSource: number; isWashed?: boolean } =
    {
      orderId: orderId,
      updateSource: 3,
    };

    if (isWashed) {
      body.isWashed = isWashed;
    }

    return this._httpService
      .post(
        this._urlService.baseUrl() + this.baseOrderUrl + 'OrderIsDone',
        body
      )
      .then(() => {
        if (this.ordersList) {
          const doneOrder = this.ordersList.find(
            (o) => o.id === orderId
          );
          if (doneOrder) {
            // doneOrder.isActive = false;
            this.ordersList[this.ordersList.indexOf(doneOrder)].isActive =
              false;
            doneOrder.isActive = false;
          }
        }
      });
  }

  getActiveOrders(): Observable<(EmployeeOrder | Order)[]> {
    return this.getAllOrders().pipe(
      map((orders: (EmployeeOrder | Order)[]) =>
        orders.filter((o) => o.isActive)
      )
    );
  }

  async getActiveUnlockCode(orderID: number): Promise<string> {
    const body = { OrderID: orderID };
    const headers = this._httpService.jwt()
    await this._httpService.asyncVerifyExistenceOfAccessToken()
    return this.httpClient.put(this._urlService.baseUrl() + this.baseOrderUrl + 'GetCurrentUnlock', body, { headers, responseType: 'text' }).toPromise();
  }

  getCurrentOrder(isShowLoading: boolean = true): Observable<(EmployeeOrder | Order)[]> {
    const timeSlots: timeSlot = <timeSlot>{
      start: this._datesService.dateToString(
        new Date(currentDate().getTime() - AppGlobals.TIMES.MINUTES_IN_TIME * 5)
      ),
      end: this._datesService.dateToString(
        new Date(currentDate().getTime() + AppGlobals.TIMES.MINUTES_IN_TIME * 5)
      ),
    };

    return this.getOrders(timeSlots, isShowLoading)
      .pipe(
        tap((orders: (EmployeeOrder | Order)[]) => {
          if (orders.length && this.ordersList)
            orders.forEach((order) => {
              const index = this.ordersList.indexOf(
                this.ordersList.find((o) => o.id == order.id)
              );
              if (index == -1) this.ordersList.push(order);
              else this.ordersList[index] = order;
            });
        }),
        map((orders: (EmployeeOrder | Order)[]) => {
          const activeOrders = orders.filter((o) => o.isActive);
          if (activeOrders && activeOrders.length) return activeOrders;
          return null;
        })
      );
  }

  getOrderForDamageReport(): Observable<EmployeeOrder | Order> {
    const minutes: number = AppGlobals.MINUTES_FOR_DAMAGE_REPORT;
    const startRange: Date = new Date(
      currentDate().getTime() - minutes * AppGlobals.TIMES.MINUTES_IN_TIME
    );
    const endRange: Date = new Date(
      currentDate().getTime() + minutes * AppGlobals.TIMES.MINUTES_IN_TIME
    );

    const ordersRange: timeSlot = this._datesService.datesToTimeSlot(
      startRange,
      endRange
    );

    return this.getOrders(ordersRange).pipe(
      map((orders: (EmployeeOrder | Order)[]) => {
        if (orders.length)
          return orders.find(
            (o) =>
              o.isActive == true &&
              startRange.getTime() < currentDate().getTime() &&
              !o.realDatetimeStart
          );
        return null;
      })
    );
  }

  getOrderForDamageReportAfter(): Observable<(EmployeeOrder | Order)[]> {
    const startRange: Date = new Date(
      currentDate().getTime() - 120 * AppGlobals.TIMES.MINUTES_IN_TIME
    );
    const endRange: Date = new Date(
      currentDate().getTime() + 5 * AppGlobals.TIMES.MINUTES_IN_TIME
    );

    const ordersRange: timeSlot = this._datesService.datesToTimeSlot(
      startRange,
      endRange
    );

    return this.getOrders(ordersRange);
  }

  getOrderById(
    orderId: number,
    isLoadAgain: boolean = false
  ): Observable<EmployeeOrder | Order> {
    let order: EmployeeOrder | Order;

    //if all orders was already loaded, load again:
    if (
      isLoadAgain &&
      (order = this.futureOrder.find((o) => o.id == orderId))
    ) {
      const timeSlot: timeSlot = this.getOrderTimeSlot(order);
      return this.getOrders(timeSlot).pipe(
        map((orders: (EmployeeOrder | Order)[]) => {
          const reloadedOrder = orders.find((o) => o.id == order.id);
          if (reloadedOrder) {
            this.futureOrder[this.futureOrder.indexOf(order)] = reloadedOrder;
            return reloadedOrder;
          }
          Observable.throw(new ErrorResponse('ההזמנה לא נמצאה'));
        })
      );
    } else
      return this.getAllOrders().pipe(
        map((orders: (EmployeeOrder | Order)[]) =>
          orders.find((o) => o.id == orderId)
        )
      );
  }

  calculateOrderPrice(): Observable<number> {
    this.updateIfOrderForNow();

    let orderPriceObs: Observable<OrderBody>;
    if (this.IsEditOrder)
      orderPriceObs = from(
        this._httpService.post(
          this._urlService.baseUrl() +
          this.baseOrderUrl +
          'Update?ShouldSave=false&pretty=1',
          this.custToOrderBody(),
          true
        )
      );
    else
      orderPriceObs = from(
        this._httpService.post(
          this._urlService.baseUrl() +
          this.baseOrderUrl +
          'add?ShouldSave=false&pretty=0',
          this.custToOrderBody(),
          true
        )
      );

    this.order.isInsuranceRequired = false;
    console.log(this.IsEditOrder);
    orderPriceObs = from(
      this._httpService.post(
        this._urlService.baseUrl() + this.baseOrderUrl + 'Calc',
        this.custToOrderBody(),
        true
      )
    );

    return orderPriceObs.pipe(
      map((data: OrderBody) => {
        this.notifyNewPrice = data.notifyNewPrice;
        console.log(data.notifyNewPrice);
        return data.totalPrice;
      })
    );
  }

  getOrderTimeSlot(order: Order): timeSlot {
    return this._datesService.datesToTimeSlot(order.startTime, order.endTime);
  }

  updateIfOrderForNow() {
    if (this.isOrderForNow) {
      const orderLenghInTime: number =
        this.order.endTime.getTime() - this.order.startTime.getTime();
      this.order.startTime = new Date(
        currentDate().getTime() + AppGlobals.TIMES.MINUTES_IN_TIME
      );
      this.order.endTime = new Date(
        this.order.startTime.getTime() + orderLenghInTime
      );
      this.Order.startTime.setSeconds(0);
      this.Order.endTime.setSeconds(0);
    }
  }

  sendTravelDetails(orderId: number): Observable<any> {
    const body = {
      orderId,
    };
    return from(
      this._httpService.post(this._urlService.baseUrl() + 'orderDetails', body)
    );
  }

  private custToOrderBody(order: any = this.order): OrderBody {
    this.order = order;
    const startDate: string = this._datepipe.transform(
      this.order.startTime,
      'yyyy-MM-ddTHH:mm:ss'
    );
    const endDate: string = this._datepipe.transform(
      this.order.endTime,
      'yyyy-MM-ddTHH:mm:ss'
    );
    console.log('dates', startDate, endDate);


    if (!(order instanceof EmployeeOrder)) {
      return <OrderBody>{
        planDatetimeStart: startDate,
        planDatetimeEnd: endDate,
        hoursPriceId: this.order.period,
        orderStatus: order.isActive ? 2 : 1,
        startParkingId: this.order.startParking.id,
        createSource: 3,
        updateSource: 3,
        orderId: order.id,
        isInsuranceRequired: order.isInsuranceRequired,
        isIrregularOrder: order.isIrregularOrder,
        lateMinutes: order.lateMinutes,
        notifyNewPrice: order.notifyNewPrice,
        carNumber: order.car.carNumber,
      };
    } else {
      const isOfficeDrive = order.isOfficeDrive;
      const officeDriveType = order.officeDriveType;
      const officeDriveReason = order.officeDriveReason;
      return <OrderBody>{
        planDatetimeStart: startDate,
        planDatetimeEnd: endDate,
        hoursPriceId: this.order.period,
        orderStatus: order.isActive ? 2 : 1,
        startParkingId: this.order.startParking.id,
        createSource: 3,
        updateSource: 3,
        orderId: order.id,
        isInsuranceRequired: order.isInsuranceRequired,
        isIrregularOrder: order.isIrregularOrder,
        lateMinutes: order.lateMinutes,
        notifyNewPrice: order.notifyNewPrice,
        carNumber: order.car.carNumber,
        isOfficeDrive: isOfficeDrive,
        officeDriveType: officeDriveType,
        officeDriveReason: officeDriveReason,
      };
    }
  }

  private custFromOrderBody(orderBody: OrderBody): EmployeeOrder | Order {
    const order: Order = new Order();

    order.startTime = newDate(orderBody.planDatetimeStart);
    order.endTime = newDate(orderBody.planDatetimeEnd);

    order.finalPrice = orderBody.totalPrice;
    order.id = orderBody.orderId;

    order.car = new CarInParking();
    order.car.amountOfSeats = orderBody.carTotalSeats;
    order.car.carType = orderBody.carType;
    order.car.carNumber = orderBody.carNumber;

    order.period = Period[Period[orderBody.hoursPriceId]];
    order.unlockCode = orderBody.unlockCode;
    order.isActive = orderBody.orderStatus == 2 && !orderBody.markedAsFinished;

    order.startParking = this._parkingsService.getParkingById(
      orderBody.startParkingId
    );
    order.isInsuranceRequired = orderBody.isInsuranceRequired;
    order.cancelPrice = orderBody.cancelPrice;
    order.realDatetimeStart = orderBody.realDatetimeStart
      ? newDate(orderBody.realDatetimeStart)
      : null;
    order.realDatetimeEnd = orderBody.realDatetimeEnd
      ? newDate(orderBody.realDatetimeEnd)
      : null;

    order.lateMinutes = orderBody.lateMinutes;
    order.isCancelled = orderBody.orderStatus == 1;
    order.orderStatusHebrew = orderBody.orderStatusHebrew;
    order.notifyNewPrice = orderBody.notifyNewPrice;

    order.markedAsFinished = newDate(orderBody.markedAsFinished);
    order.status = orderBody.orderStatus;
    order.priceDetails = orderBody.priceDetails;
    order['officeDriveReason'] = orderBody.officeDriveReason;
    order.lastCarLocation = orderBody.lastCarLocation
      ? <MapLocation>{
        lat: orderBody.lastCarLocation.latitude,
        lon: orderBody.lastCarLocation.longitude,
      }
      : null;

    if (orderBody.isOfficeDrive) {
      const employeeOrder = new EmployeeOrder(
        orderBody.isOfficeDrive,
        orderBody.officeDriveType,
        order
      );
      return employeeOrder;
    } else {
      return order;
    }
  }

  private addStartPakingToOrder(order: EmployeeOrder | Order, startParkingId: number): EmployeeOrder | Order {
    const parking = this._parkingsService.getParkingById(startParkingId);
    order.startParking = parking;
    return order;
  }

  private getOrders(
    ordersTimesSlots: timeSlot,
    isShowLoading: boolean = true
  ): Observable<(EmployeeOrder | Order)[]> {
    return from(
      this._httpService.put(
        this._urlService.baseUrl() + this.baseOrderUrl + '?pretty=0',
        ordersTimesSlots,
        isShowLoading
      )
    ).pipe(
      map((orders: OrderBody[]) => {
        console.log(orders);
        return orders.map((o) => {
          const order = this.custFromOrderBody(o);
          return order;
        });
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToSelectGroup',
})
export class EnumToSelectGroupPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const keys = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({ id: enumMember, title: args[value[enumMember]] });
        // Uncomment if you want log
        // console.log("enum member: ", value[enumMember]);
      }
    }
    return keys;
  }
}

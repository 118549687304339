import {
  Component,
  OnInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../services/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IrregularOrderService } from '../../services/irregular-order.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../services/url.service';
import { CarInParking, timeSlot } from '../../models/car';
import { ErrorResponse } from '../../models/error-response';
import { MapLocation } from '../../models/map-location';
import { EmployeeOrder, OfficeOrderTypeDescriptionHeEnum, Order } from '../../models/order';
import { Parking } from '../../models/parking';
import { AlertService } from '../../services/alert.service';
import { GoogleMapsService } from '../../services/google-maps.service';
import { HttpService } from '../../services/http.service';
import { LoadingService } from '../../services/loading.service';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { newDate } from '../../shared/function';
import {
  OrderModalContext,
} from '../base-modal/base-modal.component';
import { ReplaySessionService } from '../../services/replay-session.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-car-in-rent',
  templateUrl: './car-in-rent.component.html',
  styleUrls: ['./car-in-rent.component.css'],
})
export class CarInRentComponent
  implements OnInit {
  public OfficeOrderTypeDescriptionHeEnum: typeof OfficeOrderTypeDescriptionHeEnum = OfficeOrderTypeDescriptionHeEnum;

  TravelMode = google.maps.TravelMode;
  _finishOrder = false;
  lat = 32.0535083;
  lng = 34.9595936;
  zoom = 14;

  dir = {
    origin: { lat: 24.799448, lng: 120.979021 },
    destination: { lat: 24.799524, lng: 120.975017 },
  };

  renderOpts: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    markerOptions: <google.maps.MarkerOptions>{
      icon: AppGlobals.POINTER_IMAGES.CITY_CAR,
    },
  };
  f: google.maps.MarkerOptions = <google.maps.MarkerOptions>{};
  markerOpts: {
    origin: google.maps.MarkerOptions,
    destination: google.maps.MarkerOptions,
  } = {
      origin: <google.maps.MarkerOptions>{
        icon: AppGlobals.POINTER_IMAGES.MAN_WALKING,
        opacity: 0.8,
      },
      destination: <google.maps.MarkerOptions>{
        icon: AppGlobals.POINTER_IMAGES.CITY_CAR,
        opacity: 0.8,
      },
    };

  context: OrderModalContext;

  addressName: string;

  isShowExtendRent = false;
  isShowMapWithDirection = false;
  isShowLateReport = false;
  isShowFinishRent = false;

  isIrregularOrder = false;

  enableOpenDoors = true;
  enableOpenGate = true;

  pictures: Picture[] = [];

  images: string[] = [];
  imagesSent = false;

  isOpenningMap = false;

  enableGetUnlockCode = true;

  get maxImagesSize(): number {
    return AppGlobals.IMAGES_DATA.MAX_SIZE_KB.REGISTER;
  }

  get isAllTabsClose(): boolean {
    return (
      !this.isShowMapWithDirection &&
      !this.isShowLateReport &&
      !this.isShowExtendRent &&
      !this.isShowFinishRent      
    );
  }

  hoursOrDays: number;
  daysToAdd = 0;
  hoursToAdd = 0;
  minutesToAdd = 0;
  lateMinutes = 1;
  getCode = 'GET_CODE';
  timer: number = null;

  startCatchedTime: Date;

  get isExtendByMinutes(): boolean {
    return (
      this.order.endTime.getTime() +
      AppGlobals.TIMES.HOURS_IN_TIME * this.hoursToAdd != this.newOrderEndTime.getTime()
    );
  }

  get newOrderEndTime(): Date {
    if (this.daysToAdd > 0) {
      console.log(this.daysToAdd);
      console.log(
        this.order.endTime.getTime() +
        this.daysToAdd * 24 * AppGlobals.TIMES.HOURS_IN_TIME
      );
      return new Date(
        this.order.endTime.getTime() +
        this.daysToAdd * 24 * AppGlobals.TIMES.HOURS_IN_TIME
      );
    }

    if (this.startCatchedTime) {
      return this.startCatchedTime;
    }

    if (this.minutesToAdd > 0) {
      return new Date(
        this.order.endTime.getTime() +
        this.minutesToAdd * AppGlobals.TIMES.MINUTES_IN_TIME
      );
    } else {
      return new Date(
        this.order.endTime.getTime() +
        this.hoursToAdd * AppGlobals.TIMES.HOURS_IN_TIME
      );
    }
  }

  get CARS_IMAGES() {
    return AppGlobals.CARS_IMAGES;
  }

  get mapsDirectionToCarLink(): string {
    return (
      'geo:0,0?q=' +
      this.order.lastCarLocation.lat +
      ',' +
      this.order.lastCarLocation.lon +
      '(כאן נמצא הרכב שלך)&travelmode=walking'
    );
  }

  get DomSanitizer(): DomSanitizer {
    return this.domSanitizer;
  }

  get customStyle() {
    return AppGlobals.STYLES.IMAGE_UPLOAD;
  }

  getCarsImages(carType: string) {
    return AppGlobals.CARS_IMAGES[carType]
      ? AppGlobals.CARS_IMAGES[carType]
      : AppGlobals.CARS_IMAGES.NO_DATA;
  }

  constructor(
    private _parkingsService: ParkingsService,
    private _googleMapsService: GoogleMapsService,
    private _orderService: OrderService,
    private _alertService: AlertService,
    private _loadingService: LoadingService,
    private _gloabalService: GlobalService,
    private _irregularOrder: IrregularOrderService,
    private translate: TranslateService,
    private _datePipe: DatePipe,
    private domSanitizer: DomSanitizer,
    private httpService: HttpService,
    private _urlService: UrlService,
    private _globalService: GlobalService,
    private replaySessionService: ReplaySessionService,
    private activeModal: NgbActiveModal,
    public order: Order,
  ) { }

  async ngOnInit() {
    console.log(this.order.period);

    this.addressName = this.order.startParking
      ? this.order.startParking.name
      : null;
  }

  async getUnlockCode() {
    this.enableGetUnlockCode = false;
    try {
      const unlockCode = await this._orderService.getActiveUnlockCode(this.order.id);
      this.enableGetUnlockCode = true;
      this.replaySessionService.sendEventToReplaySession('unlockCodeResponse', { unlockCode });
      if (unlockCode) {
        this.getCode = unlockCode.toString();
      } else {
        this.getCode = this.translate.currentLang === 'he' ? 'נסה שנית' : 'Try again';
      }
      setTimeout(() => {
        this.getCode = this.translate.currentLang === 'he'
          ? 'קבל את קוד הרכב'
          : 'Get the code';
      }, 30000);
    } catch (err: any) {
      this.enableGetUnlockCode = true;
      this.replaySessionService.sendErrorToReplaySession(err);
      this.replaySessionService.sendEventToReplaySession('unlockCodeError', err);
      this._alertService.error(err?.CustomErrorMessage);
    } finally {
      this.enableGetUnlockCode = true;
    }
  }

  finishRent(isWashed?: boolean) {
    this._orderService
      .orderIsDoneReport(this.order.id, isWashed)
      .then(() => {
        this._alertService.success('תודה ולהתראות!');
        setTimeout(() => {
          this.activeModal.close(true);
        }, 1500);
      })
      .catch((error) => {
        console.error(error);
        this._alertService.error(error.error.message);
      });
  }

  toggleShowMapWithDirection() {
    if (!this.isOpenningMap) {
      this.isOpenningMap = true;
      this._loadingService.startLoading();
      this._googleMapsService.getCurrentPosition().subscribe(
        (location: MapLocation) => {
          this.dir.origin.lat = location.lat;
          this.dir.origin.lng = location.lon;
          this.markerOpts.origin.visible = true;

          displayDirection();
          this.isOpenningMap = false;
        },
        () => {
          this.isOpenningMap = false;
          this._alertService.error('לא הצלחנו למצוא את מיקומך');
          this.markerOpts.origin.visible = false;
          displayDirection();
          this.dir.origin = this.dir.destination;
        }
      );
    }

    const displayDirection = () => {
      console.log(this.order.lastCarLocation);
      if (this.order.lastCarLocation) {
        this.closeAllTabs();
        this._loadingService.stopLoading();
        this.isShowMapWithDirection = !this.isShowMapWithDirection;

        this.dir.destination.lat = this.order.lastCarLocation.lat;
        this.dir.destination.lng = this.order.lastCarLocation.lon;
      }
    };
  }
  toggleShowLateReport() {
    this.closeAllTabs();
    this.isShowLateReport = !this.isShowLateReport;
  }
  toggleFinishRent() {
    this.closeAllTabs();
    this.isShowFinishRent = !this.isShowFinishRent;
  }

  closeAllTabs() {
    this.isShowMapWithDirection = false;
    this.isShowLateReport = false;
    this.isShowExtendRent = false;
    this.isShowFinishRent = false;
  }

  extendRentHoursOrDays(hoursOrDays) {
    this.hoursOrDays = hoursOrDays;
    if (hoursOrDays === 1) {
      this.hoursToAdd = 1;
      this.daysToAdd = 0;
    } else if (hoursOrDays === 2) {
      this.hoursToAdd = 0;
      this.daysToAdd = 1;
    }
  }

  extendRent(minutes: number = null) {
    // set the checket time slot to the order in the order service in order to check it:
    this._orderService.changeCurrentOrder(
      this._gloabalService.copyObject(this.order)
    );
    // add hours to the order's time:
    this.newOrderEndTime.setSeconds(0);
    this.order.endTime.setSeconds(0);
    this._orderService.EndTime = this.newOrderEndTime;
    this._orderService.StartTime = this.order.endTime;
    this._loadingService.startLoading();

    this._parkingsService
      .getParkingsWithCarsAndTimeSlots([this.order.startParking.id], false)
      .subscribe(
        (parkings: Parking[]) => {
          const parking: Parking = parkings.find(
            (p) => p.id == this.order.startParking.id
          );
          let car: CarInParking;
          this._loadingService.startLoading();
          console.log('parking', parking);

          // if parking is available to those hours:
          if (
            (car = parking.carsDetails.find(
              (c) => c.carNumber == this.order.car.carNumber
            )) &&
            car.availability == 1
          ) {
            const editedOrder = Object.assign({}, this.order);
            editedOrder.endTime = this.newOrderEndTime;
            this._orderService
              .updateOrder(true, editedOrder, false, this.isIrregularOrder)
              .then((order: EmployeeOrder | Order) => {
                this._loadingService.stopLoading();
                this._alertService.success(
                  'הזמנתך הוארכה בהצלחה! נא החזר את הרכב לתחנה עד ל:' +
                  this._datePipe.transform(
                    order.endTime,
                    'dd/MM/yyyy - HH:mm'
                  )
                );
                this.isShowExtendRent = false;
                this.startCatchedTime = null;
                this.order = order;
              })
              .catch((error: ErrorResponse) => {
                this._loadingService.stopLoading();
                console.error(error);
                this._alertService.error(error.CustomErrorMessage);
              });
          } else if (car && car.availability == 2) {
            const firstCatchedTimeSlot: timeSlot = car.timeSlots.find(
              (t) => newDate(t.start).getTime() > this.order.endTime.getTime()
            );
            const newStart = newDate(firstCatchedTimeSlot.start);
            newStart.setSeconds(this.order.endTime.getSeconds());
            // this.startCatchedTime = newDate(firstCatchedTimeSlot.start);
            this.hoursToAdd = Math.ceil(
              (newStart.getTime() - this.order.endTime.getTime()) /
              AppGlobals.TIMES.HOURS_IN_TIME
            );

            const isTenMinuteBefore = this._irregularOrder.isTenMinuteBefore(
              this.order.endTime,
              newStart
            );
            if (isTenMinuteBefore) {
              this.startCatchedTime = newDate(firstCatchedTimeSlot.start);
              this.isIrregularOrder = true;
              this.newOrderEndTime.setSeconds(this.order.endTime.getSeconds());
            } else {
              this._alertService.error(
                ' לא ניתן להאריך את זמן השכירות, באפשרותך לדווח על איחור במספר דקות'
              );
              this._loadingService.stopLoading();
            }
          }
        },
        (error: ErrorResponse) => {
          this._loadingService.stopLoading();
          console.error(error);
          this._alertService.error(error.CustomErrorMessage);
        }
      );
  }

  removeFromHoursToAdd() {
    if (this.hoursToAdd > 1) {
      this.hoursToAdd--;
    }
  }

  addToHoursToAdd() {
    this.hoursToAdd++;
    console.log(this.hoursToAdd);
  }

  removeFromDaysToAdd() {
    if (this.daysToAdd > 1) {
      this.daysToAdd--;
      console.log(this.daysToAdd);
    }
  }

  addToDaysToAdd() {
    this.daysToAdd++;
    console.log(this.daysToAdd);
  }

  addMinutesToRent(minutes: number) {
    this.minutesToAdd = minutes;
    this.extendRent(minutes);
  }

  async navigateToCar() {
    const carLocation = this.order.lastCarLocation
      ? this.order.lastCarLocation
      : await this.queryCarLocation(this.order.car.carNumber);

    if (!carLocation) {
      this._alertService.error('לא הצליח לקבל את מיקום הרכב');
      return;
    }

    this._globalService.navigateToCar(carLocation.lat, carLocation.lon);
  }

  async queryCarLocation(carNumber: number): Promise<MapLocation> {
    try {
      const cars = await this.httpService.get(
        this._urlService.nodeUrl() + 'cars?carNumber=' + carNumber
      );
      return { lat: cars[0].latitude, lon: cars[0].longitude };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public performActionOnDoors(action: boolean) {
    this.enableOpenDoors = false;
    setTimeout(() => {
      this.enableOpenDoors = true;
    }, 10000);
    const body = {
      orderId: this.order.id,
      updateSource: AppGlobals.ID,
      Open: action,
    };
    this.httpService
      .post(this._urlService.baseUrl() + 'orders/Doors', body, true)
      .then((res: { isSucceeded: boolean }) => {
        console.log(res);
        if (res.isSucceeded === true) {
          this._alertService.success('פעולתך בוצעה בהצלחה');
        } else {
          this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
          this.enableOpenDoors = true;
        }
      })
      .catch((error) => {
        console.error(error);
        this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
        this.enableOpenDoors = true;
      });
  }

  openGate() {
    const body = { ORDER_ID: this.order.id, UPDATE_SOURCE: AppGlobals.ID };
    this.enableOpenGate = false;
    this.httpService
      .post(this._urlService.baseUrl() + 'gates/OpenMyGate', body, true)
      .then(() => {
        this._alertService.success('פעולתך בוצעה בהצלחה');
        this.enableOpenGate = true;
      })
      .catch((error) => {
        console.log(error);
        this.enableOpenGate = true;
        this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
      });
  }

  closeModal() {
    this.activeModal.close();
  }

}

class Picture {
  image: string;
  note: string;
  file: File;
}

import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CarInRentComponent } from "../modals/car-in-rent/car-in-rent.component";
import { Order, EmployeeOrder } from "../models/order";
import { ModalService } from "./modal.service";
import { OrderService } from "./order.service";

@Injectable({ providedIn: 'root' },)
export class CarsInRent {
  constructor(
    private orderService: OrderService,
    private translateService: TranslateService,
    private _router: Router,
    private _modalService: ModalService,
    private modalService: NgbModal,
  ) { }

  showCarInRent(order: Order): void {
    this.modalService.open(CarInRentComponent, {
      centered: true, injector: Injector.create(
        {
          providers: [
            {
              provide: Order,
              useValue: order,
            }
          ],
        }
      ),
    });
  }

  showCarsInRent() {
    this.orderService.getCurrentOrder(true)
      .subscribe((orders: (EmployeeOrder | Order)[]) => {
        // show alert of car in rent
        if (orders?.length === 1) {
          this.modalService.open(CarInRentComponent, {
            centered: true, injector: Injector.create(
              {
                providers: [
                  {
                    provide: Order,
                    useValue: orders[0],
                  }
                ],
              }
            ),
          });
        } else if (orders?.length > 1) {
          this._router.navigate(['/orderList'], { state: { orders } });
        } else {
          this.translateService
            .get('MESSAGES.NOT_HAVE_ACTIVE_ORDER')
            .subscribe(text => {
              this._modalService.alert(
                '',
                text,
              );
            });
        }
      });
  }
}

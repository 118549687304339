import { Component, ElementRef, Renderer2 } from '@angular/core';
import { DialogRef } from 'ngx-modialog-7';
import { Router } from '@angular/router';

import { BaseModalComponent } from '../base-modal/base-modal.component';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import PageTitle from '../../models/page-title';

export class DamageReportTypeModalContext extends BSModalContext {
  constructor(public afterSuccessFn: (isDamageBeforeStart) => void) {
    super();
  }
}

@Component({
  selector: 'app-damage-report-type',
  templateUrl: './damage-report-type.component.html',
  styleUrls: ['./damage-report-type.component.css'],
})
export class DamageReportTypeComponent
  extends BaseModalComponent<DamageReportTypeModalContext>
{
  isDamageBeforeStart = true;
  afterSuccess: (isDamageBeforeStart) => void;
  afterClose(): void {
    this.afterSuccess(this.isDamageBeforeStart);
  }

  get pageTitle(): PageTitle {
    return {
      title: 'REPORT_DAMAGE',
      previousUrl: '/menu',
    };
  }

  constructor(
    public dialog: DialogRef<DamageReportTypeModalContext>,
    elementRef: ElementRef,
    renderer: Renderer2,
    private _router: Router
  ) {
    super(dialog, elementRef, renderer);
    this.afterSuccess = dialog.context.afterSuccessFn;
  }

  continue() {
    this.closeDialog();
  }
}

<div id="result">
  <style>
    body {
      background: url(./images/bg_citycar_image.jpg) repeat;
    }
  </style>
  <title>סיטי קאר - שירות לקוחות</title>
  <section id="page-title" style="padding: 5px 0px" dir="rtl">
    <!-- #page-title starts -->
    <div class="routerContainer">
      <div class="col-lg-12 text-right">
        <ul>
          <li>
            <a
              type="button"
              id="Notifications"
              class="btn btn-lg"
              data-toggle="modal"
              data-target="#myModal"
              ><img src="images/icon_chat.png" alt="" /> הודעות</a
            >
          </li>
          <li style="background: #1f0737">
            <a
              type="button"
              class="btn btn-lg hover"
              data-toggle="modal"
              data-target="#myModal1"
              ><img src="images/icon_gift.png" alt="" /> מבצעים</a
            >
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- #page-title ends -->
  <section id="salescoupon" class="fullwidth" dir="rtl">
    <!-- #items starts -->
    <ul class="small-list-items">
      <li class="btn">
        <div class="routerContainer">
          <div class="col-xs-2 text-left">
            <a class="yellow-round-btn">ממש הטבה</a>
          </div>
          <div class="col-xs-8">
            <div class="salescoupon_text fix">
              <h4>שעה שלישית חינם!!</h4>
              <p>21 באוגוסט, 9:30</p>
            </div>
          </div>
          <div class="col-xs-2">
            <img
              class="img-responsive"
              src="images/user_image_citycar.png"
              alt=""
            />
          </div>
        </div>
      </li>
      <li class="btn">
        <div class="routerContainer">
          <div class="col-xs-2 text-left">
            <a class="yellow-round-btn">ממש הטבה</a>
          </div>
          <div class="col-xs-8">
            <div class="salescoupon_text fix">
              <h4>חניה חינם ללקוחות סיטי קאר</h4>
              <p>21 באוגוסט, 9:30</p>
            </div>
          </div>
          <div class="col-xs-2">
            <img
              class="img-responsive"
              src="images/user_image_citycar.png"
              alt=""
            />
          </div>
        </div>
      </li>
    </ul>
  </section>
  <!-- #items ends -->
</div>

<a (click)="clear()">
  <div
    class="row"
    id="alertDiv"
    *ngIf="message && message.text"
    [ngClass]="{
      alert: message,
      'alert-success': message.type === 'success',
      'alert-danger': message.type === 'error'
    }"
  >
    <div class="col-xs-10">
      {{ message.text }}
    </div>
    <div class="col-xs-2">
      <i
        *ngIf="message.type === 'error'"
        class="fa fa-exclamation-circle errorIcon"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="message.type === 'success'"
        class="fa fa-check successIcon"
        aria-hidden="true"
      ></i>
    </div>
  </div>
</a>

<div class="damageReportTypeDiv">
  <div>
    <p class="title-popup" translate>SELECT_DEMAGE_TYPE</p>
    <div>
      <input [(ngModel)]="isDamageBeforeStart" type="radio" [value]="true" />

      <div class="after-radio">
        <h4 class="title-popup" translate>REPORT_BEFORE</h4>
        <div>
          <p translate>REPORT_BEFORE_2</p>
          <p translate>REPORT_BEFORE_3</p>
        </div>
      </div>

      <div>
        <input [(ngModel)]="isDamageBeforeStart" type="radio" [value]="false" />
        <div class="after-radio">
          <h4 class="title-popup" translate>REPORT_AFTER</h4>
          <p translate>REPORT_AFTER_2</p>
          <p translate>REPORT_AFTER_3</p>
        </div>
      </div>
    </div>
    <button (click)="continue()" class="blue-btn-class" translate>
      CONTINUE
    </button>
  </div>
</div>

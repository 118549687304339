<ng-container *ngIf="isShowSmallFormat; then smallFormat; else normalFormat">
</ng-container>
<ng-template #smallFormat>
  <div class="row">
    <div class="col-xs-3 col-lg-3"></div>
    <div class="col-xs-3 col-lg-3">
      <div
        (click)="choosePeriod('hours')"
        [ngClass]="{ selectedPeriod: periodName === 'hours' }"
        class="routerContainer"
      >
        <div class="box col-xs-12 text-center">
          <div class="img-this"></div>
          <h4>שעתי</h4>
        </div>
      </div>
    </div>
    <div class="col-xs-3 col-lg-3">
      <div
        (click)="choosePeriod('days')"
        [ngClass]="{ selectedPeriod: periodName === 'days' }"
        class="routerContainer"
      >
        <div class="box col-xs-12 text-center">
          <div class="img-this"></div>
          <h4>יומי</h4>
        </div>
      </div>
    </div>
    <div class="col-xs-3 col-lg-3">
      <button (click)="changeLocalPeriod()">try</button>
    </div>
  </div>
</ng-template>
<ng-template #normalFormat>
  <div id="result">
    <title>סיטי קאר - בחירת סוג השכרה</title>
    <!-- #page-title ends -->

    <app-page-title [pageTitle]="pageTitle"></app-page-title>

    <section id="rent-types" class="fullwidth" dir="rtl">
      <!-- #items starts -->
      <ul class="itemlist">
        <li class="btn activate1">
          <div
            class="routerContainer"
            [ngClass]="{ selectedPeriod: periodName === 'hours' }"
          >
            <div class="box col-xs-12 text-center">
              <div class="img-this" (click)="choosePeriod('hours')"></div>
              <h4>שעתי</h4>
            </div>
          </div>
        </li>

        <li class="btn activate3">
          <div
            [ngClass]="{ selectedPeriod: periodName === 'days' }"
            class="routerContainer"
          >
            <div class="box col-xs-12 text-center">
              <div class="img-this" (click)="choosePeriod('days')"></div>
              <h4>יומי</h4>
            </div>
          </div>
        </li>
      </ul>

      <br />
      <br />

      <div class="col-xs-10 col-xs-offset-1 text-center">
        <a class="yellow-round-btn" id="ContinueButton" (click)="next()"
          >המשך</a
        >
      </div>
    </section>
    <!-- #items ends -->
  </div>
</ng-template>

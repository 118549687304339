import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '../../enums';
import { GoogleAuthService } from '../../services/google-auth.service';
import PageTitle from '../../models/page-title';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css'],
})
export class PageTitleComponent {
  @Input()
  pageTitle: PageTitle;

  constructor(
    private _googleAuthService: GoogleAuthService,
    private _router: Router
  ) {}

  navigate(): void {
    this._router.navigate([this.pageTitle.previousUrl]);
  }

  logout() {
    localStorage.setItem('AuthState', AuthState.NOT_SET.toString());
    this._googleAuthService.logout();
  }
}

<app-page-title [pageTitle]="pageTitle"></app-page-title>
<section class="fullwidth">
  <div class="col-xs-12 bg-color">
    <div class="col-xs-6 contactOption">
      <a class="contactA" [href]="'tel:' + CONTACT_DETAILS.PHONE">
        <div class="contact-div">
          <img
            _ngcontent-c10=""
            class="img-contact"
            src="assets/images/phone-contact.png"
          />
        </div>
        <p class="a-contact" translate>PHONE</p>
      </a>
    </div>

    <div class="col-xs-6 contactOption" (click)="sendMail()">
      <a class="contactA">
        <div class="contact-div">
          <img
            _ngcontent-c10=""
            class="img-contact"
            src="assets/images/mail-contact.png"
          />
        </div>
        <a class="a-contact" translate>EMAIL</a>
      </a>
    </div>
    <!-- 
        <div class="col-xs-4 contactOption">
            <a class="contactA" href="whatsapp://send?phone=972529522234">
                <div class="contact-div">
                    <img _ngcontent-c10="" class="img-contact" src="assets/images/whatsapp-contact.png">
                </div>
                <!- - <a class="a-contact" target="_blank" [href]="'whatsapp://send?phone='+CONTACT_DETAILS.PHONE">what's app</a> - ->
                <!- - <a class="a-contact" target="_blank" [href]="whatsapp://send?phone=*2319">what's app</a> - ->
                <a class="a-contact" target="_blank" translate>WHATSAPP</a>
            </a>
        </div> -->
  </div>
  <div class="col-xs-12">
    <h4 class="call-h4" translate>
      TO_JOIN
      <br />
      <span class="blue-color"> 2319* </span>
    </h4>
  </div>
  <div class="col-xs-offset-2 col-xs-8 col-xs-offset-2 class-detail">
    <h4 class="call-h4-a" translate>MAIN_OFFICE</h4>
    <p class="p-contact" translate>
      {{ 'PO_BOX' | translate }}
      <br />{{ 'TEL_CONTACT' | translate }} <br />{{
        'FAX_CONTACT' | translate
      }}
      <br />
      {{ 'EMAIL_CONTACT' | translate }}
    </p>
  </div>
  <div
    class="col-xs-offset-2 col-xs-8 col-xs-offset-2 class-detail"
    style="margin-top: 0px; border-top: none"
  >
    <h4 class="call-h4-a" translate>REGISTRATION_HOURS</h4>
    <p class="p-contact" translate>
      REGISTRATION_HOURS1
      <br translate />
      REGISTRATION_HOURS2
    </p>
  </div>
</section>

<section *ngIf="pageTitle" style="z-index: 10" id="page-title" dir="rtl">
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 text-center">
        <div *ngIf="pageTitle.previousUrl !== '/home'">
          <a
          style="
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              line-height: 2;
            "
            (click)="navigate()"> {{ 'PREVIOUS' | translate }}</a>
          </div>
        </div>
        <div class="col-6 text-center">
          <h2 translate>{{ pageTitle.title }}</h2>
        </div>
        <div class="col-3">
          <div>
            <button (click)="logout()">
              <i class="fas fa-sign-out-alt"></i>התנתק
            </button>
          </div>
        </div>
      </div>
  </div>
</section>

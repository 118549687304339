import { Directive, Input } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Directive({
  selector: '[appRequired]',
  host: {
    '[class.has-error]': 'isInvalid',
  },
})
export class RequiredDirective {
  @Input('appRequired') elements: { model: NgModel; form: NgForm };

  get isInvalid(): boolean {
    return (
      ((this.elements.form && this.elements.form.submitted) ||
        this.elements.model.dirty) &&
      this.elements.model.errors &&
      this.elements.model.errors.required
    );
  }

  setValidator() {
    if (
      this.elements &&
      this.elements.form &&
      this.elements.form.form.controls[this.elements.model.name]
    ) {
      const el = this.elements.form.form.controls[this.elements.model.name];
    } else {
      console.warn('appRequired did not find the element');
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {

  copyObject(object: any): any {
    if (!object) {
      return object;
    }
    return JSON.parse(JSON.stringify(object));
  }

  calculateFile64SizeKB(base64String: string, fileFormat: string = ''): number {
    if (!base64String) return 0;

    const srcLength = base64String.length - fileFormat.length;
    if (!srcLength) return 0;
    const fileSize = 4 * Math.ceil(srcLength / 3) * 0.5624896334383812;

    return fileSize / 1000;
  }

  navigateToCar(lat, lon) {
    const navigationLink = document.createElement('a');
    navigationLink.href = this.GetNavigationUrl(lat, lon);
    navigationLink.target = '_blank';
    navigationLink.click();
  }

  GetNavigationUrl(lat, lon): string {
    return (
      'https://www.waze.com/ul?ll=' +
      lat +
      '%2C' +
      lon +
      '&navigate=yes&zoom=17'
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Period } from '../../enums';
//import { Input, Output } from '@angular/core/src/metadata/directives';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import PageTitle from '../../models/page-title';
import { OrderService } from '../../services/order.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';

@Component({
  selector: 'app-order-period',
  templateUrl: './order-period.component.html',
  styleUrls: ['./order-period.component.css'],
})
export class OrderPeriodComponent implements OnInit {
  slectedPeriod: Period;
  // @Input()
  isShowSmallFormat: boolean;

  //@Output()
  // @Input()
  changePeriod: Subject<any>;

  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'SELECT_RENTAL_TYPE',
      previousUrl: '/menu',
    };
    return PageTitle;
  }

  get periodName(): string {
    return Period[this.slectedPeriod];
  }
  constructor(private _orderService: OrderService, private _router: Router) {}

  ngOnInit() {
    AppGlobals.PREVIOUS_URL = '/menu';

    if (this._orderService.Period)
      this.slectedPeriod = this._orderService.Period;
    else this.slectedPeriod = Period.hours;

    if (this.changePeriod)
      this.changePeriod.asObservable().subscribe((data) => {
        if (!data) {
          console.log('selected period from inner', this.slectedPeriod);

          this.changePeriod.next(this.slectedPeriod);
        }
      });
  }

  choosePeriod(periodName: string): void {
    this.slectedPeriod = Period[periodName];
  }

  next() {
    this._orderService.Period = this.slectedPeriod;
    this._router.navigate(['/order/selectTime']);
  }

  changeLocalPeriod(): void {
    this.changePeriod.next(this.slectedPeriod);
  }
}

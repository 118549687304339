<div id="result">
  <title>סיטיקאר - דווח תאונה</title>
  <section id="page-title" dir="rtl">
    <!-- #page-title starts -->
    <div class="routerContainer">
      <div class="col-xs-12 text-center">
        <h2>בחר הזמנה לדיווח על תאונה</h2>
        <a id="GoBack" class="linkBack" href="#"></a>
      </div>
    </div>
  </section>
  <!-- #page-title ends -->
  <section id="order" class="fullwidth" dir="rtl">
    <!-- #items starts -->
    <div class="routerContainer">
      <div class="col-xs-12">
        <form>
          <div class="formRow col-xs-6">
            <label class="label-c">סוג הרכב</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">מספר רישוי</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">שם חברת הביטוח </label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">מספר הפוליסה </label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">מספר התעודה</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">שם סוכן הביטוח ומספרו </label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">שם הנהג +ת"ז</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">כתובת מגורים</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">מספר רישיון נהיגה</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="formRow col-xs-6">
            <label class="label-c">טלפון</label>
            <input type="text" class="form-control-plaintext input-c" />
          </div>
          <div class="col-xs-12">
            <p class="must">
              **חובה להקפיד על רישום נכון וברור, אי הקפדה על כך תגרום לאי יכולת
              לתבוע את הנזק שנגרם לרכב מהנהג המעורב ולהוצאה מיותרת**
            </p>
            <div class="formRow col-xs-6">
              <label class="label-c">מקום התאונה</label>
              <input type="text" class="form-control-plaintext input-c" />
            </div>
            <div class="formRow col-xs-6">
              <label class="label-c">מיקום כלי הרכב</label>
              <input type="text" class="form-control-plaintext input-c" />
            </div>
            <div class="formRow col-xs-6">
              <label class="label-c">יום התאונה</label>
              <input type="text" class="form-control-plaintext input-c" />
            </div>
            <div class="formRow col-xs-6">
              <label class="label-c">שעת התאונה</label>
              <input type="text" class="form-control-plaintext input-c" />
            </div>
            <div class="formRow col-xs-6">
              <label class="label-c">לאיזה בית חולים מפונה:</label>
              <select class="form-control-plaintext input-c">
                <option value="volvo">1</option>
                <option value="volvo">1</option>
                <option value="volvo">1</option>
              </select>
            </div>
            <div class="col-xs-12">
              <button class="col-xs-6 blue-btn-class">הוסף תמונה</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- #items ends -->

  <script type="text/javascript">
    $(document).ready(function () {
      $('#GoBack').click(function () {
        $('#result').load('main.php');
      });
    });
  </script>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Order } from '../models/order';

@Injectable()
export class ServicesCommunicationService {
  orderFromOrderService: Subject<Order> = new Subject();

  getOrderFromOrderService() {
    return this.orderFromOrderService.asObservable();
  }
}

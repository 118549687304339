import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ErrorResponse } from '../models/error-response';
import { AlertService } from '../services/alert.service';
import { OrderService } from '../services/order.service';

@Injectable()
export class OpenOrderResolver implements Resolve<any> {
  constructor(
    private _orderService: OrderService,
    private _alertService: AlertService,
    private _router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<number> {
    const obs: Observable<number> = new Observable((observer) => {
      if (
        this._orderService.IsEditOrder &&
        !this._orderService.isOrderChanged
      ) {
        observer.next(this._orderService.Order.finalPrice);
        observer.complete();
      }

      this._orderService.calculateOrderPrice().subscribe(
        (price: number) => {
          observer.next(price);
          observer.complete();
          return price;
        },
        (error: ErrorResponse) => {
          console.error(error);
          this._alertService.error(error.CustomErrorMessage);
          observer.complete();
        }
      );

      return observer;
    });

    return obs;
  }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { OrderService } from '../../services/order.service';

import { ComponentData } from '../../directives';
import { Order } from '../../models/order';
@Component({
  selector: 'app-order-date-editor',
  templateUrl: './order-date-editor.component.html',
  styleUrls: ['./order-date-editor.component.css'],
})
export class OrderDateEditorComponent {
  @Output()
  public changeTime: EventEmitter<any> = new EventEmitter();

  minDivSize = 29;
  divSize: number = this.minDivSize;

  currentComponent: ComponentData;

  get order(): Order {
    return this._orderService.Order;
  }

  get isOrderForNow(): boolean {
    return this._orderService.isOrderForNow;
  }

  get orderTimeAmount(): number {
    return Math.ceil((this.order.timeAmount * 100) / 100);
  }

  constructor(private _orderService: OrderService) {}

  toggleDisplayEditor(): void {
    if (this.divSize) this.divSize = null;
    else this.divSize = this.minDivSize;

    this.order.timeAmount;
  }

  emitChangeTime(): void {
    this.changeTime.emit();
  }
}

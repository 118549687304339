import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

@Injectable()
export class LoadDataService {
  private finishLoadDataSubject = new Subject<any>();

  getFinishLoadObservable(): Observable<any> {
    return this.finishLoadDataSubject.asObservable();
  }

  finishLoadData() {
    this.finishLoadDataSubject.next();
    this.finishLoadDataSubject.complete();
  }
}

<!-- <img src="assets/images/rmp-icon1.png" alt="" style='width: 30px' id="pickDate" href="#"> -->
<!-- <a href="#" id="pickDate">Select Date</a> -->

<!-- <div [ngClass]="{'col-xs-7':isShowDay,'col-xs-10':!isShowDay}" class=" col-xs-offset-1">
  <input style="width: 100%" onchange="this.type=this.val?'month':'text'" onmouseenter="this.type='month';this.click()" [type]="value&&month.model!=null?'month':'text'"
    placeholder="חודש ושנה" required [appRequired]="{model:month,form:form}" class="form-control-plaintext input-c" [ngModel]="value?(value | customDate:'yyyy-MM'):null"
    (ngModelChange)="changeDate(day1,month)" [min]="minYear" [max]="maxYear" name="month" #month="ngModel" onkeypress="return false">
</div>

<div [hidden]="!isShowDay" class="col-xs-3">
  <input name="day1" #day1="ngModel" style="width: 100%" [ngClass]="{'has-error':!this.val>0}" onfocus="(this.type='number')"
    [type]="value&&this.val?'month':'number'" placeholder="יום" required [appRequired]="{model:day1,form:form}" class="form-control-plaintext input-c"
    [ngModel]="value?(value | customDate:'dd'):null" (ngModelChange)="changeDate(day1,month)" [value]="value?(value | customDate:'dd'):null"
    min="1">
</div> -->

<div *ngIf="isShowDay" class="col-xs-10 col-xs-offset-1">
  <input
    style="width: 100%"
    type="date"
    class="form-control-plaintext input-c"
    [required]="isReq"
    [appRequired]="{ model: date1, form: form }"
    (ngModelChange)="changeDate(date1)"
    name="date1"
    [min]="minYear"
    [max]="maxYear"
    [(ngModel)]="value"
    #date1="ngModel"
  />
</div>

<div *ngIf="!isShowDay" class="col-xs-10 col-xs-offset-1">
  <input
    style="width: 100%"
    type="month"
    class="form-control-plaintext input-c"
    [required]="isReq"
    [appRequired]="{ model: date1, form: form }"
    (ngModelChange)="changeDate(date1)"
    name="date1"
    [min]="minYear"
    [max]="maxYear"
    [(ngModel)]="value"
    #date1="ngModel"
  />
</div>

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { OrderService } from '../services/order.service';

@Injectable()
export class OrderDatailsExistGuard implements CanActivate {
  requiredValuesUrl: {
    url: string;
    requiredValues: string[];
    queryParams: any;
  }[];

  constructor(
    private _orderService: OrderService,
    private _router: Router,
  ) {
    this.requiredValuesUrl = [
      {
        url: '/order/selectTime',
        requiredValues: ['startTime', 'endTime'],
        queryParams: null,
      },
      {
        url: '/order/stationsInMap',
        requiredValues: ['startParking'],
        queryParams: null,
      },
    ];
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUrl: string = state.url;

    for (let i = 0; i < this.requiredValuesUrl.length; i++) {
      const value = this.requiredValuesUrl[i];

      if (currentUrl.indexOf(value.url) != -1) {
        value.queryParams = null;
        return true;
      }
      if (!this.isAllValuesExists(value.requiredValues)) {
        this._router.navigate([value.url], { queryParams: value.queryParams });
        return false;
      }
    }
    return true;
  }

  isAllValuesExists(val: any[]) {
    for (let i = 0; i < val.length; i++) {
      if (!this._orderService.Order[val[i]]) return false;
    }
    return true;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  forwardRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LatLngBounds } from '@agm/core';
import { GlobalService } from '../../services/global.service';
import * as moment from 'moment';
import { IrregularOrderService } from '../../services/irregular-order.service';
import { of, Subject, Subscription } from 'rxjs';
import { AppStartService } from '../../services/app-start.service';
import { CarAvailabilityPipe } from '../../pipes/car-availability.pipe';
import { MatDialog } from '@angular/material/dialog';
import { SelectOfficeDriveTypeComponent } from '../../modals/select-office-drive-type/select-office-drive-type.component';
import { SearchByLocationOptions } from '../../enums';
import PageTitle from '../../models/page-title';
import { CarInParking } from '../../models/car';
import { ErrorResponse } from '../../models/error-response';
import { MapLocation } from '../../models/map-location';
import { EmployeeOrder, Order } from '../../models/order';
import { Parking, ParkingOnMap } from '../../models/parking';
import { CarAvailabilitySuggestionPipe } from '../../pipes/car-availability-suggestion.pipe';
import { AlertService } from '../../services/alert.service';
import { GoogleMapsService } from '../../services/google-maps.service';
import { LoadingService } from '../../services/loading.service';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { EmployeeType, UserService } from '../../services/user.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { catchError, map } from 'rxjs/operators';
import { CarsInRent } from '../../services/cars-in-rent.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectTimeComponent } from '../select-time/select-time.component';

enum LastWashEnum {
  LESS_OR_EQUAL_TO_7_DAYS,
  LESS_OR_EQUAL_TO_14_DAYS,
  MORE_THEN_14_DAYS,
  UNKNOWN,
}

enum LastDateIndicator {
  Red,
  Yellow,
  Green,
  Gray,
}

export interface MapOptions {
  location?: MapLocation;
  zoom: number;
  markersIcon: string;
  gestureHandling?: 'cooperative' | 'greedy' | 'none';
}

@Component({
  selector: 'app-stations-in-map',
  templateUrl: './stations-in-map.component.html',
  styleUrls: ['./stations-in-map.component.css'],
})
export class StationsInMapComponent implements OnInit, OnDestroy {
  LastWashEnum: typeof LastWashEnum = LastWashEnum;
  isOpen = false;
  EmployeeType: typeof EmployeeType = EmployeeType;
  carCategories: { name: string; id: number; isSelected: boolean }[] = <
    { name: string; id: number; isSelected: boolean }[]
    >[
      { name: '4 מקומות', id: 5, isSelected: true },
      { name: 'רגיל (קטן)', id: 1, isSelected: true },
      { name: 'משפחתי', id: 2, isSelected: true },
      { name: 'משפחתי פלוס', id: 3, isSelected: true },
      { name: '7 מקומות', id: 6, isSelected: true },
      { name: '7 קיה קרניבל', id: 12, isSelected: true },
    ];

  officeDriveTypes: { name: string; id: number }[] = <
    { name: string; id: number }[]
    >[
      { name: 'מוסך', id: 0 },
      { name: 'הרשמה', id: 1 },
      { name: 'תיקונים', id: 2 },
      { name: 'שונות', id: 3 },
      { name: "פנצ'ר", id: 4 },
      { name: 'שטיפה', id: 5 },
      { name: 'טסט', id: 6 },
      { name: 'תדלוק', id: 7 },
      { name: 'תחזוקה', id: 8 },
    ];

  parkings: Parking[];
  defoultMapZoom = 17;

  mapOptions: MapOptions = {
    zoom: this.defoultMapZoom,
    markersIcon: 'assets/images/icon_pointer_citycar.png',
    gestureHandling: 'greedy',
  };

  previosOrder: Order;

  currentRadiusOfParkings: number;
  currentParkingsCenter: MapLocation;

  defaultMapLocation: MapLocation = {
    lat: 32.08593785548084,
    lon: 34.83792543411255,
  };

  currentZoom = 17;
  showZoomAlert = true;
  currentAddressName: string;
  currentLocation: MapLocation;

  selectedParking: ParkingOnMap;
  selectedParkingError: string;

  selectedCarIndex = 0;
  isShowCarDetails: boolean;

  MILI_SECONDES_PER_MINUTES = 60 * 1000;

  get pageTitle(): PageTitle {
    return {
      title: 'STATION_MAP',
      previousUrl: '/menu',
    };
  }

  addOrderNowButtonString = 'הדבק';

  get selectedCarCategories(): number[] {
    return this.carCategories.filter((c) => c.isSelected).map((c) => c.id);
  }
  isShowCarCategorySort = false;
  isShowCarCategorySortButton = false;

  isShowEditTime = false;
  reloadParkingInterval: any;
  showAllParkings = false;

  private _isSelectAllCategories = true;
  public get isSelectAllCategories(): boolean {
    return this._isSelectAllCategories;
  }
  public set isSelectAllCategories(v: boolean) {
    if (v !== this._isSelectAllCategories) {
      this.carCategories.forEach((c) => (c.isSelected = v));
    }
    this._isSelectAllCategories = v;
  }

  private isAllCarDetLoaded = false;
  private dataSubject: Subject<any> = new Subject<any>();
  subscription: Subscription;
  invokeBoundsChangedEvent = true;

  eventFromChild(data: boolean) {
    console.log(data);
    this.selectedParking = null;
  }

  getIsAllCarDetLoaded() {
    return this.dataSubject.asObservable();
  }
  setIsAllCarDetLoaded(isAllCarDetLoaded) {
    this.isAllCarDetLoaded = isAllCarDetLoaded;
    this.dataSubject.next(this.isAllCarDetLoaded);
  }

  get isZoomGood(): boolean {
    return this.currentZoom >= 17;
  }

  get selectedCar(): CarInParking {
    if (!this.selectedParking.carsDetails) {
      return null;
    }
    return this.selectedParking.carsDetails[this.selectedCarIndex];
  }

  get selectedCarSuggestions(): any[] {
    return this._carAvailabilitySuggestionPipe.transform(
      this.selectedCar.timeSlots
    );
  }

  get selectedCarAvailability(): any[] {
    return this._carAvailabilityPipe.transform(this.selectedCar.timeSlots);
  }

  get pointerImg(): string {
    return AppGlobals.POINTER_IMAGES.CITY_CAR;
  }

  get pointerNotAvailable(): string {
    return AppGlobals.POINTER_IMAGES.CITY_CAR_RED;
  }

  get pointerNotCompletelyAvailable(): string {
    return AppGlobals.POINTER_IMAGES.CITY_CAR_ORANGE;
  }

  get isEditOrder(): boolean {
    return this._orderService.IsEditOrder;
  }

  get orderId(): number {
    return this._orderService.Order.id;
  }

  constructor(
    @Inject(forwardRef(() => AppStartService))
    private _appStart: AppStartService,
    private _router: Router,
    private _route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public _orderService: OrderService,
    private _parkingsService: ParkingsService,
    private _googleMapsService: GoogleMapsService,
    private _loadingService: LoadingService,
    private _alertService: AlertService,
    private _globalService: GlobalService,
    private _carAvailabilitySuggestionPipe: CarAvailabilitySuggestionPipe,
    private _carAvailabilityPipe: CarAvailabilityPipe,
    private _irregularOrderService: IrregularOrderService,
    public _userService: UserService,
    public _dialog: MatDialog,
    private carsInRent: CarsInRent,
    private modalService: NgbModal,
  ) {
    document.addEventListener('click', (e) => {
      const target = e.target as Element;
      if (
        !target ||
        (target.id !== 'editDateBtn' &&
          target.id !== 'sortByCarTypeBtn' &&
          target.id !== 'littleChangeTime')
      ) {
        this.isShowCarCategorySort = false;
        this.isShowEditTime = false;
      }
    });
  }

  stopProposition(event) {
    event.stopImmediatePropagation();
  }

  ngOnInit() {
    this.irregularOrder();
    this._orderService.Order.isIrregularOrder = false;
    this._orderService.isOrderChanged = true;
    this.previosOrder = this._globalService.copyObject(
      this._orderService.Order
    );

    this.setLocalTime();
    AppGlobals.PREVIOUS_URL = 'order/selectTime';

    this.parkings = this._globalService.copyObject(
      this._parkingsService.parkings
    );
    console.log(this._parkingsService.parkings);
    console.log(this.parkings);

    this._route.queryParams.subscribe((params) => {
      console.log('finish get params', params);
      const parkingId: number = params['parkingId'];
      // check if there is selected station from route or from service:
      if (parkingId) {
        // select this parking
        const selectedParking = this._parkingsService.getParkingById(parkingId);
        this.selectParking(selectedParking, true);
        this.cdRef.detectChanges();
      } else if (
        this._orderService.Order &&
        this._orderService.Order.startParking
      ) {
        this.selectParking(this._orderService.Order.startParking, true);
      } else {
        this.setDefoultLocation(this._orderService.searchByOption).subscribe();
        this.isShowCarCategorySortButton = true;
        this.reloadParkingInterval = setInterval(() => {
          console.log(this.isZoomGood);
          console.log(this.currentParkingsCenter);
          this._orderService.updateIfOrderForNow();
          this.reloadParkings().subscribe(
            (parkings) => {
              console.log(parkings);
            },
            (error: ErrorResponse) => {
              if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
                this._alertService.error(error.CustomErrorMessage);
              }
            }
          );
        }, this.MILI_SECONDES_PER_MINUTES * 2);
      }
    });
  }

  setLocalTime() {
    this.previosOrder.startTime = moment(this.previosOrder.startTime).toDate();
    this.previosOrder.endTime = moment(this.previosOrder.endTime).toDate();
  }

  ngOnDestroy(): void {
    this._appStart.show = true;
    this.cdRef.detach();
    console.log('ngOnDestroy');
    clearInterval(this.reloadParkingInterval);
  }

  selectAddress(location: MapLocation): void {
    this.mapOptions.location = location;
  }

  selectParkingByIndex(selectedIndex: number): void {
    this.selectParking(this.parkings[selectedIndex]);
  }

  selectParking(
    selectedParking: Parking,
    isSetParkingOnMap: boolean = false
  ): void {
    this.selectedCarIndex = 0;

    console.log('selectedParking', selectedParking);
    if (selectedParking) {
      this.selectedParking = selectedParking as ParkingOnMap;
      console.log(this.isAllCarDetLoaded);
      // set location to the center of the map
      if (isSetParkingOnMap) {
        this.setMapCenter(selectedParking.earthLocation, false);
      }

      if (selectedParking.carsDetails && selectedParking.carsDetails.length) {
        let c: CarInParking;
        // select to most available car:
        if (
          (c = this.selectedParking.carsDetails.find(
            (c) => c.availability === 1
          ))
        ) {
          this.selectedCarIndex = this.selectedParking.carsDetails.indexOf(c);
        } else if (
          (c = this.selectedParking.carsDetails.find(
            (c) => c.availability === 2
          ))
        ) {
          this.selectedCarIndex = this.selectedParking.carsDetails.indexOf(c);
          if (
            this.isAllCarDetLoaded === false &&
            this.subscription.closed === false
          ) {
            this.setIsAllCarDetLoaded(true);
          }
        }
      }
    }
  }

  isCarCategorySelected(parking: Parking): boolean {
    const sortedCarCategories = this.carCategories
      .filter((c) => c.isSelected === true)
      .map((c) => c.id);
    return sortedCarCategories.includes(parking.carsDetails[0].carCategory);
  }

  sortBycarCategory() {
    this.isShowCarCategorySort = false;
  }

  irregularOrder() {
    this.subscription = this.getIsAllCarDetLoaded().subscribe((r) => {
      if (this.isAllCarDetLoaded) {
        const availability = this.selectedParking.carsDetails[0].availability;
        const beforeUsedTimeSlot =
          this.selectedParking.carsDetails[0].carTimeSlots[0].used.filter(
            (t) =>
              new Date(t.end) > this.previosOrder.startTime &&
              new Date(t.start) < this.previosOrder.startTime
          );

        const usedTimeSlot =
          this.selectedParking.carsDetails[0].carTimeSlots[0].used.find(
            (r) =>
              new Date(r.start) < this.previosOrder.endTime &&
              new Date(r.end) > this.previosOrder.startTime &&
              new Date(r.start) > this.previosOrder.startTime
          );

        if (
          this.selectedParking.carsDetails != null &&
          availability === 2 &&
          usedTimeSlot &&
          beforeUsedTimeSlot.length === 0
        ) {
          this._irregularOrderService.wantIrregularOrder(
            this.previosOrder,
            this.selectedParking
          );
          this._irregularOrderService
            .getIsWantIrregularOrder()
            .subscribe((r) => {
              if (r === true) {
                this.openOrder();
              }
            });
        }
      }
      this.isAllCarDetLoaded = false;
    });
  }

  openOfficeDrive() {
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];
    console.log(this.officeDriveTypes);
    this._dialog
      .open(SelectOfficeDriveTypeComponent, {
        data: { officeDriveTypes: this.officeDriveTypes },
      })
      .afterClosed()
      .subscribe((data) => {
        console.log(data);
        if (data !== undefined) {
          const ref = this.modalService.open(SelectTimeComponent, { centered: true })
          ref.result
            .then(() => {
              this.createOfficeDrive(data.type.id, data.reason);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  }

  openOrder(): void {
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];

    // check if order has changes:
    if (this.previosOrder === this._orderService.Order) {
      this._orderService.isOrderChanged = false;
    }
    this._router.navigate(['order/finishOrder']);
  }

  WashCarNow() {
    const now = new Date(Date.now());
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];

    this._orderService.Order.startTime = new Date(
      now.setMinutes(now.getMinutes() + 1)
    );
    this._orderService.Order.endTime = new Date(
      now.setMinutes(now.getMinutes() + 20)
    );

    this.createOfficeDrive();
  }

  WashCarLater() {
    const ref = this.modalService.open(SelectTimeComponent, { centered: true })
    ref.result
      .then(() => {
        this._orderService.StartParking = this.selectedParking;
        this._orderService.Car =
          this.selectedParking.carsDetails[this.selectedCarIndex];
        this.createOfficeDrive();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private createOfficeDrive(
    p_officeDriveType = null,
    p_officedriveReason = null
  ) {
    let officeDriveType = p_officeDriveType;
    if (officeDriveType == null) {
      officeDriveType = 13;
    }
    this._orderService.Order.startTime.setSeconds(0);
    this._orderService.Order.endTime.setSeconds(0);
    const employeeOrder = new EmployeeOrder(
      true,
      officeDriveType,
      this._orderService.Order
    );
    if (p_officedriveReason) {
      employeeOrder.officeDriveReason = p_officedriveReason;
    }
    this._orderService.changeCurrentOrder(employeeOrder);
    console.log(this._orderService.Order);
    const isUpdateOrder =
      this._orderService.Order &&
      this._orderService.Order.id != null &&
      this._orderService.Order.id !== undefined;
    console.log(isUpdateOrder);
    const saveOperation = !isUpdateOrder
      ? this._orderService.saveOrder(true)
      : this._orderService.updateOrder(true);
    console.log(saveOperation);

    saveOperation
      .then((order: Order) => {
        console.log(order);
        this._orderService.changeCurrentOrder(new Order());
        this._alertService.success('פעולתך בוצעה');
        this._loadingService.stopLoading();
        setTimeout(() => {
          const minutes = this.isOrderBeginNow(order);
          if (Math.abs(minutes) < 5) {
            this._orderService.openCurrenOrder = order.id;
          }
          this.carsInRent.showCarInRent(order);
          this._router.navigate(['menu']);
        }, 500);
      })
      .catch((error: ErrorResponse | any) => {
        console.error(error);
        this._alertService.error(error?.error?.error);
      });
  }

  private isOrderBeginNow(order: Order) {
    const now = moment(new Date());
    const _start = moment(order.startTime);
    const duration = moment.duration(now.diff(_start));
    const minutes = duration.asMinutes();
    console.log(minutes);
    return minutes;
  }

  emitChangeTime(): void {
    this.reloadParkings().subscribe(
      () => {
        this.isShowEditTime = false;
      },
      (error: ErrorResponse) => {
        if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
          this._alertService.error(error.CustomErrorMessage);
        }
      }
    );
  }

  zoomChange(zoom: number) {
    this.currentZoom = zoom;
    console.log('current zoom: ' + this.currentZoom);
  }

  boundsChange(event: LatLngBounds) {
    if (this.invokeBoundsChangedEvent) {
      const currentMapCenter: MapLocation = this.custToMapLocation(
        event.getCenter()
      );

      const currentMapRadius: number =
        this._googleMapsService.getDistanceFromLatLonInKm(
          this.custToMapLocation(event.getNorthEast()),
          this.custToMapLocation(event.getSouthWest())
        ) / 2;

      this.loadParking(currentMapCenter, currentMapRadius);
      this.invokeBoundsChangedEvent = false;
    }
  }

  loadParking(
    currentMapCenter: MapLocation = null,
    currentMapRadius: number = null
  ) {
    console.log('loadParking');
    this._orderService.updateIfOrderForNow();
    if (
      (this.isZoomGood &&
        this.isNewParkingsRange(currentMapCenter, currentMapRadius)) ||
      !this.invokeBoundsChangedEvent
    ) {
      // fill parkings at a bigger area than current map radius:
      this.currentRadiusOfParkings = currentMapRadius * 2;
      this.currentParkingsCenter = currentMapCenter;
      this.reloadParkings(
        this.currentParkingsCenter,
        this.currentRadiusOfParkings
      ).subscribe(
        (parkings: Parking[]) => {
          console.log(
            'parkings was loaded for radius:' + this.currentRadiusOfParkings,
            parkings
          );
          const sortedCarCategories = this.carCategories
            .filter((c) => c.isSelected === true)
            .map((c) => c.id);
          console.log(sortedCarCategories);
          console.log(this.parkings.filter((p) => p.carsDetails !== undefined));
          if (
            this.subscription.closed === false &&
            this.selectedParking !== undefined &&
            this.selectedParking != null &&
            this.selectedParking.carsDetails[0] !== undefined &&
            this.isAllCarDetLoaded === false &&
            this.selectedParking.carsDetails[0].availability === 2
          ) {
            this.setIsAllCarDetLoaded(true);
          }
        },
        (error: ErrorResponse) => {
          console.error(error);
          console.error(error);
          this._alertService.error(error.CustomErrorMessage);
        }
      );
    }
  }

  mapClick(event) {
    console.log('map click', event);
  }

  getColorByLastWashDate(lastWashDate: string): LastDateIndicator {
    return LastDateIndicator.Green;
  }

  lastWash = (lastWashDate: string): LastWashEnum => {
    return LastWashEnum.LESS_OR_EQUAL_TO_7_DAYS;
  }

  getCurrentLocationMarker() {
    return AppGlobals.POINTER_IMAGES.CURRENT_LOCATION;
  }

  getParkingPointerImgUrl(parking: Parking): string {
    if (!(parking as ParkingOnMap).isWithDetails) {
      return AppGlobals.POINTER_IMAGES.CITY_CAR_WIHTOUT_DETAILS;
    }

    const isSelectedParking: boolean =
      this.selectedParking && this.selectedParking.id === parking.id;

    const baseIconUrl = 'assets/images/small_icon_pointer_citycar';
    if (this.checkIfIsEmployDrive(parking)) {
      return (
        baseIconUrl +
        '_orange' +
        this.getParkingIconByDateAndAvailability((parking as ParkingOnMap))
      );
    }

    switch (this.getParkingAvailability(parking)) {
      case 1:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_SELECTED
          : baseIconUrl + this.getParkingIconByDateAndAvailability((parking as ParkingOnMap));
      case 2:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_ORANGE_SELECTED
          : baseIconUrl +
          '_blue' +
          this.getParkingIconByDateAndAvailability((parking as ParkingOnMap));
      case 3:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_RED_SELECTED
          : baseIconUrl +
          '_grey' +
          this.getParkingIconByDateAndAvailability((parking as ParkingOnMap));
    }
  }

  checkIfIsEmployDrive(parking: Parking): boolean {
    const now = new Date(Date.now());
    return (
      parking.carsDetails != null &&
      parking.carsDetails !== undefined &&
      parking.carsDetails.filter(
        (c) =>
          c.carTimeSlots != null &&
          c.carTimeSlots.length > 0 &&
          c.carTimeSlots.some(
            (d) =>
              d.used != null &&
              d.used.length > 0 &&
              d.used.some(
                (w) =>
                  w.odt != null &&
                  new Date(w.start) < now &&
                  new Date(w.end) > now
              )
          )
      ).length > 0
    );
  }

  getParkingIconByDateAndAvailability(parking: ParkingOnMap): string {
    return '_green_bordered.png';
  }

  // TODO RECALL
  getIconColor = (car: CarInParking) => this.getColorByLastWashDate(car.lastWashDate as unknown as string)

  getOfficeOrderTypeByEnum(): string {
    const i_1 = this.selectedParking.carsDetails.findIndex(
      (c) =>
        c.carTimeSlots != null &&
        c.carTimeSlots.find(
          (s) => s.used != null && s.used.some((t) => t.odt != null)
        ) != null
    );
    const i_2 = this.selectedParking.carsDetails[i_1].carTimeSlots.findIndex(
      (d) => d.used != null && d.used.some((k) => k.odt != null)
    );
    const i_3 = this.selectedParking.carsDetails[i_1].carTimeSlots[
      i_2
    ].used.findIndex((h) => h.odt != null);
    const odtType =
      this.selectedParking.carsDetails[i_1].carTimeSlots[i_2].used[i_3].odt;
    switch (odtType) {
      case 0:
        return 'מוסך';
      case 1:
        return 'הרשמה';
      case 2:
        return 'תיקונים';
      case 3:
        return 'שונות';
      case 4:
        return 'פנצ\'ר';
      case 5:
        return 'שטיפה';
      case 6:
        return 'טסט';
      case 7:
        return 'תדלוק';
      default:
        return 'לא ידוע';
    }
  }

  getParkingAvailability(parking: Parking): number {
    // if exists 1, return 1, else if exists 2 return 2, else return 3
    let availability = 3;
    // let isSelectedCarExists: boolean = false;
    const selectedCarCategories = this.selectedCarCategories;

    if (!parking.carsDetails) {
      return 3;
    }
    for (let i = 0; i < parking.carsDetails.length; i++) {
      if (
        selectedCarCategories.indexOf(parking.carsDetails[i].carCategory) !== -1
      ) {
        if (parking.carsDetails[i].availability === 1) {
          return 1;
        }
        if (parking.carsDetails[i].availability === 2) {
          availability = 2;
        }
      }
    }
    return availability;
  }

  private isNewParkingsRange(
    currentMapCenter: MapLocation,
    currentMapRadius: number
  ): boolean {
    if (!this.currentParkingsCenter) {
      return true;
    }

    const distanceFromParkingsCenter: number =
      this._googleMapsService.getDistanceFromLatLonInKm(
        currentMapCenter,
        this.currentParkingsCenter
      );
    return (
      distanceFromParkingsCenter + currentMapRadius >
      this.currentRadiusOfParkings
    );
  }

  closeZoomAlert() {
    this.showZoomAlert = false;
    setTimeout(() => {
      this.showZoomAlert = true;
    }, 120 * 1000);
  }

  private reloadParkings(
    center: MapLocation = this.currentParkingsCenter,
    radiosKM: number = this.currentRadiusOfParkings,
    isShowLoading: boolean = true
  ): Observable<Parking[]> {
    try {
      return this._parkingsService
        .getParkingsByCenterAndRadios(center, radiosKM, isShowLoading, true)
        .pipe(
          map(
            (parkings: ParkingOnMap[]) => {
              // updated the received parkings:
              parkings.forEach((parking) => {
                const keepOldFrequency = (oldParking: Parking, newParking: ParkingOnMap) => {
                  return {
                    ...newParking,
                    isWithDetails: true,
                  };
                };

                const parkingIndex = this.parkings.indexOf(
                  this.parkings.find((p) => p.id === parking.id)
                );

                this.parkings[parkingIndex] = keepOldFrequency(this.parkings[parkingIndex], parking);
              });

              this._loadingService.stopLoading();
              if (!this.showAllParkings) {
                this.reFilterParkings();
              }

              // refresh selected parking from new array:
              this.refreshSelectedParking();
              // force to make the binding:
              if (!this.cdRef['destroyed']) {
                this.cdRef.detectChanges();
              }

              return parkings;
            },
            (error: ErrorResponse) => {
              console.error(error);
              this._alertService.error(error.CustomErrorMessage);
            }
          )
        );
    } catch (error) {
      console.log(error);
    }
  }

  private reFilterParkings() {
    this.parkings = this.parkings.filter(
      (p) =>
        !p.carsDetails ||
        p.carsDetails.some(
          (c) => c.needsRecall)
    );
  }

  private refreshSelectedParking() {
    let parking: Parking;
    if (
      this.selectedParking &&
      (parking = this.parkings.find((p) => p.id === this.selectedParking.id))
    ) {
      this.selectedParking = parking as ParkingOnMap;
    }
  }

  getLocationBySearchOption(
    searchBy: SearchByLocationOptions
  ): Observable<MapLocation> {
    if (searchBy === SearchByLocationOptions.CurrentLocation) {
      return this._googleMapsService.getCurrentPosition();
    } else if (searchBy === SearchByLocationOptions.RegisteredAddress) {
      const locInStorage = localStorage.getItem(
        AppGlobals.LOCAL_STORAGE.Location
      );
      if (locInStorage) {
        return of(JSON.parse(locInStorage));
      } else {
        const user = this._userService.user;
        const address = `${user.street} ${user.streetNumber} ${user.city}`;
        return this._googleMapsService
          .geocodeAddress(address)
          .pipe(
            map((location: MapLocation) => {
              localStorage.setItem(
                AppGlobals.LOCAL_STORAGE.Location,
                JSON.stringify(location)
              );
              return location;
            })
          );
      }
    }
  }

  private setDefoultLocation(
    searchBy: SearchByLocationOptions
  ): Observable<MapLocation> {
    return this.getLocationBySearchOption(searchBy)
      .pipe(
        map((location: MapLocation) => {
          if (location) {
            this.mapOptions.location = location;
            this.currentLocation = location;
            this.mapOptions.zoom = this.defoultMapZoom;
            console.log('finish get current location', this.currentLocation);

            this.setMapCenter(location);

            return location;
          } else {
            location = this.defaultMapLocation;
            this.setMapCenter(location, false);
          }
        }),
        catchError(() => {
          const location = this.defaultMapLocation;
          this.setMapCenter(location, false);
          return of(location);
        })
      );
  }

  private setMapCenter(
    location: MapLocation,
    isSetAddressName: boolean = true
  ): void {
    this.mapOptions.location = location;
    this.currentLocation = location;
    this.mapOptions.zoom = this.defoultMapZoom;

    if (isSetAddressName) {
      this._googleMapsService
        .getAddressName(location)
        .subscribe((addressName: string) => {
          console.log('set address name', addressName);
          this.currentAddressName = addressName;

          // force to make the binding:
          if (!this.cdRef['destroyed']) {
            this.cdRef.detectChanges();
          }
        });
    } else {
      this.currentAddressName = null;
    }
  }

  private custToMapLocation(eventLocation): MapLocation {
    return <MapLocation>{
      lat: eventLocation.lat(),
      lon: eventLocation.lng(),
    };
  }

  navigateToCar() {
    this._globalService.navigateToCar(
      this.selectedParking.earthLocation.lat,
      this.selectedParking.earthLocation.lon
    );
  }
}

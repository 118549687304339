import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Uploads } from '../models/uploads';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';

@Injectable()
export class UploadsService {
  constructor(
    private _httpService: HttpService,
    private _urlService: UrlService
  ) {}

  sendUploads(uploads: Uploads): Observable<any> {
    return from(
      this._httpService.post(
        this._urlService.nodeUrl() + 'api/uploadsDriverLicense',
        { uploads: uploads }
      )
    );
  }
}

<div class="col-xs-12">
  <label class="col-xs-10 col-xs-offset-1 label-c text-center"
    >סוג הזמנה:</label
  >
  <div class="col-xs-10 col-xs-offset-1">
    <input
      onkeypress="event.preventDefault()"
      (blur)="officeDriveTypeSelected($event)"
      type="text"
      class="form-control-plaintext input-c"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let type of officeDriveTypes" [value]="type.name">
        {{ type.name }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <label class="col-xs-10 col-xs-offset-1 label-c text-center"
    >סיבת הזמנה:</label
  >
  <div class="col-xs-10 col-xs-offset-1" style="margin-top: 10px">
    <textarea
      name="Notes"
      rows="4"
      placeholder="סיבת ההזמנה"
      [(ngModel)]="officeDriveReason"
      [ngModelOptions]="{ standalone: true }"
    ></textarea>
  </div>
  <div class="col-xs-10 col-xs-offset-1 text-center">
    <button mat-button (click)="closeAndSendSelectedValue()">בחר</button>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import PageTitle from '../../models/page-title';
import { GoogleAuthService } from '../../services/google-auth.service';
import { UserService } from '../../services/user.service';
import { AppGlobals } from '../app-globals/app-globals';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  get identifier() {
    return this._userService.userPermission.userID;
  }

  @Input()
  tryInput: string;

  @Input()
  pageTitle: PageTitle;

  get anotherLanguage(): string {
    return this.translate.currentLang === 'he' ? 'en' : 'עב';
  }

  get userName(): string {
    if (this._userService.user && this._userService.user.firstName) {
      return (
        this._userService.user.firstName + ' ' + this._userService.user.lastName
      );
    }
    return '';
  }

  get name(): string {
    if (
      this._userService.userPermission &&
      this._userService.userPermission.name
    ) {
      return this._userService.userPermission.name;
    }
    return '';
  }

  get previousUrl(): string {
    return AppGlobals.PREVIOUS_URL;
  }

  get rightIconImage(): string {
    return AppGlobals.PREVIOUS_URL ? 'assets/images/icon_arrow_right.png' : 'assets/images/top-plus.png';
  }

  constructor(
    private _googleAuthService: GoogleAuthService,
    private _router: Router,
    private _userService: UserService,
    private translate: TranslateService,
  ) { }

  logout(): void {
    this._googleAuthService.logout();
  }

  toggleLanguage() {
    this.translate.use(this.translate.currentLang === 'en' ? 'he' : 'en');
  }

  navigateMenu() {
    this._router.navigate(['/menu']);
  }
}

<div class="map-footer car" *ngIf="car">
  <!-- .map-footer starts -->
  <!-- #show-details starts -->
  <div id="findlocation" style="display: none"></div>
  <div id="address"></div>

  <div class="map-footer car">
    <!-- .map-footer starts -->
    <div class="routerContainer" dir="rtl">
      <div class="car-detail row">
        <div class="col-xs-2" style="bottom: -27px">
          <a *ngIf="!isShowMoreDetails" (click)="toggleDetails()">
            <img
              src="assets/images/open_car_details_btn.png"
              class="toogle-details-btn"
              alt=""
            />
          </a>
          <a *ngIf="isShowMoreDetails" (click)="toggleDetails()">
            <img
              src="assets/images/close_car_details_btn.png"
              class="toogle-details-btn"
              alt=""
            />
          </a>
        </div>
        <div class="col-xs-8 text-center">
          <img
            class="daihatsu img-responsive"
            style="margin-bottom: -3px"
            src="{{ getCarsImages(car.carType).IMG }}"
            alt=""
          />
        </div>
        <div class="col-xs-2" style="bottom: -27px">
          <a (click)="closeDetails()">
            <img
              src="assets/images/close_button.png"
              class="close-btn"
              alt=""
            />
          </a>
        </div>
      </div>
      <div *ngIf="checkIfIsEmployDrive() === true" class="col-xs-12 text-center">
        <span> <b>סוג נסיעת עובדים:</b> {{ getOfficeOrderTypeByEnum() }} </span>
      </div>
      <div>
        <div class="col-xs-8">
          <h4 style="font-size: 14px; text-align: left; padding-top: 5px">
            {{ addressName }}
          </h4>
        </div>
        <div class="ufc new col-xs-4 text-center">
          <div>
            <div class="col-xs-12 col-sm-10">
              <h2>
                <span style="font-size: 14px">{{ car.carType }} </span>
                <img
                  style="width: 20px"
                  src="{{ getCarsImages(car.carType).LOGO }}"
                  alt=""
                />
                <span style="font-size: 14px"></span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div style="display: table; margin: 0 auto; margin-bottom: 15px">
        <div class="waze-navigator" (click)="navigateToCar()">
          נווט באמצעות
          <img
            alt="waze"
            src="https://img.icons8.com/color/22/000000/waze.png"
          />
        </div>
      </div>
      <div
        *ngIf="isShowMoreDetails"
        id="show-details"
        class="row"
        style="display: block"
      >
        <!-- #show-details starts -->
        <ul class="itemlist">
          <li>
            <div class="col-xs-6">
              <p>{{ car.amountOfSeats }}</p>
            </div>
            <div class="col-xs-5">
              <p>מספר מקומות</p>
            </div>
            <div class="col-xs-1">
              <img
                class="icons-details"
                src="assets/images/new-ride-point2.png"
                alt=""
              />
            </div>
          </li>
          <li>
            <div class="col-xs-6">
              <p>{{ car.gasType }}</p>
            </div>

            <div class="col-xs-5">
              <p>סוג דלק</p>
            </div>
            <div class="col-xs-1">
              <img
                class="icons-details"
                src="assets/images/new-ride-point4.png"
                alt=""
              />
            </div>
          </li>
          <li>
            <div class="col-xs-6">
              <p>{{ car.carGearType }}</p>
            </div>
            <div class="col-xs-5">
              <p>סוג הנעה</p>
            </div>
            <div class="col-xs-1">
              <img
                class="icons-details"
                src="assets/images/new-ride-point3.png"
                alt=""
              />
            </div>
          </li>
        </ul>

        <ng-content></ng-content>
      </div>
      <!-- #show-details ends -->
    </div>
  </div>
</div>

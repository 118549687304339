import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../classes';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-month-input',
  templateUrl: './month-input.component.html',
  styleUrls: ['./month-input.component.css'],
  providers: [MakeProvider(MonthInputComponent)],
})
export class MonthInputComponent extends AbstractValueAccessor {
  @Input()
  form: NgForm;

  @Input()
  isShowDay = true;

  @Input()
  maxYear: string;

  @Input()
  minYear: string;

  @Input()
  isReq = true;

  changeDate(dayOfBirth) {
    console.log(this.minYear, this.maxYear);
    if (
      !(
        !this.isShowDay ||
        (dayOfBirth.viewModel &&
          dayOfBirth.viewModel.length &&
          +dayOfBirth.viewModel > 0)
      )
    ) {
      dayOfBirth.viewModel = null;
    }
  }

  formatTo2Places(str: string): string {
    if (!str) {
      return '';
    }

    if (str.length === 1) {
      return '0' + str;
    }

    return str;
  }
}

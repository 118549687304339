import { Component, Injector, OnInit } from '@angular/core';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { FinishOrderDetailsComponent } from '../../modals/finish-order-details/finish-order-details.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { CarInRentComponent } from '../../modals/car-in-rent/car-in-rent.component';
import PageTitle from '../../models/page-title';
import { ErrorResponse } from '../../models/error-response';
import { EmployeeOrder, Order } from '../../models/order';
import { AlertService } from '../../services/alert.service';
import { LoadingService } from '../../services/loading.service';
import { OrderService } from '../../services/order.service';
import { OrderModalContext } from '../../modals/base-modal/base-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-finish-order',
  templateUrl: './finish-order.component.html',
  styleUrls: ['./finish-order.component.css'],
  providers: [TranslatePipe],
})
export class FinishOrderComponent implements OnInit {
  order = this._orderService.Order;
  display = false;

  enableFinishOrder: boolean;
  get isHasError(): boolean {
    return this.order.isInsuranceRequired == null && this.isSubmitted;
  }

  isSubmitted = false;

  showDialog() {
    this.display = true;
  }

  get pageTitle(): PageTitle {
    return {
      title: 'OPEN_NEW_ORDER',
      previousUrl: '/order/stationsInMap',
    };
  }

  get isEditOrder(): boolean {
    return this._orderService.IsEditOrder;
  }

  constructor(
    private _loadingService: LoadingService,
    public _orderService: OrderService,
    private _alertService: AlertService,
    public modal: Modal,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.order.finalPrice = this._route.snapshot.data['finalPrice'];
    console.log(this.order.finalPrice);
  }

  get priceShow() {
    if (this.order.isInsuranceRequired === undefined) {
      return false;
    } else {
      return true;
    }
  }

  finishOrder() {
    this.isSubmitted = true;
    this.enableFinishOrder = false;

    this.resetOrderTimeSecondsToZero();
    if (this.order.isInsuranceRequired != null) {
      this._orderService
        .saveOrder()
        .then((order: EmployeeOrder | Order) => {
          console.log(order);
          this.order = order;
          this.openDetailsModal();
          this.enableFinishOrder = true;
        })
        .catch((error: ErrorResponse | any) => {
          console.error(error);
          this._alertService.error(error?.error?.error);
          this.enableFinishOrder = true;
        });
    }
  }
  resetOrderTimeSecondsToZero(): any {
    this.order.startTime.setSeconds(0);
    this.order.endTime.setSeconds(0);
  }

  updateOrder(): void {
    this.resetOrderTimeSecondsToZero();
    this._orderService
      .updateOrder(true)
      .then(() => {
        this._alertService.success('ההזמנה נשמרה בהצלחה!');
        setTimeout(() => {
          this._router.navigate(['/orderList']);
        }, 1500);
      })
      .catch((error: ErrorResponse) => {
        console.error(error);
        this._alertService.error(error.CustomErrorMessage);
      });
  }

  openDetailsModal() {
    this._orderService.getCurrentOrder(alert ? false : true).subscribe(
      (currentOrder: (EmployeeOrder | Order)[]) => {
        this._loadingService.stopLoading();

        if (
          currentOrder &&
          currentOrder.length == 1 &&
          currentOrder[0].id == this.order.id
        ) {
          this.modalService.open(CarInRentComponent, {
            centered: true, injector: Injector.create(
              {
                providers: [
                  {
                    provide: Order,
                    useValue: currentOrder[0],
                  }
                ],
              }
            ),
          });

          setTimeout(() => {
            this._router.navigate(['/menu']);
          }, 500);
        } else {
          return this.modal.open(
            FinishOrderDetailsComponent,
            overlayConfigFactory(
              new OrderModalContext(
                this.order,
                (() => {
                  setTimeout(() => {
                    this._router.navigate(['/menu']);
                  }, 1000);
                }).bind(this)
              )
            )
          );
        }
      },
      (error) => {
        console.error(error);
        this._alertService.error('אירעה שגיאה, נסה שנית');
        this._loadingService.stopLoading();
      }
    );
  }

}

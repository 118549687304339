import { Directive, ElementRef, Renderer2 } from '@angular/core';

import { DialogRef, ModalComponent, CloseGuard } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { EmployeeOrder, Order } from '../../models/order';

export class OrderModalContext extends BSModalContext {
  constructor(public order: EmployeeOrder | Order, public afterCloseFn: () => void) {
    super();
  }
}

export class ModalContext<T> extends BSModalContext {
  constructor(public data: T, public afterCloseFn: () => void) {
    super();
  }
}

@Directive()
export abstract class BaseModalComponent<T extends BSModalContext>
  implements CloseGuard, ModalComponent<T>
{
  constructor(
    public dialog: DialogRef<T>,
    elementRef: ElementRef,
    renderer: Renderer2
  ) {
    // bind close dialog to click event of dialog area:
    document.addEventListener('click', (e) => {
      const target = e.target as Element;
      if (
        target &&
        (target.nodeName == 'bs-modal-container' ||
          target.nodeName == 'BS-MODAL-CONTAINER')
      ) {
        this.closeDialog();
      }
    });
  }

  abstract afterClose(): void;

  beforeDismiss?(): boolean | Promise<boolean> {
    console.log('before dismiss');
    return false;
  }

  beforeClose?(): boolean | Promise<boolean> {
    console.log('before close');
    return false;
  }

  closeDialog(): void {
    this.dialog.close();
    if (this.afterClose) {
      this.afterClose();
    }
  }

  initModal() {
    // bind close dialog event to close dialog btn:
    const closeBtn = document.getElementById('modal-close-btn');
    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        this.closeDialog();
      });
    }
  }
}

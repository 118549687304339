import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  forwardRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { currentDate } from '../../shared/function';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DateTimePickerComponent implements OnInit {
  @Input()
  isShowSmallFormat = false;

  @Input()
  showHours = true;

  @Input()
  showMinutes = true;

  @Input()
  isDisabled = false;

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this._timeValue) {
      this._timeValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  private _timeValue: Date = currentDate();
  public get timeValue(): Date {
    //this._timeValue.setMinutes(this._endTime.getMinutes());
    return this._timeValue;
  }
  public set timeValue(v: Date) {
    if (!(v instanceof Date)) v = new Date(v);

    //alert('1 set timeValue: ' + v);
    if (!isNaN(v.valueOf())) {
      //let minutes: number = v.getMinutes();

      //round to 5 minutes
      // if (minutes % 5) {
      //   minutes += 5 - minutes % 5
      //   v.setMinutes(minutes);
      // }

      this._timeValue = v;

      this.onChangeCallback(v);
    }
  }

  public set timeDate(v: string) {
    //save last values of hours and minutes:
    const timeHour: string = this.timeHour;
    const timeMinutes: string = this.timeMinutes;
    this.timeValue = new Date(
      new Date(v).toLocaleString(AppGlobals.LANGUAGES.EN, {
        timeZone: AppGlobals.TIME_ZONE.JERUSALEM,
      })
    );
    //this.timeValue = new Date(v);
    this.timeHour = timeHour;
    this.timeMinutes = timeMinutes;
  }

  private _timeTime: string;
  public get timeTime(): string {
    if (!isNaN(new Date(this.timeValue).valueOf()))
      this._timeTime = this._datepipe.transform(this.timeValue, 'HH:mm');
    return this._timeTime;
  }
  public set timeTime(v: string) {
    this.timeValue.setHours(+v.slice(0, 2));
    this.timeValue.setMinutes(+v.slice(3, 5));
    this.timeValue = new Date(this.timeValue);
    this._timeTime = v;
  }

  hoursOptions: Array<any>;
  private _timeHour: string;
  public get timeHour(): string {
    if (!isNaN(new Date(this.timeValue).valueOf()))
      this._timeHour = this._datepipe.transform(this.timeValue, 'HH');
    return this._timeHour;
  }
  public set timeHour(v: string) {
    this.timeValue.setHours(+v);
    this.timeValue = new Date(this.timeValue);

    this._timeHour = v;
  }

  minutesOptions: Array<any>;
  private _timeMinutes: string;
  public get timeMinutes(): string {
    if (!isNaN(new Date(this.timeValue).valueOf()))
      this._timeMinutes = this._datepipe.transform(this.timeValue, 'mm');
    return this._timeMinutes;
  }
  public set timeMinutes(v: string) {
    if (v) {
      this.timeValue.setMinutes(+v);
      this.timeValue = new Date(this.timeValue);
      this._timeMinutes = v;
    }
  }

  mySelectValue: Array<string>;

  constructor(private cdRef: ChangeDetectorRef, private _datepipe: DatePipe) {}

  ngOnInit() {
    this.fillHours();
    this.fillMinutes();
  }

  fillHours() {
    this.hoursOptions = [];
    for (let i = 0; i < 24; i++) {
      const hour: string = i < 10 ? '0' + i.toString() : i.toString();
      this.hoursOptions.push({
        value: hour,
        label: hour,
      });
    }
  }
  fillMinutes() {
    this.minutesOptions = [];
    for (let i = 0; i < 60; i += 1) {
      const hour: string = i < 10 ? '0' + i.toString() : i.toString();
      this.minutesOptions.push({
        value: hour,
        label: hour,
      });
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { Router } from '@angular/router';
import { AuthState } from '../enums';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import * as moment from 'moment';
import { ReplaySessionService } from './replay-session.service';

/// <reference types="gapi" />

const googleAuthErrorsList = [
  {
    error: 'idpiframe_initialization_failed',
    details: 'Cookies are not enabled in current environment.',
    messageKey: 'MESSAGES.COOKIES_ARE_NOT_ENABLED',
  },
];

@Injectable()
export class GoogleAuthService {
  count = 0;
  public afterLogout: () => void;

  constructor(
    private _router: Router,
    private zone: NgZone,
    private translateService: TranslateService,
    private alertService: AlertService,
    private replaySession: ReplaySessionService,
  ) { }

  InitGoogleAuth2Object: object = {
    client_id: AppGlobals.GOOGLE_CLIENT_ID,
    cookiepolicy: 'single_host_origin',
    scope: 'profile email',
  };

  loadClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.load('auth2', {
          callback: resolve,
          onerror: reject,
          timeout: 10000,
          ontimeout: reject,
        });
      });
    });
  }

  initClient(initObj: gapi.auth2.ClientConfig): Promise<gapi.auth2.GoogleAuthBase> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        return gapi.auth2.init(initObj).then(resolve, reject);
      });
    });
  }

  public initGapiForBrowserOnly() {
    return this.loadClient()
      .then(async () => {
        return new Promise<gapi.auth2.GoogleAuthBase>((resolve, reject) => {
          this.initClient(this.InitGoogleAuth2Object).then(resolve, reject);
        })
          .then((GoogleAuth) => {
            console.log(GoogleAuth);
          })
          .catch((error) => {
            const errorFromList = googleAuthErrorsList.find(
              (errorFromList) => error.error === errorFromList.error
            );
            if (errorFromList) {
              this.translateService
                .get(errorFromList.messageKey)
                .subscribe((message) => {
                  this.alertService.error(message, true);
                });
            }
            console.log(
              'GoogleAuthError: ' + typeof error === 'string'
                ? error
                : JSON.stringify(error)
            );
          });
      })
      .catch((error) => {
        console.log('1error in gapi.load():' + error);
      });
  }

  public browserLogin(
    prompt: 'select_account' | 'none',
    callback: (token: string) => void,
    errorCallback?: (error: any) => void,
  ): void {
    let email: string;
    gapi.auth2
      .getAuthInstance()
      .signIn({ prompt })
      .then(async (GoogleUser) => {
        const googleBasicProfile = GoogleUser.getBasicProfile();
        const email = googleBasicProfile.getEmail();
        const accessToken = GoogleUser.getAuthResponse().access_token;
        this.replaySession.sendIssueToReplaySession('after email login', {
          GoogleUser,
          email,
          googleBasicProfile,
          accessToken,
        });
        this.replaySession.sendEventToReplaySession(email, googleBasicProfile);
        this.handleGoogleLoginResponse(accessToken, 'google', callback);
      })
      .catch((error) => {
        this.replaySession.sendIssueToReplaySession('email login error', {
          email,
          error,
        });
        console.log(error.error);
        errorCallback(error);
      });
  }


  handleGoogleLoginResponse = (
    accessToken: string,
    AccessTokenType: string,
    callback: (token: string) => void
  ) => {
    const accessTokenObject = {
      timeStamp: moment().format(),
      access_token: accessToken,
    };
    localStorage.setItem(
      AppGlobals.LOCAL_STORAGE.AccessTokenObject,
      JSON.stringify(accessTokenObject)
    );
    localStorage.setItem(
      AppGlobals.LOCAL_STORAGE.AccessTokenType,
      AccessTokenType
    );
    localStorage.setItem('AuthState', AuthState.AUTHORIZED.toString());
    callback(accessToken);
  };

  public signOut() {
    gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        console.log('signOut');
      });
  }

  public login(
    callback: (token: string) => void,
    errorCallback?: (error: any) => void
  ) {
    this.browserLogin('select_account', callback, errorCallback);
  }

  logout = (callback = null, p_nextPage = 'home') => {
    localStorage.removeItem(AppGlobals.LOCAL_STORAGE.AccessTokenObject);
    localStorage.removeItem(AppGlobals.LOCAL_STORAGE.AccessToken);
    localStorage.removeItem(AppGlobals.LOCAL_STORAGE.AccessTokenType);

    if (this.afterLogout) {
      this.afterLogout();
    }

    localStorage.setItem('AuthState', AuthState.NOT_SET.toString());
    if (p_nextPage === 'register') {
      this._router.navigate([p_nextPage]).then(() => {
        console.log('register');
        if (callback) {
          callback();
        }
      });
    } else {
      this._router.navigate(['/home']).then(() => {
        if (callback) {
          callback();
        }
      });
    }
  }
}

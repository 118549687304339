import { AppGlobals } from './app-globals/app-globals';

export function newDate(string: string): Date {
  if (!string) return null;
  const b: Array<any> = string.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3] || 0, b[4] || 0, b[5] || 0);
}

export function currentDate(): Date {
  const today = new Date(
    new Date(Date.now()).toLocaleString(AppGlobals.LANGUAGES.EN, {
      timeZone: AppGlobals.TIME_ZONE.JERUSALEM,
    })
  );
  const utc_offset = isDstObserved(today) ? 7200000 : 10800000;
  const newDate = new Date(
    new Date(today).getTime() +
      new Date(today).getTimezoneOffset() * 60000 +
      utc_offset
  );
  newDate.setSeconds(0);
  return today;
}

function stdTimezoneOffset(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);

  return Math.max(
    Math.abs(jan.getTimezoneOffset()),
    Math.abs(jul.getTimezoneOffset())
  );
}

function isDstObserved(date: Date) {
  return Math.abs(date.getTimezoneOffset()) < Math.abs(stdTimezoneOffset(date));
}

export function openDocument(fileUrl) {
  window.open(fileUrl, '_blank', 'location=no');
}

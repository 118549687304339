<div id="result">
  <title>הרכבים שלנו</title>
  <section id="page-title" dir="rtl">
    <!-- #page-title starts -->
    <div class="routerContainer">
      <div class="col-xs-12 text-center">
        <h2>בחר/י רכב</h2>
        <a id="GoBack" class="linkBack" href="#"></a>
      </div>
    </div>
  </section>
  <!-- #page-title ends -->
  <section id="choose-type-of-car" class="fullwidth" dir="rtl">
    <!-- #items starts -->
    <div class="cars" align="center">
      <h3 dir="rtl"><b>׳‘׳—׳¨/׳™ ׳¨׳›׳‘:</b></h3>
      <hr />
      <div class="bx-wrapper" style="max-width: 860px; margin: 0px auto">
        <div
          class="bx-viewport"
          style="
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 223px;
          "
        >
          <div
            class="slider4"
            style="
              width: 1015%;
              position: relative;
              transition-duration: 0s;
              transform: translate3d(-1020px, 0px, 0px);
            "
          >
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car6" href="#"
                ><h4>׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 1</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/citroen-c1.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car7" href="#"
                ><h4>׳₪׳™׳׳˜ 500</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Fiat500.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car8" href="#"
                ><h4>׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 4 ׳₪׳™׳׳§׳¡׳•</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/citroen-c4.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car1" href="#"
                ><h4>׳ ׳™׳¡׳ ׳׳™׳§׳¨׳”</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Nissan-Micra.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car2" href="#"
                ><h4>׳§׳™׳” ׳₪׳™׳§׳ ׳˜׳•</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Kia-Picanto.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car3" href="#"
                ><h4>׳׳¦׳•׳‘׳™׳©׳™ ׳¡׳₪׳™׳™׳¡ ׳¡׳˜׳׳¨</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Mitsubishi-Space-St.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car4" href="#"
                ><h4>׳׳¦׳•׳‘׳™׳©׳™ ׳׳˜׳¨׳׳– - (׳׳©׳₪׳—׳×׳™)</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Mitsubishi-Attrage.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car5" href="#"
                ><h4>׳₪׳™׳’׳• 301 - (׳׳©׳₪׳—׳×׳™)</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/peugeot301.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car6" href="#"
                ><h4>׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 1</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/citroen-c1.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car7" href="#"
                ><h4>׳₪׳™׳׳˜ 500</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Fiat500.png"
                  alt=""
              /></a>
            </div>

            <div
              class="slide"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car8" href="#"
                ><h4>׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 4 ׳₪׳™׳׳§׳¡׳•</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/citroen-c4.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car1" href="#"
                ><h4>׳ ׳™׳¡׳ ׳׳™׳§׳¨׳”</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Nissan-Micra.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car2" href="#"
                ><h4>׳§׳™׳” ׳₪׳™׳§׳ ׳˜׳•</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Kia-Picanto.png"
                  alt=""
              /></a>
            </div>
            <div
              class="slide bx-clone"
              style="
                float: left;
                list-style: none;
                position: relative;
                width: 280px;
                margin-right: 10px;
              "
            >
              <a id="car3" href="#"
                ><h4>׳׳¦׳•׳‘׳™׳©׳™ ׳¡׳₪׳™׳™׳¡ ׳¡׳˜׳׳¨</h4>
                <br /><img
                  class="img-responsive"
                  src="assets/images/cars/Mitsubishi-Space-St.png"
                  alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="routerContainer">
      <div class="car-details">
        <!-- .car-details starts -->
        <div class="col-xs-12 text-center">
          <h3><b>׳”׳“׳’׳ ׳”׳׳•׳¦׳’</b></h3>
          <img
            class="img-responsive"
            src="assets/images/cars/Nissan-Micra.png"
            alt=""
          />
        </div>
        <div class="col-xs-12 text-center">
          <h2>
            ׳ ׳™׳¡׳ ׳׳™׳§׳¨׳”
            <img src="assets/images/cars/model/Nissan.png" alt="" />
          </h2>
          <!--<a class="year" href="#">׳©׳ ׳” &gt;</a>-->
        </div>
        <div class="col-xs-12 text-center">
          <ul class="rents-loc">
            <li><a href="#">5 ׳׳§׳•׳׳•׳×</a></li>
            <li><a href="#">׳׳•׳˜׳•׳׳˜׳™</a></li>
            <li class="bg-yellow">ג‚×</li>
            <li><a href="#">׳”׳—׳ ׳-19 ׳׳©׳¢׳”</a></li>
          </ul>
        </div>
        <div class="footerbtn col-lg-12 text-center">
          <!--	<a class="yellow-round-btn" href="#">׳׳™׳©׳•׳¨</a>-->
        </div>
      </div>
      <!-- .car-details ends -->
    </div>
  </section>
  <!-- #items ends -->

  <script src="js/jquery.bxslider.js"></script>
  <script type="text/javascript">
    $(document).ready(function () {
      $('.slider4').bxSlider({
        slideWidth: 280,
        minSlides: 1,
        maxSlides: 3,
        moveSlides: 1,
        auto: true,
        slideMargin: 10,
        controls: false,
      });
      $('.slide').click(function () {
        $(this).toggleClass('active');
      });

      $('#GoBack').click(function () {
        $('#result').load('main.php');
      });

      $('#car1').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳ ׳™׳¡׳ ׳׳™׳§׳¨׳”') +
            '&pic=Nissan-Micra.png&model=Nissan.png&seats=5'
        );
      });

      $('#car2').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳§׳™׳” ׳₪׳™׳§׳ ׳˜׳•') +
            '&pic=Kia-Picanto.png&model=Kia.png&seats=5'
        );
      });

      $('#car3').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳׳¦׳•׳‘׳™׳©׳™ ׳¡׳₪׳™׳™׳¡ ׳¡׳˜׳׳¨') +
            '&pic=Mitsubishi-Space-St.png&model=Mitsubishi.png&seats=5'
        );
      });

      $('#car4').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳׳¦׳•׳‘׳™׳©׳™ ׳׳˜׳¨׳׳– - (׳׳©׳₪׳—׳×׳™)') +
            '&pic=Mitsubishi-Attrage.png&model=Mitsubishi.png&seats=5'
        );
      });

      $('#car5').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳₪׳™׳’׳• 301 - (׳׳©׳₪׳—׳×׳™)') +
            '&pic=peugeot301.png&model=Peugeot.png&seats=5'
        );
      });

      $('#car6').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 1') +
            '&pic=citroen-c1.png&model=citroen.png&seats=4'
        );
      });

      $('#car7').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳₪׳™׳׳˜ 500') +
            '&pic=Fiat500.png&model=Fiat.png&seats=4'
        );
      });

      $('#car8').click(function () {
        $('#result').load(
          'choose-the-type-of-car.php?car=true&name=' +
            encodeURI('׳¡׳™׳˜׳¨׳•׳׳ ׳¡׳™ 4 ׳₪׳™׳׳§׳¡׳•') +
            '&pic=citroen-c4.png&model=citroen.png&seats=7'
        );
      });
    });
  </script>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { currentDate, newDate } from '../shared/function';
import { timeSlot } from '../models/car';

@Pipe({
  name: 'carAvailability',
})
export class CarAvailabilityPipe implements PipeTransform {
  constructor(private _datepipe: DatePipe) {}

  transform(value: timeSlot[], args?: any): any {
    if (!value || !value.length) return [];

    const availabiltyArray: string[] = [];

    value.forEach((v) => {
      let message: string;
      if (newDate(v.end) > currentDate()) {
        message =
          'הרכב תפוס בין השעות ' +
          this._datepipe.transform(newDate(v.start), 'dd/MM/yy HH:mm') +
          ' - ' +
          this._datepipe.transform(newDate(v.end), 'dd/MM/yy HH:mm');

        availabiltyArray.push(message);
      }
    });
    return availabiltyArray;
  }
}

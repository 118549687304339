import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {
  DamageReportTypeModalContext,
  DamageReportTypeComponent,
} from '../modals/damage-report-type/damage-report-type.component';
import { Order } from '../models/order';
import { AlertService } from '../services/alert.service';
import { ModalService } from '../services/modal.service';
import { OrderService } from '../services/order.service';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { currentDate } from '../shared/function';

@Injectable()
export class DamageReportResolver implements Resolve<any> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Order> {
    const obs: Observable<Order> = new Observable((observer) => {
      this._modalService.openComponentModal(
        new DamageReportTypeModalContext((isDamageBeforeStart: boolean) => {
          console.log('damageType', isDamageBeforeStart);

          //get one active order for damage report:
          if (isDamageBeforeStart) {
            this._orderService
              .getOrderForDamageReport()
              .subscribe((order: Order) => {
                if (order) observer.next(order);
                else {
                  this._alertService.error(
                    ' אין הזמנה פעילה לדווח נזק לפני השכרה'
                  );
                }

                observer.complete();
              });
          }

          //find list of orders for damage report:
          else {
            this._orderService.getOrderForDamageReportAfter().subscribe(
              (orders: Order[]) => {
                const ordersForDamage: Order[] = orders.filter(
                  (o) =>
                    //find orders for damage report:
                    o.startTime.getTime() <= currentDate().getTime() &&
                    o.endTime.getTime() +
                      AppGlobals.TIMES.HOURS_IN_TIME *
                        AppGlobals.HOURS_AFTER_RENT_FOR_DAMAGE_REPORT >=
                      currentDate().getTime()
                );

                if (ordersForDamage && ordersForDamage.length) {
                  observer.next(ordersForDamage[ordersForDamage.length - 1]);
                } else {
                  this._alertService.error('אין הזמנות מתאימות לדווח נזק');
                }

                observer.complete();
              },
              () => {
                this._alertService.error('אירעה שגיאה, נסה שוב');
                observer.complete();
              }
            );
          }
        }),
        DamageReportTypeComponent
      );
    });

    return obs;
  }

  constructor(
    private _orderService: OrderService,
    private _alertService: AlertService,
    private _router: Router,
    private _modalService: ModalService
  ) {}
}

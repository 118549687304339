import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-select-office-drive-type',
  templateUrl: './select-office-drive-type.component.html',
  styleUrls: ['./select-office-drive-type.component.css'],
})
export class SelectOfficeDriveTypeComponent {
  officeDriveTypes: { name: string; id: number }[];
  selectedDriveType: any;
  officeDriveReason: string;

  constructor(
    public dialogRef: MatDialogRef<SelectOfficeDriveTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService
  ) {
    this.officeDriveTypes = data.officeDriveTypes;
    console.log(this.officeDriveTypes);
  }

  officeDriveTypeSelected(event) {
    console.log(event.target.value);
    const id = this.officeDriveTypes.find((t) => t.name == event.target.value);
    console.log(id);
    this.selectedDriveType = id;
    console.log(this.selectedDriveType);
  }

  closeAndSendSelectedValue() {
    console.log(this.officeDriveReason);

    if (!this.selectedDriveType) {
      this.alertService.error('חובה לבחור סוג נסיעת עובדים');
      return;
    }
    if (!this.officeDriveReason || this.officeDriveReason.length < 5) {
      this.alertService.error('חובה לכתוב לפחות 5 תווים בשדה סיבה');
      return;
    }

    this.dialogRef.close({
      type: this.selectedDriveType,
      reason: this.officeDriveReason,
    });
  }
}

<section
  *ngIf="order"
  id="order"
  class="fullwidth"
  dir="rtl"
  style="margin-bottom: -100px"
>
  <!-- #items starts -->
  <div class="routerContainer">
    <ul class="small-list-items new_pickup" style="margin-top: 0">
      <li>
        <div class="orderbox_pickup_input col-xs-1 flush"></div>
        <div class="orderbox_pickup_text col-xs-9 flush">
          <h4 style="margin-top: -5px" translate>REDUCE</h4>

          <a *ngIf="isShow" (click)="isShow = false">{{
            'HIDE' | translate
          }}</a>

          <p style="font-size: small" *ngIf="isShow">
            {{ 'REDUCE_MESSAGE' | translate }}
          </p>
          <label *ngIf="isShow">
            {{ 'REDUCE2' | translate }}
          </label>

          <div>
            <input
              type="radio"
              (change)="calculateOrderPrice()"
              [(ngModel)]="order.isInsuranceRequired"
              name="isInsuranceRequired"
              [value]="true"
              [checked]="isUserWantInsurance"
            />
            {{ 'YES' | translate }}
          </div>
          <div>
            <input
              type="radio"
              (change)="calculateOrderPrice()"
              [(ngModel)]="order.isInsuranceRequired"
              name="!isInsuranceRequired"
              [value]="false"
            />
            {{ 'NO' | translate }}
          </div>
          <a *ngIf="!isShow" (click)="isShow = true">{{
            'VIEW' | translate
          }}</a>
        </div>
        <div class="orderbox_pickup_image col-xs-2 flush">
          <a data-toggle="modal" data-target="#InsuranceDetails">
            <img
              class="img-responsive"
              src="assets/images/rent_icon10.png"
              alt=""
            />
          </a>
        </div>
        <!-- <input [(ngModel)]="order.isInsuranceRequired" (change)="calculateOrderPrice()" type="checkbox" class="insuranceTrue" id="checkedINS">
            <input [(ngModel)]="!order.isInsuranceRequired" type="checkbox" class="insuranceTrue" id="checkedINS"> -->

        <!-- <div class="slider round new"></div> -->
      </li>

      <div *ngIf="_orderService.notifyNewPrice">
        <h4 class="blink" translate>NOTE_DIFF_PRICE</h4>
        <h5 class="blink_black" translate>
          {{ 'NOTE_DIFF_PRICE2' | translate }}
        </h5>
        <h5 class="blink_black" translate>
          {{ 'NOTE_DIFF_PRICE3' | translate }}
        </h5>
      </div>
      <li>
        <!-- <div class="orderbox_pickup_input col-xs-4 flush">
          <a type="button" id="type-of-rent"  ><img style="width: 30px;" src="assets/images/icon_pensil2.png" alt=""></a>
        </div> -->
        <div class="orderbox_pickup_text col-xs-10 flush">
          <h4>{{ PeriodName }}</h4>
        </div>
        <div class="orderbox_pickup_image col-xs-2 flush">
          <img
            class="img-responsive"
            src="assets/images/rent_icon2.png"
            alt=""
          />
        </div>
      </li>
      <li>
        <!-- <div class="orderbox_pickup_input col-xs-4 flush">
          <a type="button" id="car-slider-for-choosing" ><img style="width: 30px;" src="assets/images/icon_pensil2.png" alt=""></a>
        </div> -->
        <div class="orderbox_pickup_text col-xs-10 flush">
          <h4>{{ order.car.carType }}</h4>
        </div>
        <div class="orderbox_pickup_image col-xs-2 flush">
          <img
            class="img-responsive"
            src="assets/images/icon_cartype.png"
            alt=""
          />
        </div>
      </li>
      <li>
        <!-- <div class="orderbox_pickup_input col-xs-4 flush">
          <a type="button" id="choose-date-to-rent"  ><img style="width: 30px;" src="assets/images/icon_pensil2.png" alt=""></a>
        </div> -->
        <div class="orderbox_pickup_text col-xs-10 flush">
          <h5>
            <span *ngIf="!isOrderForNow">
              <h4>{{ order.startParking.name }}</h4>
              {{ 'PICKUP' | translate }}
              {{ order.startTime | customDate: 'dd-MM-yyyy' }}
              {{ 'AT' | translate }}
              {{ order.startTime | customDate: 'HH:mm' }}
              <br />
              {{ 'RETURN' | translate
              }}{{ order.endTime | customDate: 'dd-MM-yyyy' }}
              {{ 'AT' | translate }}
              {{ order.endTime | customDate: 'HH:mm' }}
              <br />
              {{ 'TOTAL' | translate }} {{ orderTimeAmount }}
              {{ order.periodName }}
            </span>

            <span *ngIf="isOrderForNow"
              >{{ 'PICKUP_NOW' | translate }} <br />{{
                'RETURN_WHILE' | translate
              }}
              {{ order.timeAmount }} {{ order.periodName }} <br />{{
                'TOTAL' | translate
              }}
              {{ order.timeAmount }} {{ order.periodName }}
            </span>
          </h5>
        </div>
        <div class="orderbox_pickup_image col-xs-2 flush">
          <img
            class="img-responsive"
            src="assets/images/icon_date.png"
            alt=""
          />
        </div>
      </li>
      <li>
        <!-- <div class="orderbox_pickup_input col-xs-4 flush">
          <a type="button" id="pickup-location"><img style="width: 30px;" src="assets/images/icon_pensil2.png" alt=""></a>
        </div> -->
      </li>
    </ul>
  </div>
</section>

<!-- 
<div class="orderbox_pickup_text col-xs-10 flush">
  <h4> {{order.startParking.name}}</h4>
 
</div>



<div class="orderbox_pickup_image col-xs-2 flush">
  <img class="img-responsive" src="assets/images/icon_location.png" alt="">
</div>
</li> -->

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { Order } from '../../models/order';
import { ErrorResponse } from '../../models/error-response';
import { AlertService } from '../../services/alert.service';
import { OrderService } from '../../services/order.service';

export class ImproveCancellOrderModalContext extends BSModalContext {
  constructor(public order: Order, public afterSuccessFn: () => void) {
    super();
  }
}

@Component({
  selector: 'app-improve-cancell-order',
  templateUrl: './improve-cancell-order.component.html',
  styleUrls: ['./improve-cancell-order.component.css'],
})
export class ImproveCancellOrderComponent
  extends BaseModalComponent<ImproveCancellOrderModalContext>
  implements OnInit
{
  cancellReason: string;
  order: Order;

  afterSuccess: () => void;

  constructor(
    public dialog: DialogRef<ImproveCancellOrderModalContext>,
    elementRef: ElementRef,
    renderer: Renderer2,

    @Inject(forwardRef(() => OrderService))
    private _orderService: OrderService,
    @Inject(forwardRef(() => AlertService))
    private _alertService: AlertService
  ) {
    super(dialog, elementRef, renderer);
    this.order = dialog.context.order;
    this.afterSuccess = dialog.context.afterSuccessFn;
  }

  ngOnInit() {
    this.initModal();
  }

  afterClose(): void {
    //throw new Error("Method not implemented.");
  }

  cancellOrder() {
    this._orderService
      .cancellOrder(this.cancellReason, this.order.id)
      .subscribe(
        (data) => {
          console.log('cancellOrder', data);
          this.afterSuccess();
          this._alertService.success('ההזמנה בוטלה');
          this.closeDialog();
        },
        (error: ErrorResponse) => {
          console.error(error);
          console.error(error);
          this._alertService.error(error.CustomErrorMessage);
          this.closeDialog();
        }
      );
  }
}

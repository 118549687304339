import { Routes } from '@angular/router';

import {
  HomeComponent,
  MenuComponent,
  LocationComponent,
  CarComponent,
  AccidentReportingComponent,
  DealsComponent,
  OrderListComponent,
  OrderSummaryComponent,
  OrderUpdateComponent,
  StationsInMapComponent,
  SelectTimeComponent,
  StatusComponent,
} from '../pages';
import { OrderPeriodComponent } from '../pages/order-period/order-period.component';
import { AuthGuard } from '../guards';
import { FinishOrderComponent } from '../pages/finish-order/finish-order.component';
import { OrderDatailsExistGuard } from '../guards/order-datails-exist.guard';
import { ContactComponent } from '../pages/contact/contact.component';
import { MessagesComponent } from '../pages/messages/messages.component';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { DamageReportComponent } from '../pages/damage-report/damage-report.component';
import { DamageReportResolver, OpenOrderResolver } from '../resolvers/index';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: 'menu',
    component: MenuComponent,
    canActivate: [AuthGuard],
  },
  { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
  { path: 'location', component: LocationComponent, canActivate: [AuthGuard] },
  { path: 'car', component: CarComponent, canActivate: [AuthGuard] },
  {
    path: 'orderList',
    component: OrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'orderSummary',
    component: OrderSummaryComponent,
    canActivate: [AuthGuard],
  },
  { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },

  {
    path: 'order/period',
    component: OrderPeriodComponent,
    canActivate: [AuthGuard, OrderDatailsExistGuard],
  },
  {
    path: 'order/selectTime',
    component: SelectTimeComponent,
    canActivate: [AuthGuard, OrderDatailsExistGuard],
  },
  {
    path: 'order/stationsInMap',
    component: StationsInMapComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'order/finishOrder',
    component: FinishOrderComponent,
    canActivate: [AuthGuard, OrderDatailsExistGuard],
    resolve: {
      finalPrice: OpenOrderResolver,
    },
  },
  {
    path: 'order/orderUpdate',
    component: OrderUpdateComponent,
    canActivate: [AuthGuard],
  },

  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
  {
    path: 'userProfile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  { path: 'deals', component: DealsComponent, canActivate: [AuthGuard] },

  { path: 'accidentReport', component: AccidentReportingComponent },
  {
    path: 'damageReport',
    component: DamageReportComponent,
    resolve: {
      order: DamageReportResolver,
    },
    canActivate: [
      // IsDataExistsGuard,
      AuthGuard,
    ],
  },

  { path: '', redirectTo: '/menu', pathMatch: 'full' },
  { path: '*', redirectTo: '/menu', pathMatch: 'full' },
  { path: '**', redirectTo: '/menu', pathMatch: 'full' },
];

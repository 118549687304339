<app-page-title [pageTitle]="pageTitle"></app-page-title>

<div class="text-center">
  <button
    class="btn-purple"
    style="width: -webkit-fill-available"
    [routerLink]="['/userProfile']"
  >
    <i class="fas fa-sign-out-alt" translate></i>
    {{ 'BACK_PERSONAL_AREA' | translate }}
  </button>
</div>

<div
  *ngIf="
    userStatus.balanceOfCredit !== undefined && userStatus.balanceOfCredit !== 0
  "
  class="col-xs-12 balance-div"
  dir="rtl"
>
  <h4 class="balance">
    <div
      dir="ltr"
      class="col-xs-6"
      [ngClass]="{
        'positive-balance': userStatus.balanceOfCredit > 0,
        'negative-balance': userStatus.balanceOfCredit < 0
      }"
    >
      ₪ {{ userStatus.balanceOfCredit }}
    </div>
    <div class="col-xs-6" style="float: right; text-align: left" translate>
      YOUR_BALANCE
    </div>
  </h4>
</div>
<div *ngIf="!isShowSearchComponent" class="center-div">
  <button
    class="chooes-btn center-div"
    (click)="showSearchComponent()"
    translate
  >
    SEARCH_BY_DATES
  </button>
</div>
<div
  *ngIf="isShowSearchComponent"
  dir="rtl"
  class="border-div"
  [ngClass]="{
    'col-xs-offset-1': !isShowSmallFormat,
    'col-xs-10': !isShowSmallFormat,
    'col-xs-12': isShowSmallFormat
  }"
>
  <h4 class="title-popup" translate>START_RANGE</h4>
  <app-date-time-picker
    [(ngModel)]="startTime"
    [isShowSmallFormat]="false"
  ></app-date-time-picker>
  <!-- <app-validator-messages [validators]="startTimeValidations" [value]="startTime"></app-validator-messages> -->

  <h4 class="title-popup" translate>END_RANGE</h4>
  <app-date-time-picker
    [(ngModel)]="endTime"
    [isShowSmallFormat]="false"
  ></app-date-time-picker>
  <!-- <app-validator-messages [validators]="endTimeValidations" [value]="endTime"></app-validator-messages> -->

  <button class="chooes-btn center-div" (click)="getStatus()" translate>
    SEARCHE_TRANSCTIONS
  </button>
</div>

<section class="fullwidth" dir="rtl">
  <div class="col-xs-12 class-title tabs">
    <button
      [ngClass]="{ 'active-tab': selectedTab === 2 }"
      class="col-xs-4"
      (click)="showCashBanks()"
      translate
    >
      CASH
    </button>
    <button
      [ngClass]="{ 'active-tab': selectedTab === 1 }"
      class="col-xs-4"
      (click)="showRefunds()"
      translate
    >
      CREDITS
    </button>
    <button
      [ngClass]="{ 'active-tab': selectedTab === 0 }"
      class="col-xs-4 active-tab"
      (click)="showMovments()"
      translate
    >
      TRANSCTIONS
    </button>
  </div>

  <div class="routerContainer">
    <div style="margin-top: 3.5px">
      <ng-template [ngIf]="selectedTab === 0">
        <div
          *ngFor="let balanceDetail of userStatus.balanceDetails; let i = index"
        >
          <ul (click)="openDetails(i)" class="small-list-items new-style">
            <li>
              <div class="col-xs-2">
                <p>
                  <b>{{ 'ORDER' | translate }}: {{ balanceDetail.ORDER_ID }}</b>
                </p>
              </div>

              <div
                style="text-align: center"
                class="col-xs-7 flush new_page_onelist"
              >
                <p>
                  <b>{{
                    balanceDetail.TIME_CHARGED | customDate: 'dd/MM/yyyy HH:mm'
                  }}</b>
                </p>
                <p>
                  <b class="class-color">
                    <strong>סכום:</strong>
                  </b>
                  <b
                    dir="ltr"
                    [ngClass]="{
                      'positive-number': balanceDetail.AMOUNT >= 0,
                      'negative-number': balanceDetail.AMOUNT < 0
                    }"
                  >
                    ₪ {{ balanceDetail.AMOUNT }}</b
                  >
                </p>
              </div>

              <div class="col-xs-3 flush new_page_onelist">
                <p>
                  <b>{{ balanceDetail.NOTES }} </b>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
      <ng-template [ngIf]="selectedTab === 1">
        <div *ngFor="let refound of refounds; let i = index">
          <ul (click)="openDetails(i)" class="small-list-items new-style">
            <li>
              <div *ngIf="refound.FILLED_IN_ORDER_ID === undefined">
                <div class="col-xs-7 flush new_page_onelist">
                  <p>
                    <b *ngIf="refound.REDEEMED_ON_ORDER_ID !== null">
                      <b class="class-color" translate>RECEIVED_IN_ORDER </b>
                      {{ refound.REDEEMED_ON_ORDER_ID }}
                      <b class="class-color">בתאריך:</b>
                      {{ refound.DATE_REDEEMED | customDate: 'yyyy-MM-dd' }}</b
                    >
                    <b
                      *ngIf="
                        refound.VALIDITY_END >= Now &&
                        refound.REDEEMED_ON_ORDER_ID === null
                      "
                    >
                      <b class="class-color" translate> VALIDITY</b>
                      {{ refound.VALIDITY_END | customDate: 'yyyy-MM-dd' }}
                    </b>
                    <b
                      *ngIf="
                        refound.VALIDITY_END < Now &&
                        refound.REDEEMED_ON_ORDER_ID === null
                      "
                      class="text-danger"
                      translate
                      >EXPIRED</b
                    >
                  </p>
                </div>
                <div class="col-xs-1 flush new_page_onelist"></div>
                <div class="col-xs-4 flush new_page_onelist">
                  <p class="class-color" dir="rtl">
                    <b>{{ 'COUPON' | translate }} {{ refound.NOTE }}</b>
                  </p>
                </div>
              </div>

              <div *ngIf="refound.FILLED_IN_ORDER_ID !== undefined">
                <div class="col-xs-2 flush new_page_onelist">
                  <p>
                    <b *ngIf="refound.REDEEMED_IN_ORDER_ID !== null">
                      <b class="class-color" translate>RECEIVED_IN_ORDER</b>
                      {{
                        refound.REDEEMED_IN_ORDER_ID | customDate: 'yyyy-MM-dd'
                      }}
                    </b>
                    <b
                      *ngIf="
                        refound.COUPON_EXPIRE_DATE >= Now &&
                        refound.REDEEMED_IN_ORDER_ID === null
                      "
                    >
                      <b class="class-color" translate> VALIDITY</b>
                      {{ refound.COUPON_EXPIRE_DATE }}
                    </b>
                    <b
                      *ngIf="
                        refound.COUPON_EXPIRE_DATE < Now &&
                        refound.REDEEMED_IN_ORDER_ID === null
                      "
                      class="text-danger"
                      translate
                    >
                      EXPIRED
                    </b>
                  </p>
                </div>
                <div class="col-xs-8 flush new_page_onelist">
                  <p>
                    <b>
                      <b class="class-color" translate>FILLING_DATE </b
                      >{{ refound.REFILL_DATE }}
                      <b class="class-color" translate>IN_ORDER </b>
                      {{ refound.FILLED_IN_ORDER_ID }}</b
                    >
                  </p>
                </div>
                <div class="col-xs-2 flush new_page_onelist">
                  <p class="class-color">
                    <b translate>FUEL_FILLING</b>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
      <ng-template [ngIf]="selectedTab === 2">
        <div *ngFor="let cashBank of userStatus.cashBanks; let i = index">
          <ul (click)="openDetails(i)" class="small-list-items new-style">
            <li>
              <div class="col-xs-8 flush new_page_onelist">
                <p>
                  <b class="class-color" translate>CREATED </b>
                  <b>{{ cashBank.ADDED | customDate: 'dd/MM/yyyy HH:mm' }}</b>
                </p>
                <p>
                  <b class="class-color" translate>VALIDITY </b>
                  <b>{{ cashBank.EXPIRY }}</b>
                </p>
              </div>
              <div class="col-xs-4 flush new_page_onelist">
                <p>
                  <b translate>AMOUNT </b>
                  <b class="positive-number">{{ cashBank.AMOUNT }} ₪ </b>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
</section>

import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

export class Reloadable<T> {
  private _value: T;
  public get value(): T {
    return this._value;
  }
  public set value(v: T) {
    this._value = v;
    this.isTimeToReload = false;
    setTimeout(() => {
      this.isTimeToReload = true;
    }, this.timeToReload);
  }

  private _timeToReload: number;
  public get timeToReload(): number {
    return this._timeToReload;
  }
  public set timeToReload(v: number) {
    this._timeToReload = v;
  }

  isTimeToReload = true;

  getObservable(getDetails: Observable<T>): Observable<T> {
    if (this.isTimeToReload)
      return getDetails.pipe(
        tap((value: T) => (this.value = value))
      );

    return of(this.value);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { ActivatedRoute } from '@angular/router';
import PageTitle from '../../models/page-title';
import { DamageReport } from '../../models/damage-report';
import { Order } from '../../models/order';
import { AlertService } from '../../services/alert.service';
import { MessagesService } from '../../services/messages.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { ErrorResponse } from '../../models/error-response';

@Component({
  selector: 'app-damage-report',
  templateUrl: './damage-report.component.html',
  styleUrls: ['./damage-report.component.css'],
})
export class DamageReportComponent implements OnInit {
  get pageTitle(): PageTitle {
    return {
      title: 'DAMAGE_REPORT',
      previousUrl: '/menu',
    };
  }
  orderId: number;
  isSubmitted = false;

  get customStyle() {
    return AppGlobals.STYLES.IMAGE_UPLOAD;
  }

  damageReports: DamageReport[] = [];
  order: Order;

  get damageReportsToSend() {
    return this.damageReports;
  }

  get maxImagesSize(): number {
    return AppGlobals.IMAGES_DATA.MAX_SIZE_KB.DAMAGE_REPORT;
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _messagesService: MessagesService,
  ) {}

  ngOnInit() {
    if (!this._route.snapshot.data['order']) this._router.navigate(['']);
    else {
      this.order = this._route.snapshot.data['order'];
      this.orderId = this.order.id;
    }
  }

  sendDamageReport() {
    console.log('damage reports to send', this.damageReportsToSend);

    if (this.damageReportsToSend.find((d) => !d.note)) {
      this.isSubmitted = true;
      return;
    }
    this._messagesService
      .damageReports(this.order.id, this.damageReportsToSend)
      .subscribe(
        () => {
          this._alertService.success('הדווח נשלח בהצלחה!');
          setTimeout(() => {
            this._router.navigate(['menu']);
          }, 1000);
        },
        (error: ErrorResponse) => {
          console.error(error);
          this._alertService.error('לא כל התמונות נשלחו, נסה שוב');
        }
      );
  }

  onUploadFinished(event: FileHolder) {
    this.addDamageReport(<DamageReport>{ image: event.src, file: event.file });
  }

  onRemoved(event: FileHolder) {
    this.damageReports.splice(
      this.damageReports.indexOf(
        this.damageReports.find((i) => i.image == event.src)
      ),
      1,
    );
  }

  removePicture(i) {
    this.damageReports.splice(i, 1);
  }

  addDamageReport(damageReport: DamageReport) {
    this.damageReports.push(damageReport);
    console.log('this.damageReports', this.damageReports);
  }
}

import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Parking, ParkingOnMap } from '../models/parking';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs/Subject';
import { GetTimeSlotsBody, filterParkingBody } from '../models/request-models';
import { GlobalService } from './global.service';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { timeSlot, CarInParking } from '../models/car';
import { City } from '../models/city';
import { MapLocation } from '../models/map-location';
import { EmployeeOrder, Order } from '../models/order';
import { Reloadable } from '../models/reloadable';
import { TimeSlotsPipe } from '../pipes/time-slots.pipe';
import { DatesService } from './dates.service';
import { GoogleMapsService } from './google-maps.service';
import { HttpService } from './http.service';
import { LoadingService } from './loading.service';
import { UserService } from './user.service';
import { flatMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ParkingsService {
  baseParkingsUrl = 'parkings/';

  permittedParkings: any[] = [];

  private parkingsCities: Reloadable<City[]> = new Reloadable<City[]>();
  private isAllParkingsLoaded: boolean;
  private dataSubject: Subject<any> = new Subject<any>();
  public OriginalParkings: any[];
  private _parkings: ParkingOnMap[];

  getOrder: () => Order;

  parkingsToLoadFirst: number[];

  getIsAllParkingsLoaded() {
    return this.dataSubject.asObservable();
  }
  setIsAllParkingsLoaded(isAllParkingsLoaded) {
    this.isAllParkingsLoaded = isAllParkingsLoaded;
    this.dataSubject.next(this.isAllParkingsLoaded);
  }

  get parkings(): ParkingOnMap[] {
    return this._parkings;
  }
  set parkings(v) {
    this._parkings = v;
  }

  filterByRecall(parkings: ParkingOnMap[]): ParkingOnMap[] {
    return parkings.filter(
      (p) => !p.carsDetails || p.carsDetails.some((c) => c.needsRecall));
  }

  constructor(
    private _httpService: HttpService,
    private _datepipe: DatePipe,
    private _globalService: GlobalService,
    @Inject(forwardRef(() => LoadingService))
    private _loadingService: LoadingService,
    @Inject(forwardRef(() => DatesService))
    private _datesService: DatesService,
    private _timeSlotsPipe: TimeSlotsPipe,
    @Inject(forwardRef(() => GoogleMapsService))
    private _googleMapsService: GoogleMapsService,
    @Inject(forwardRef(() => UrlService))
    private _urlService: UrlService,
    @Inject(forwardRef(() => UserService))
    private _userService: UserService
  ) { }

  registerFromOrderService(getOrder: () => Order) {
    this.getOrder = getOrder;
  }

  getAllParkingsCities(): Observable<City[]> {
    if (this.parkingsCities.value)
      return of(this.parkingsCities.value);
    return from(
      this._httpService.get<City[]>(
        this._urlService.baseUrl() + this.baseParkingsUrl + ' '
      )
    ).pipe(tap((cities: City[]) => (this.parkingsCities.value = cities)));
  }

  getParkingsByCenterAndRadios = (
    center: MapLocation,
    radiusKM: number,
    isShowLoading: boolean = true,
    getAll: boolean = false
  ): Observable<ParkingOnMap[]> => {
    const startTime: Date = this.getOrder().startTime;
    const endTime: Date = this.getOrder().endTime;
    const orderTimeSlot: timeSlot = this._datesService.datesToTimeSlot(
      startTime,
      endTime
    );

    // get all parkigns form the radius area that are not with details, or that are not cover current order's time slot:
    let parkingsNotToReload: ParkingOnMap[] = [];
    const parkingsIds = getAll
      ? this.parkings.map((p) => p.id)
      : this.getParkingsIdsToReload(center, radiusKM, parkingsNotToReload);

    console.log(this.parkings.filter((p) => p.carsDetails != null));

    return this.getParkingsWithCarsAndTimeSlots(parkingsIds, isShowLoading).pipe(
      map(
        (parkings: ParkingOnMap[]) => {
          // filter parkingsNotToReload by order time slots, not take if it in parkings list(orderIdToIgnore):
          parkingsNotToReload = parkingsNotToReload
            .filter((parking) => !parkings.find((p) => p.id == parking.id))
            .map((parking) => {
              if (parking.carsDetails) {
                parking.carsDetails.forEach((car) => {
                  car.availability = this.getCarAvailability(
                    car.timeSlots,
                    orderTimeSlot
                  );
                });
              }
              return parking;
            });

          const p = parkings.concat(parkingsNotToReload);
          console.log('this.parkings[0]', this.parkings[0], p);
          return parkings.concat(parkingsNotToReload);
        }
      )
    );
  };

  getParkingsWithCarsAndTimeSlots = (
    parkingsIds: number[],
    isShowLoading: boolean = true
  ): Observable<ParkingOnMap[]> => {
    this.setIsAllParkingsLoaded(false);

    let parkingsObs: Observable<ParkingOnMap[]>;
    let orderIdToIgnore: number;
    let parkingOfOrderToIgnore: number;

    const startTime: Date = this.getOrder().startTime;
    const endTime: Date = this.getOrder().endTime;
    const orderTimeSlot: timeSlot = this._datesService.datesToTimeSlot(
      startTime,
      endTime
    );

    // add 5 minutes to end time:
    // endTime = newDate(endTime.getTime() + AppGlobals.TIMES.MINUTES_IN_TIME * 5);

    // if it has to ignore one order:
    if (this.getOrder().id) {
      orderIdToIgnore = this.getOrder().id;
      parkingOfOrderToIgnore = this.getOrder().startParking.id;

      // remove time slots of current order:
      // if that parking's id is not have to be reloaded, add it to the list, and reload it again:
      if (parkingsIds.indexOf(parkingOfOrderToIgnore) === -1) {
        parkingsIds.push(this.getOrder().startParking.id);
      }
    }

    if (!parkingsIds.length) parkingsObs = of([]);
    else {
      if (isShowLoading) this._loadingService.startLoading();

      parkingsObs = this.getParkingsWithCars(parkingsIds, false)
        .pipe(
          flatMap((parkings) =>
            this.mapParkingsWithDetails(
              parkings,
              orderIdToIgnore,
              startTime,
              endTime
            )
          ),
          map((parkings: Parking[]) => {
            // parkings to return to current call:
            const reloadedParkings: ParkingOnMap[] =
              this._globalService.copyObject(parkings);

            // contact time slots of less then hour between:
            reloadedParkings.forEach((parking) => {
              if (parking.carsDetails) {
                parking.carsDetails.forEach((car) => {
                  car.timeSlots =
                    this._timeSlotsPipe.concatTimeSlotsOfLessThenHourBetween(
                      car.timeSlots,
                      startTime,
                      endTime
                    );
                });
              }
            });

            this.setIsAllParkingsLoaded(true);

            // save parkings in parkings list:

            this.insertParkings(parkings, orderTimeSlot);
            console.log('finish get cars availability');
            if (isShowLoading) {
              this._loadingService.stopLoading();
            }

            return reloadedParkings;
          })
        );
    }

    return parkingsObs.pipe(
      map((parkings: ParkingOnMap[]) => {
        // filter, return only parkings with cars
        // allParkings = allParkings.filter(p => p.carsDetails && p.carsDetails.length);

        // return parkings, with used time slots of current order:
        parkings.forEach((parking) => {
          if (parking.carsDetails) {
            parking.carsDetails.forEach((car) => {
              car.availability = this.getCarAvailability(
                car.timeSlots,
                orderTimeSlot
              );

              // if ignored current order time slots, add it to the saved parkings:
              if (
                parking.id === parkingOfOrderToIgnore &&
                car.carNumber === this.getOrder().car.carNumber
              ) {
                const currentOrderTimeSlot =
                  this._datesService.datesToTimeSlot(
                    this.getOrder().startTime,
                    this.getOrder().endTime
                  );

                const savedParking = this.parkings.find(
                  (p) => p.id === parking.id
                );
                const savedParkingIndex = this.parkings.indexOf(savedParking);

                const savedCar: CarInParking = savedParking.carsDetails.find(
                  (c) => c.carNumber === car.carNumber
                );
                const savedCarIndex: number =
                  savedParking.carsDetails.indexOf(savedCar);

                this.parkings[savedParkingIndex].carsDetails[
                  savedCarIndex
                ].timeSlots.push(
                  this._timeSlotsPipe.add10MinutesToTimeSlot(currentOrderTimeSlot)
                );
                this.parkings[savedParkingIndex].carsDetails[
                  savedCarIndex
                ].timeSlots = this._timeSlotsPipe.mergeTimeSlotsArray(
                  this.parkings[savedParkingIndex].carsDetails[savedCarIndex]
                    .timeSlots
                );
              }
            });
          }
        });
        return parkings;
      })
    );
  }

  getParkingById(parkingId: number): Parking {
    return this.OriginalParkings.find((p) => p.id == parkingId);
  }

  // filter, only time slot of range:
  updateParkingsTimeSlotsByRange(isShowLoading: boolean = true): Parking[] {
    if (isShowLoading) {
      this._loadingService.startLoading();
    }

    this.parkings.forEach((parking: ParkingOnMap) => {
      if (parking.carsDetails) {
        parking.carsDetails.forEach((car) => {
          car.availability = !car.timeSlots ? 3 : car.timeSlots.length ? 2 : 1;
        });
      }
    });

    this._loadingService.stopLoading();
    return this.parkings;
  }

  addCatchedTimeSlotOfNewOrder(order: EmployeeOrder | Order) {
    const parkingId: number = order.startParking.id;
    const parking: ParkingOnMap = this.parkings.find((p) => p.id === parkingId);

    if (parking.isWithDetails) {
      const car: CarInParking = parking.carsDetails.find(
        (o) => o.carNumber === order.car.carNumber
      );
      if (car) {
        const addedTimeSlot: timeSlot = this._timeSlotsPipe.createTimeSlot(
          order.startTime,
          order.endTime
        );
        car.timeSlots.push(
          this._timeSlotsPipe.add10MinutesToTimeSlot(addedTimeSlot)
        );
        car.timeSlots = this._timeSlotsPipe.mergeTimeSlotsArray(car.timeSlots);
      }
    }
  }

  removeCatchedTimeSlotOfCancelledOrder(order: EmployeeOrder | Order) {
    const parkingId: number = order.startParking.id;
    const parking: ParkingOnMap = this.parkings.find((p) => p.id == parkingId);

    if (parking.isWithDetails) {
      // new:::
      // sign this order time slots at the parking's checked time slots as not loaded:
      const removedTimeSlot: timeSlot = this._timeSlotsPipe.createTimeSlot(
        order.startTime,
        order.endTime
      );
      const orderCatchedTimeSlot: timeSlot =
        this._timeSlotsPipe.add10MinutesToTimeSlot(removedTimeSlot);
      parking.checkedTimeSlots =
        this._timeSlotsPipe.cutTimeSlotFromTimeSlotsArray(
          parking.checkedTimeSlots,
          orderCatchedTimeSlot
        );

      // remove order catched time slots from car catched time slots:
      const car: CarInParking = parking.carsDetails.find(
        (c) => c.carNumber == order.car.carNumber
      );
      if (car) {
        // let removedTimeSlot: timeSlot = this._timeSlotsPipe.createTimeSlot(order.startTime, order.endTime);
        // let orderCatchedTimeSlot: timeSlot = this._timeSlotsPipe.add10MinutesToTimeSlot(removedTimeSlot);
        car.timeSlots = this._timeSlotsPipe.cutTimeSlotFromTimeSlotsArray(
          car.timeSlots,
          orderCatchedTimeSlot
        );
      }
    }
  }

  private getParkingsCatchedTimeSolts(
    parkingIds: number[],
    startTime: Date,
    endTime: Date,
    orderIdToIgnore: number,
    isShowLoading: boolean = true
  ): Observable<any> {
    const body: GetTimeSlotsBody = <GetTimeSlotsBody>{
      Parkings: parkingIds,
      Range: {
        start: this._datepipe.transform(startTime, 'yyyy-MM-ddTHH:mm:ss'),
        end: this._datepipe.transform(endTime, 'yyyy-MM-ddTHH:mm:ss'),
      },
      orderIdToIgnore: orderIdToIgnore,
    };
    return from(
      this._httpService.put(
        this._urlService.baseUrl() + 'timeSlots?pretty=1',
        body,
        isShowLoading
      )
    );
  }

  private mapParkingsWithDetails = (
    parkings: Parking[],
    orderIdToIgnore: number,
    startTime: Date = null,
    endTime: Date = null,
  ): Observable<Parking[]> => {
    // add time solts to cars in parkings
    if (parkings && parkings.length && startTime && endTime) {
      const ParkingsIds: number[] = parkings.map((p) => p.id);
      const allParkingsInOneObs = this.getParkingsCatchedTimeSolts(
        ParkingsIds,
        startTime,
        endTime,
        orderIdToIgnore
      ).pipe(
        map((parkingsTimeSlots) => {
          parkings = this._timeSlotsPipe.insertTimeSlotsInParkings(
            parkings,
            parkingsTimeSlots,
            startTime,
            endTime
          );
        })
      );
      return allParkingsInOneObs.pipe(
        map((_) => {
          return parkings;
        })
      );
    } else {
      return of(parkings);
    }
  };

  private mapParkingsWithCars(parkings: ParkingOnMap[]): ParkingOnMap[] {
    // remove year from car type string
    parkings.forEach((parking) => {
      parking.carsDetails.forEach((car) => {
        car.carType = car.carType.slice(0, car.carType.length - 5);
      });
    });
    return parkings;
  }

  private getParkings(
    body: filterParkingBody,
    isShowLoading: boolean = true
  ): Observable<ParkingOnMap[]> {
    return from(
      this._httpService.post(
        this._urlService.baseUrl() +
        this.baseParkingsUrl +
        'FullDetails?pretty=1',
        body,
        isShowLoading
      )
    );
  }

  private getParkingsWithCars(
    parkingIds: number[],
    isShowLoading: boolean = true
  ): Observable<ParkingOnMap[]> {
    const existParkings: ParkingOnMap[] = [];
    const idsToReload: number[] = [];
    parkingIds.forEach((parkingId: number) => {
      const existParking: Parking = this.parkings.find((p: ParkingOnMap) => {
        return p.id == parkingId && p.isWithDetails == true;
      });

      // if parking's details are already exist, take it, and not load this parking:
      if (existParking) {
        existParkings.push(this._globalService.copyObject(existParking));
      } else {
        idsToReload.push(parkingId);
      }
    });

    if (!idsToReload.length) {
      return of(existParkings);
    }

    const body: filterParkingBody = <filterParkingBody>{
      ParkingIDs: idsToReload,
      WithCars: true,
    };
    return from(
      this._httpService.post(
        this._urlService.baseUrl() +
        this.baseParkingsUrl +
        'FullDetails?pretty=1',
        body,
        isShowLoading
      )
    ).pipe(
      map((parkings: ParkingOnMap[]) => {
        parkings = this.mapParkingsWithCars(parkings);
        return parkings.concat(existParkings);
      })
    );
  }

  public getParkingsByCityId(cityId: number): Observable<ParkingOnMap[]> {
    return this.getAllParkingsCities()
      .pipe(
        map((cities: City[]) => cities.find((c) => c.id === cityId)),
        map((city: City) => this.parkings.filter((parking) => parking.name.slice(0, parking.name.indexOf('-')) === city.name))
      );
  }

  // insert list of parkings to this.parkings, if parking is exists, replace exists parking with the new one:
  private insertParkings(parkings: Parking[], checkedTimeSlot: timeSlot): void {
    const parkingsOnMap: ParkingOnMap[] = parkings.map(
      (p) => new ParkingOnMap(p, true)
    );

    parkingsOnMap.forEach((parking: ParkingOnMap) => {
      const parkingIndex: number = this.parkings.indexOf(
        this.parkings.find((p) => p.id == parking.id)
      );

      parking.checkedTimeSlots.push(checkedTimeSlot);

      // order time slots by date:
      if (parking.carsDetails) {
        parking.carsDetails.forEach((c) => {
          c.timeSlots = this._timeSlotsPipe.orderTimeSlotsArray(c.timeSlots);
        });
      }

      if (parkingIndex === -1) {
        // insert new parking:
        this.parkings.push(parking);
      } else {
        // if parking was reload because of new time slot, save also old time slots:
        if (!this.parkings[parkingIndex].isTimeToReload) {
          const oldCheckedTimeSlots: timeSlot[] =
            this.parkings[parkingIndex].checkedTimeSlots;
          parking.checkedTimeSlots = oldCheckedTimeSlots || [];

          // check if checked time slot is already exists in parking's list:
          if (
            !this._datesService.isTimeSlotsInTimesSlotsArrray(
              oldCheckedTimeSlots || [],
              checkedTimeSlot
            )
          ) {
            // save old checked time slots in the parking
            parking.checkedTimeSlots.push(checkedTimeSlot);
          }
          parking.checkedTimeSlots = this._timeSlotsPipe.mergeTimeSlotsArray(
            parking.checkedTimeSlots
          );

          // save the old catched time slots in the car:
          if (parking.carsDetails) {
            for (let i = 0; i < parking.carsDetails.length; i++) {
              const newCar: CarInParking = parking.carsDetails[i];
              let oldCar: CarInParking;

              newCar.timeSlots = this._timeSlotsPipe.mergeTimeSlotsArray(
                newCar.timeSlots
              );

              if (
                this.parkings[parkingIndex].carsDetails &&
                (oldCar = this.parkings[parkingIndex].carsDetails.find(
                  (c) => c.carNumber == newCar.carNumber
                ))
              ) {
                const oldCatchedTimeSlots: timeSlot[] = oldCar.timeSlots;
                newCar.timeSlots = (newCar.timeSlots || []).concat(
                  oldCatchedTimeSlots || []
                );
                newCar.timeSlots = this._timeSlotsPipe.orderTimeSlotsArray(
                  newCar.timeSlots
                );
                newCar.timeSlots = this._timeSlotsPipe.mergeTimeSlotsArray(
                  newCar.timeSlots
                );
              }
            }
          }
        }
        this.parkings[parkingIndex] = <ParkingOnMap>parking;
      }
    });
  }

  private getParkingsIdsToReload(
    center: MapLocation,
    radiusKM: number,
    parkingsNotToReload: ParkingOnMap[] = []
  ): number[] {
    const parkingsIds: number[] = [];

    for (let i = 0; i < this.parkings.length; i++) {
      const parking: ParkingOnMap = this.parkings[i];

      // if parking is in current area:
      if (
        this._googleMapsService.getDistanceFromLatLonInKm(
          center,
          parking.earthLocation
        ) < radiusKM
      ) {
        const orderTimeSlot: timeSlot = <timeSlot>{
          start: this._datesService.dateToString(this.getOrder().startTime),
          end: this._datesService.dateToString(this.getOrder().endTime),
        };
        // reload parking if it is without details, or it's time out passed, or order's time slot are not checked:
        if (
          parking.isTimeToReload ||
          // if checked  time slots are not suitable to order time slot:
          !this._datesService.isTimeSlotsInTimesSlotsArrray(
            parking.checkedTimeSlots,
            orderTimeSlot
          )
        ) {
          parkingsIds.push(parking.id);
        } else {
          parkingsNotToReload.push(parking);
        }
      }
    }

    return parkingsIds;
  }

  loadAllParkings(isShowLoading: boolean = true): Observable<Parking[]> {
    this.parkings = null;
    console.log('loadAllParkings');
    const data = forkJoin(
      this.getParkings(<filterParkingBody>{ WithCars: true }, isShowLoading)
    );
    return data.pipe(
      map((data: [Parking[]]) => {
        console.log(data);
        const parkings = data[0];
        this.parkings = parkings.map((p) => new ParkingOnMap(p));
        return this.parkings;
      })
    );
  }

  loadUserRegionPermissions = async () => {
    const body = {
      UserId: this._userService.userPermission.userID,
      Type: 3,
    };
    this.permittedParkings = await this._httpService.post(
      this._urlService.nodeUrl() + 'api/users/permissions',
      body
    );
    this.parkings = this.parkings?.filter((p) => this.permittedParkings.some(p_p => p_p.id === p.id));
  }

  private getCarAvailability(
    carTimeSlots: timeSlot[],
    orderTimeSlot: timeSlot
  ): number {
    switch (
    this._datesService.isTimeSlotsInTimesSlotsArrray(
      carTimeSlots,
      orderTimeSlot
    )
    ) {
      case 1:
        return 3;
      case -1:
        return 2;
      case 0:
        return 1;

      default:
        return 1;
    }
  }
}

<div id="result">
  <title>סיטי קאר - עדכון הזמנה קיימת</title>

  <section id="page-title" class="new_page_title" dir="rtl">
    <!-- #page-title starts -->
    <div class="routerContainer">
      <div class="col-xs-3 text-left new_savtala_ara flush">
        <h3 style="margin-top: 0px">3231</h3>
        <img src="assets/images/new-talasav.png" alt="" />
      </div>
      <div class="col-xs-8 text-right">
        <h2>הזמנה מספר 126425</h2>
        <a id="GoBack" class="linkBack" href="#" style="top: 4px"></a>
      </div>
    </div>
  </section>
  <!-- #page-title ends -->
  <section id="order" class="fullwidth" dir="rtl">
    <!-- #items starts -->
    <div class="routerContainer">
      <ul class="small-list-items new_pickup" style="margin-top: 0">
        <li>
          <div class="orderbox_pickup_input col-xs-4 flush">
            <a type="button" id="type-of-rent" routerLink="/orderType"
              ><img
                style="width: 30px"
                src="assets/images/icon_pensil2.png"
                alt=""
            /></a>
          </div>
          <div class="orderbox_pickup_text col-xs-6 flush">
            <h4>מסלול שעתי</h4>
          </div>
          <div class="orderbox_pickup_image col-xs-2 flush">
            <img
              class="img-responsive"
              src="assets/images/rent_icon2.png"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="orderbox_pickup_input col-xs-4 flush">
            <a type="button" id="car-slider-for-choosing" routerLink="/car"
              ><img
                style="width: 30px"
                src="assets/images/icon_pensil2.png"
                alt=""
            /></a>
          </div>
          <div class="orderbox_pickup_text col-xs-6 flush">
            <h4>קטן, ניסן מיקרה</h4>
          </div>
          <div class="orderbox_pickup_image col-xs-2 flush">
            <img
              class="img-responsive"
              src="assets/images/icon_cartype.png"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="orderbox_pickup_input col-xs-4 flush">
            <a type="button" id="choose-date-to-rent" routerLink="/orderTime"
              ><img
                style="width: 30px"
                src="assets/images/icon_pensil2.png"
                alt=""
            /></a>
          </div>
          <div class="orderbox_pickup_text col-xs-6 flush">
            <h5>
              <span
                >איסוף: 03-01-2018 בשעה 12:18 <br />החזרה: 03-01-2018 בשעה 14:18
                <br />סה”כ 2 שעות
              </span>
            </h5>
          </div>
          <div class="orderbox_pickup_image col-xs-2 flush">
            <img
              class="img-responsive"
              src="assets/images/icon_date.png"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="orderbox_pickup_input col-xs-4 flush">
            <a type="button" id="pickup-location"
              ><img
                style="width: 30px"
                src="assets/images/icon_pensil2.png"
                alt=""
            /></a>
          </div>

          <div class="orderbox_pickup_text col-xs-6 flush">
            <h4>הירש 4, בני ברק</h4>
            <a href="geo:32.0804345,34.833843?z=14" target="_blank"
              ><img
                class="call_icon_img"
                src="assets/images/new-calicon.png"
                alt=""
            /></a>
          </div>

          <div class="orderbox_pickup_image col-xs-2 flush">
            <img
              class="img-responsive"
              src="assets/images/icon_location.png"
              alt=""
            />
          </div>
        </li>

        <li>
          <div class="orderbox_pickup_input col-xs-4 flush">
            <label class="switch">
              <input type="checkbox" class="insuranceTrue" id="checkedINS" />
              <!-- <div class="slider round new"></div> -->
            </label>
          </div>
          <div class="orderbox_pickup_text col-xs-6 flush">
            <h4>ביטול השתתפות עצמית</h4>
          </div>
          <div class="orderbox_pickup_image col-xs-2 flush">
            <a href="#" data-toggle="modal" data-target="#InsuranceDetails"
              ><img
                class="img-responsive"
                src="assets/images/rent_icon10.png"
                alt=""
            /></a>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!-- #items ends -->
  <section class="footer_pickup_area order fullwidth" dir="rtl">
    <div class="routerContainer">
      <div class="top col-xs-12 text-center">
        <p class="bluetext"><b>סה״כ הזמנה</b></p>
        <a type="button" class="btn btn-lg pickup_fivehundred">34.90 ₪</a>
      </div>
      <div class="col-xs-12 text-center">
        <div class="col-xs-6 flush">
          <a
            type="button"
            class="pickup_approval"
            id="modaldisable"
            data-toggle="modal"
            data-target="#myModal3"
            >עדכן</a
          >
        </div>
        <div class="col-xs-6 flush">
          <a
            type="button"
            class="pickup_approval_sav"
            data-toggle="modal"
            data-target="#CancelOrderApprove"
            >ביטול הזמנה</a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- Modal -->

  <!-- Modal1 -->
  <div dir="rtl" id="myModal1" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content text-center">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <div class="modal-body cars">
          <div class="col-lg-12">
            <img
              class="top-img"
              src="assets/images/icon_twin_cars_blue.png"
              alt=""
            />
            <h3><b>ההזמנה שלך אינה כוללת</b></h3>
            <h3><b>השתתפות עצמית</b></h3>
            <hr />
          </div>
          <div class="col-lg-12">
            <span class="bluetext">הוסף רק</span><br />
            <a class="rate">0 ₪</a>
            <p>וסע בראש שקט!</p>
          </div>
        </div>
        <div class="modal-footer text-center">
          <a
            type="button"
            id="AddINS"
            class="yellow-round-btn btn btn-lg"
            data-dismiss="modal"
            >הוסף</a
          ><br /><br />
          <a
            type="button"
            class="yellow-round-btn btn med"
            id="disableINS"
            data-dismiss="modal"
            >המשך ללא השתתפות</a
          >
          <!--<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
  <!-- Modal1 -->

  <!-- Cancel order approve -->
  <div dir="rtl" id="CancelOrderApprove" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content text-center">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <div class="modal-body cars">
          <div class="col-lg-12">
            <img
              class="top-img"
              src="assets/images/icon_sad_smiles.png"
              alt=""
            />
            <h3><b>האם הינך מאשר ביטול הזמנה זו?</b></h3>
            <p>הפעולה כרוכה בעמלת ביטול</p>
            <span class="bottomnote">*עלות ביטול: 17.45 ₪</span>
            <br /><br />
            <p>האם לבטל את ההזמנה?</p>
          </div>
        </div>
        <div class="modal-footer text-center">
          <a class="yellow-round-btn" data-dismiss="modal" href="#">לא</a
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a
            class="yellow-round-btn"
            data-toggle="modal"
            data-target="#ContinueCancelOrder"
            data-dismiss="modal"
            href="#"
            >כן</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Cancel order approve -->

  <!-- Cancel order continue -->
  <form method="post" name="CancelOrder" action="panel.php?CancelOrder=1">
    <div
      dir="rtl"
      id="ContinueCancelOrder"
      style="z-index: 99999"
      class="modal fade"
      role="dialog"
    >
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content text-center">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">×</button>
          </div>
          <div class="modal-body cars">
            <h3><b>ביטול הזמנה מספר - 126425</b></h3>
            <br /><br />
            <div class="col-lg-12">
              <p>בחר סיבת ביטול:</p>
              <select name="cancelReason">
                <option value="0">הנסיעה יוצאת יקר</option>
                <option value="1">התבטל הסידור / מטלה</option>
                <option value="2">אילוץ רפואי</option>
                <option value="3">שכח את השלט בבית</option>
                <option value="4">הרכב לא תקין</option></select
              ><br /><br />
              <p>הערות:</p>
              <textarea
                name="notes"
                rows="4"
                cols="38"
                placeholder="רשום כמה מילים .."
              ></textarea>
              <input type="hidden" name="OrderID" value="126425" /><br /><br />
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="yellow-round-btn">ביטול הזמנה</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Cancel order continue -->

  <!-- Insurance Details -->
  <div dir="rtl" id="InsuranceDetails" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content text-center">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <div class="modal-body cars">
          <div class="col-lg-12">
            <img
              class="top-img"
              src="assets/images/icon_twin_cars_blue.png"
              alt=""
            />
            <h3><b>מהי השתתפות עצמית?</b></h3>
            <br />
            <p>
              השתתפות עצמית מאפשר לך לבטח את הרכב <br />
              במקרה של נזק או תאונה <br />בעלות סמלית המחושבת לפי זמן ומרחק
              הנקבע בפתיחת הזמנה
            </p>
            <br /><br />
          </div>
        </div>
        <div class="modal-footer text-center">
          <span class="bottomnote">לפרטים נוספים חייגו: 2319*</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Insurance Details -->

  <!-- Save -->
  <div dir="rtl" id="myModal3" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content text-center">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <div class="modal-body cars">
          <div class="col-lg-12">
            <h3><b>ההזמנה לא עודכנה!</b></h3>
            <br />
            <h3><b>לא בוצעו שינויים.</b></h3>
          </div>
        </div>
        <div class="modal-footer text-center">
          <a class="yellow-round-btn" data-dismiss="modal" href="#">סגור</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Save -->

  <!-- Error-Cannot change type of rent POPUP-Starts -->
  <div class="modal fade pop_mesage_bg" id="Error-type-of-rent" role="dialog">
    <div
      class="modal-dialog modal-sm wrapr_meassage_main wrapr_meassage_main_full"
      style="top: 200px; z-index: 99999"
    >
      <div
        class="modal-content wrapr_meassage_content wrapr_meassage_content_full"
      >
        <div class="modal_meassage_content">
          <div class="modal_meassage_close">
            <button
              type="button"
              class="close meassage_close"
              data-dismiss="modal"
            >
              ×
            </button>
          </div>
          <div class="modal_meassage_text">
            <h4 class="modal-title pop_message_one" dir="rtl">
              אין אפשרות לשנות מסלול בהזמנת A2B
            </h4>
            <br />
            <a
              type="button"
              class="yellow-round-btn btn sm"
              data-dismiss="modal"
              >סגור</a
            ><br /><br />
            <br />
            <p dir="rtl">
              לבירורים נוספים ניתן לפנות לשירות הלקוחות:
              <a href="tel:*2319" style="color: white">2319*</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Error-Cannot change type of rent POPUP-Ends -->
</div>

<section id="loginpage">
  <!-- #loginpage starts -->

  <div class="table">
    <div style="margin-top: 20%">
      <div class="col-xs-12 text-center">
        <img src="assets/images/logo_citycar.png" alt="" />
      </div>
      <div class="col-xs-12 text-center && gmailHover">
        <app-login></app-login>
      </div>
      <div class="col-xs-12 text-center">
        {{ appGlobals.APP_VERSION }}
      </div>
    </div>
  </div>
</section>

<div
  [ngClass]="{
    appDatePicker_smallFormat: isShowSmallFormat,
    appDatePicker_normalFormat: !isShowSmallFormat
  }"
>
  <!-- <input [disabled]="isDisabled" type="datetime-local" [ngModel]="timeValue | customDate:'yyyy-MM-ddTHH:mm'" (ngModelChange)="timeValue  = $event"
        [value]="timeValue | customDate:'yyyy-MM-ddTHH:mm'" class="data-time-class"> -->

  <input
    style="width: 62%"
    type="date"
    [disabled]="isDisabled"
    [ngModel]="timeValue | customDate: 'yyyy-MM-dd'"
    (ngModelChange)="timeDate = $event"
    [value]="timeValue | customDate: 'yyyy-MM-dd'"
    class="data-time-class"
  />

  <input
    style="width: 36%"
    type="time"
    [disabled]="isDisabled"
    [ngModel]="timeValue | customDate: 'HH:mm'"
    (ngModelChange)="timeTime = $event"
    [value]="timeValue | customDate: 'HH:mm'"
    class="data-time-class"
  />
</div>

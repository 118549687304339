import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import PageTitle from '../../models/page-title';

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.component.html',
  styleUrls: ['./order-update.component.css'],
})
export class OrderUpdateComponent implements OnInit {
  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'ORDER_UPDATE',
      previousUrl: '/menu',
    };
    return PageTitle;
  }

  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReplaySessionService } from '../services/replay-session.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private replaySessionService: ReplaySessionService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleResponse = (request: HttpRequest<any>, response: HttpEvent<any>, event: string) => {
      if (response instanceof HttpResponse) {
        this.replaySessionService.sendEventToReplaySession(event, { request, response });
      }
    }
    return next.handle(request).pipe(
      tap(
        response => handleResponse(request, response, `response ${request.url}`),
        error => handleResponse(request, error, `error ${request.url}`),
      ),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}

import { Period } from '../enums';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { CarInParking } from './car';
import { MapLocation } from './map-location';
import { Parking } from './parking';

export enum OfficeOrderTypeEnum {
  Garage,
  Registretion,
  Fixes,
  Special,
  Pancher,
  Wash,
  Test,
  Fuel,
  CodeUnit,
  A2B,
  BatteryDamping,
  FaultLamp,
  // TODO 12
  Recall = 13,
}

export enum OfficeOrderTypeDescriptionHeEnum {
  'מוסך',
  'הרשמה',
  'תיקונים',
  'שונות',
  'פנצ\'ר',
  'שטיפה',
  'טסט',
  'תדלוק',
  'תחזוקה',
  'קודנית',
  'שינוע',
  'דעיכת מצבר',
  'מנורת תקלה',
}

export class Order {
  period: Period;
  startTime: Date;
  endTime: Date;
  startParking: Parking;
  endParking: Parking;
  car: CarInParking;
  finalPrice: number;
  id: number;
  isActive = true;
  unlockCode: number;
  isInsuranceRequired? = false;
  cancelPrice: number;
  realDatetimeStart: Date;
  realDatetimeEnd: Date;
  lateMinutes: number;
  isCancelled = false;
  lastCarLocation: MapLocation;
  status: number;
  markedAsFinished: Date;
  priceDetails: PriceDetails[];
  orderStatusHebrew: string;
  isIrregularOrder = false;
  notifyNewPrice: boolean;
  isOfficeDrive?: boolean;

  get timeAmount(): number {
    if (this.startTime && this.endTime) {
      const diffInHours: number = Math.ceil(
        Math.abs(this.endTime?.valueOf() - this.startTime?.valueOf()) /
        60 /
        60 /
        1000
      );
      if (this.period === Period.days) return diffInHours / 24;
      return diffInHours;
    }
    return null;
  }

  get periodName(): string {
    if (this.period == Period.hours) return 'שעות';
    if (this.period == Period.days) return 'ימים';
    if (this.period == Period.LongTerm) return 'שעות';
    return null;
  }

  get extraHours(): number {
    return (
      ((this.endTime.getTime() - this.startTime.getTime()) /
        AppGlobals.TIMES.HOURS_IN_TIME) %
      24
    );
  }

  get isEnableSendTravelDetails(): boolean {
    return this.realDatetimeEnd && (this.status == 3 || this.status == 4);
  }

}

export class PriceDetails {
  detailsTypeHebrew: string;
  multiplier: number;
  pricePerUnit: number;
  total: number;
}

export class EmployeeOrder extends Order {
  isOfficeDrive: boolean;
  officeDriveType: OfficeOrderTypeEnum;
  officeDriveReason: string;

  constructor(
    p_isOfficeDrive: boolean,
    p_officeDriveType: OfficeOrderTypeEnum,
    p_order: EmployeeOrder | Order,
  ) {
    super();
    this.isOfficeDrive = p_isOfficeDrive;
    this.officeDriveType = p_officeDriveType;
    Object.assign(this, p_order);
  }
}

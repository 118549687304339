import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { tap, map, switchMap } from 'rxjs/operators';
import { MapLocation } from '../models/map-location';
import { HttpClient } from '@angular/common/http';
declare const google: any;

@Injectable()
export class GoogleMapsService {
  private geocoder: any;

  constructor(
    private _httpClient: HttpClient,
    private mapLoader: MapsAPILoader
  ) { }

  getCurrentPosition(): Observable<MapLocation> {
    return new Observable((observer) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position && position.coords) {
              observer.next(<MapLocation>{
                lat: position.coords.latitude,
                lon: position.coords.longitude,
              });
            } else observer.next(null);
            observer.complete();
          },
          (error) => {
            console.error(error);
            observer.error(null);
            observer.complete();
          }
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  getAddressName(location: MapLocation): Observable<string> {
    return new Observable((observer) => {
      setTimeout(() => {
        this.getAddressNameRec(location, observer);
      }, 0);
    });
  }

  getDistanceFromLatLonInKm(
    location1: MapLocation,
    location2: MapLocation
  ): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(location2.lat - location1.lat); // deg2rad below
    const dLon = this.deg2rad(location2.lon - location1.lon);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(location1.lat)) *
      Math.cos(this.deg2rad(location2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  private getAddressNameRec(location: MapLocation, observer): void {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      const latlng = new google.maps.LatLng(location.lat, location.lon);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results[1]) {
          console.log('results', results);
          observer.next(results[0].formatted_address);
          observer.complete();
        }
      });
    } else {
      console.log('google till is not defineded');
      setTimeout(() => {
        this.getAddressNameRec(location, observer);
      }, 1000);
    }
  }

  private deg2rad(deg): number {
    return deg * (Math.PI / 180);
  }

  getKilometer(originLat, originLon, destLat, destLon) {
    const googleApiMaps =
      'https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=' +
      originLat +
      '+' +
      originLon +
      '&destinations=' +
      destLat +
      '+' +
      destLon +
      '&key=AIzaSyAAR6ZQqp4fLncby3R0mocpQu-yKZVz9Zo';

    return this._httpClient.get(googleApiMaps);
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return of(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  geocodeAddress(location: string): Observable<MapLocation> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable<MapLocation>((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lon: results[0].geometry.location.lng(),
              });
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next(null);
            }
            observer.complete();
          });
        });
      })
    );
  }

  geocodeLatLng(latlng: Location): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable((observer) => {
          this.geocoder.geocode({ location: latlng }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                address: results[0].formatted_address,
                status: status,
              });
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({});
            }
            observer.complete();
          });
        });
      })
    );
  }
}

import { Injectable } from '@angular/core';
import OpenReplay from '@openreplay/tracker';

@Injectable({
  providedIn: 'root',
})
export class ReplaySessionService {
  tracker: OpenReplay;

  sendErrorToReplaySession = (error: Error) => {
    this.tracker.handleError(error);
  };

  sendEventToReplaySession = (key: string, payload: any, issue?: boolean) => {
    this.tracker.event(key, payload);
  };

  sendIssueToReplaySession = (key: string, payload: any) => {
    this.tracker.issue(key, payload);
  };
}

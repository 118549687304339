<app-page-title [pageTitle]="pageTitle"></app-page-title>
<div class="title">
  <h4 class="col-xs-12 text-center" translate>
    {{ 'REPORT_ORDER' | translate }} {{ orderId }}
  </h4>
</div>
<br />

<image-upload
  dir="ltr"
  [style]="customStyle"
  [buttonCaption]="'העלה תמונות'"
  [dropBoxMessage]="'גרור לכאן תמונות'"
  [clearButtonCaption]="'הסר הכל'"
  [extensions]="['jpeg', 'png']"
  (uploadFinished)="onUploadFinished($event)"
  (removed)="onRemoved($event)"
></image-upload>
<div class="imagesDescription">
  <div
    class="col-xs-6 damageDiv"
    *ngFor="let damageReport of damageReports; let i = index"
  >
    <button class="close-btn" (click)="removePicture(i)">
      <i class="fas fa-times"></i>
    </button>
    <div style="margin-top: -23px">
      <div class="imageDiv">
        <img width="100%" class="col-xs-12" [src]="damageReport.image" />
      </div>
      <textarea
        class="col-xs-12"
        style="margin-bottom: 7px"
        [(ngModel)]="damageReport.note"
        [ngClass]="{ 'has-error': isSubmitted && !damageReport.note }"
        type="text"
        placeholder="תאר את הנזק, לדוגמא: 'כנף קדמי ימין'"
        reqired
      ></textarea>
    </div>
  </div>
</div>

<div id="input"></div>

<button
  class="blue-btn-class"
  *ngIf="damageReportsToSend && damageReportsToSend.length"
  (click)="sendDamageReport()"
>
  שלח
</button>

<div class="header1">
  <div class="row customHeader" dir="rtl">
    <div class="col-3 d-flex justify-content-end">
      <button
        (click)="navigateMenu()"
        type="button"
        id="slidebutton"
        class="hamburger is-closed openMenu dropdown"
      >
        <i class="fas fa-home"></i>
      </button>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-center textUser" *ngIf="name">
        <h4 id="helloText" style="float: right" translate class="text-center">
          HELLO <br />
          {{ name }}
          <p>{{ 'CODE' | translate }} {{ identifier }}</p>
        </h4>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-start">
      <button
        (click)="toggleLanguage()"
        type="button"
        class="hamburger is-closed openMenu dropdown"
      >
        {{ anotherLanguage }}
      </button>
    </div>
  </div>
</div>

<div id="result">
  <section id="typesfor-rent" class="fullwidth" dir="rtl">
    <ul class="itemlist col-xs-12">
      <li>
        <div
          class="col-xs-12 row-typeorder"
          [ngClass]="{ 'row-typeorder-normal-format': !isShowSmallFormat }"
        >
          <div class="col-xs-4 col-lg-4">
            <div
              (click)="choosePeriod('hours')"
              [ngClass]="{ selectedPeriod: periodName === 'hours' }"
              class="routerContainer type-order"
            >
              <div class="col-xs-12 text-center">
                <div class="img-this">
                  <img [src]="hourlyImgUrl" class="icon-hour" />
                </div>
              </div>
            </div>

            <h4 class="h4-typeorder" translate>HOURLY</h4>
          </div>

          <div class="col-xs-4 col-lg-4">
            <div
              (click)="choosePeriod('days')"
              [ngClass]="{ selectedPeriod: periodName === 'days' }"
              class="routerContainer type-order"
            >
              <div class="col-xs-12 text-center">
                <div class="img-this">
                  <img [src]="daylyImgUrl" class="icon-hour" />
                </div>
              </div>
            </div>
            <h4 class="h4-typeorder" translate>DAILY</h4>
          </div>

          <div class="col-xs-4 col-lg-4">
            <div
              (click)="choosePeriod('LongTerm')"
              [ngClass]="{ selectedPeriod: periodName === 'LongTerm' }"
              class="routerContainer type-order"
            >
              <div class="col-xs-12 text-center">
                <div class="img-this">
                  <img [src]="longTermImgUrl" class="icon-hour" />
                </div>
              </div>
            </div>

            <h4 class="h4-typeorder" translate>
              LONG
              <!-- <div *ngIf="periodName=='LongTerm'"> -->
              <div>
                <div class="divToDescribe">
                  <small #easySubscribtion class="linkToDescribe" translate
                    ><a>{{ 'EXPL' | translate }}</a></small
                  >
                </div>
                <app-speech-bubble
                  [header]="'אקסטרה 7'"
                  [element]="easySubscribtion"
                  class="foreverSubscribtion"
                >
                  <br />
                  היחודיות במסלול זה הינו המחיר לק"מ.
                  <br />
                  1 ש''ח בלבד כולל הדלק!
                  <br />
                  <a style="font-size: 15px">(לרכב קטן/משפחתי/משפחתי+)</a>
                  <br />
                  <br />

                  לרגל ההשקה
                  <br />
                  ניתנת הטבה נוספת
                  <br />
                  מקבלים את הרכב ל-7 שעות ומשלמים רק על 5.
                  <br />
                  <a style="font-size: 15px">
                    *המבצע לזמן מוגבל, החברה רשאית להפסיק את המבצע בכל עת</a
                  >
                  <br /><br />
                  <p style="font-size: 15px">
                    תכנית "אקסטרה 7" בתוקף
                    <br />
                    ממוצ"ש 12 בלילה
                    <br />
                    ועד חמישי 12 בלילה
                    <br />
                    לא כולל שישי שבת וערבי חג
                  </p>
                  <br />
                  <br />
                  שים לב!!
                  <p style="font-size: 15px">
                    חובה לדווח על סיום הנסיעה והחזרת הרכב לתחנה
                    <br />
                    במוקד 2319* שלוחה 9
                    <br />
                    או באפליקציה כפתור סיום נסיעה.
                    <br />
                    ללא הדיווח - לא תנתן הטבת המחיר בשעות ובקילומטרים.
                  </p>
                </app-speech-bubble>
              </div>
            </h4>
          </div>
        </div>
      </li>
      <!-- <h2 *ngIf="!isShowSmallFormat" class="h2-title" translate>SELECT_TYPE_ORDER</h2> -->
      <li>
        <div
          class="box-time-class"
          [ngClass]="{ routerContainer: !isShowSmallFormat }"
          id="selectTime"
        >
          <div
            dir="rtl"
            [ngClass]="{
              'col-xs-offset-1': !isShowSmallFormat,
              'col-xs-10': !isShowSmallFormat,
              'col-xs-12': isShowSmallFormat
            }"
            class="text-center box-timeorder"
          >
            <h4 class="title-popup" translate>START_TIME</h4>

            <app-date-time-picker
              [(ngModel)]="startTime"
              [isShowSmallFormat]="false"
            ></app-date-time-picker>
            <app-validator-messages
              [validators]="startTimeValidations"
              [value]="startTime"
            ></app-validator-messages>
            <div *ngIf="!isShowSmallFormat">
              <button
                class="chooes-btn"
                [ngClass]="{ 'chooes-btn-selected': isOrderForNow }"
                (click)="toggleOrderForNow()"
                translate
              >
                ORDER_NOW
              </button>
              <button
                class="chooes-btn"
                [ngClass]="{ 'chooes-btn-selected': isOrderForClosedShabbat }"
                (click)="getClosedShabbatOrderTime()"
                translate
              >
                ORDER_SHABAT
              </button>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="routerContainer box-time-class">
          <div
            *ngIf="isShowExtraHours"
            dir="rtl"
            class="col-xs-5 text-center box-timeorder"
          >
            <h4 class="h4-class">
              <b style="font-size: 14px" translate>HOURS_RENTAL</b>
            </h4>
            <b>
              <br />
              <a (click)="addToExtraHours()">
                <i
                  class="fa fa-chevron-circle-up changeTime"
                  aria-hidden="true"
                ></i>
              </a>
              <a (click)="removeFromExtraHours()">
                <i
                  class="fa fa-chevron-circle-down changeTime"
                  aria-hidden="true"
                ></i>
              </a>
            </b>
            <br />
          </div>

          <div
            dir="rtl"
            class="col-xs-10 col-xs-offset-1 text-center box-timeorder"
            [ngClass]="{ 'col-xs-5': isShowExtraHours }"
          >
            <h4 class="h4-class">
              <b style="font-size: 14px" translate> {{ periodHebrewName }}</b>
            </h4>
            <b>
              <br />
              <a (click)="addToTimeAmount()">
                <i
                  class="fa fa-chevron-circle-up changeTime"
                  aria-hidden="true"
                ></i>
              </a>
              <input
                disabled
                [(ngModel)]="timeAmount"
                type="number"
                class="data-time-class"
              />
              <a (click)="removeFromTimeAmount()">
                <i
                  class="fa fa-chevron-circle-down changeTime"
                  aria-hidden="true"
                ></i>
              </a>
            </b>
            <br />
          </div>
        </div>
      </li>
      <li>
        <div
          class="box-time-class"
          [ngClass]="{ routerContainer: !isShowSmallFormat }"
        >
          <div
            dir="rtl"
            [ngClass]="{
              'col-xs-offset-1': !isShowSmallFormat,
              'col-xs-10': !isShowSmallFormat,
              'col-xs-12': isShowSmallFormat
            }"
            class="text-center box-timeorder"
          >
            <h4 class="h4-class">
              <br />
              <b translate>FINISH_TIME</b>
            </h4>
            <b>
              <br />
              <app-date-time-picker
                [(ngModel)]="endTime"
                [isShowSmallFormat]="false"
              ></app-date-time-picker>
            </b>
            <app-validator-messages
              [validators]="endTimeValidations"
              [value]="endTime"
            ></app-validator-messages>
          </div>
        </div>
      </li>
      <li>
        <div [ngClass]="{ routerContainer: !isShowSmallFormat }">
          <div
            [ngClass]="{
              'col-xs-offset-1': !isShowSmallFormat,
              'col-xs-10': !isShowSmallFormat,
              'col-xs-12': isShowSmallFormat
            }"
            class="text-center"
          >
            <button
              class="blue-btn-class"
              id="ContinueButton"
              [disabled]="!isTimesValid"
              (click)="saveDetails()"
              translate
            >
              CONTINUE
            </button>
          </div>
        </div>
      </li>
    </ul>
  </section>
</div>

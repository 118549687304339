<app-modals-header></app-modals-header>

<div class="improveCancellOrderAlert">
  <h4 class="title-popup">הזמנה מספר {{ order.id }}</h4>
  <p>האם אתה בטוח שברצונך לבטל את ההזמנה?</p>
  <p>עלות הביטול:{{ order.cancelPrice }} ש"ח</p>
  <div class="explainDiv">
    עד 2 שעות לפני תחילת ההזמנה עלות הביטול הינה 0 ש"ח
    <br />
    החל משעתיים לפני תחילת ההזמנה עלות הביטול הינה 50% מסך ההזמנה.
  </div>

  <form name="form" (ngSubmit)="f.form.valid && cancellOrder()" #f="ngForm">
    <input
      type="text"
      class="margin-bottom input-icon input-class-blue"
      placeholder="סיבת הביטול"
      name="cancellReason1"
      [(ngModel)]="cancellReason"
      #cancellReason1="ngModel"
      minlength="5"
      required
    />
    <div *ngIf="f.submitted && !cancellReason1.valid" class="help-block">
      לא ניתן לבטל ללא סיבה! מלא לפחות 5 תווים.
    </div>

    <button class="blue-btn-class" type="submit">בטל הזמנה</button>
  </form>
</div>
<app-alert></app-alert>

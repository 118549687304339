import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AlertService } from '../services/alert.service';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { AuthState } from '../enums';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _alertService: AlertService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const ase = localStorage.getItem('AuthState');
    console.log('canActivate ' + ase);
    switch (ase) {
      case '0':
        this._router.navigate(['/home']);
        return false;

      case '1':
        if (
          localStorage.getItem(AppGlobals.LOCAL_STORAGE.AccessTokenType) &&
          localStorage.getItem(AppGlobals.LOCAL_STORAGE.AccessTokenObject)
        ) {
          return true;
        } else {
          localStorage.setItem('AuthState', AuthState.NOT_SET.toString());
          this._router.navigate(['/home']);
          return false;
        }
      case '2': {
        this._router.navigate(['/home']);
        return false;
      }
      default: {
        this._alertService.error('ארעה שגיאה פנימית אנא פנה למוקד לקבלת עזרה');
        this._router.navigate(['/home']);
        return false;
      }
    }
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import { MapLocation } from '../../models/map-location';

@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.css'],
})
export class PlaceAutocompleteComponent implements OnInit, AfterViewInit {
  get insert() {
    const insert =
      this.translate.currentLang == 'he'
        ? 'הזן כתובת לבחירת תחנה'
        : 'Enter station address';
    return insert;
  }

  @Output()
  public selectAddressEvent: EventEmitter<any> = new EventEmitter();

  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;

  public place: google.maps.places.PlaceResult;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private translate: TranslateService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl();
  }

  ngAfterViewInit(): void {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: [],
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          this.place = autocomplete.getPlace();

          // verify result
          if (
            this.place.geometry === undefined ||
            this.place.geometry === null
          ) {
            return;
          }
          this.selectAddress();
        });
      });
    });
  }

  selectAddress() {
    // emit output:
    if (this.place)
      this.selectAddressEvent.emit(<MapLocation>{
        lat: this.place.geometry.location.lat(),
        lon: this.place.geometry.location.lng(),
      });
  }
}

import { Injectable } from '@angular/core';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { Router, RoutesRecognized } from '@angular/router';
import { AppGlobals } from '../shared/app-globals/app-globals';

@Injectable()
export class UrlService {
  public previousUrl: string = null;

  constructor(private router: Router) {
    this.router.events
      .filter((e) => e instanceof RoutesRecognized)
      .pairwise()
      .subscribe((event: any[]) => {
        this.previousUrl = event[0].urlAfterRedirects;
        console.log(this.previousUrl);
      });
  }

  public isDev = false;
  private _baseUrl: string = AppGlobals.BASE_URL;

  setBaseUrl(v: boolean) {
    if (v) {
      this._baseUrl = AppGlobals.BASE_URL;
    } else this._baseUrl = AppGlobals.TEST_URL;
  }
  getBaseUrl() {
    return this._baseUrl;
  }
  getTestUrl() {
    return AppGlobals.TEST_URL;
  }
  getGuestUrl() {
    return AppGlobals.GUEST_URL;
  }

  baseUrl() {
    if (localStorage.getItem(AppGlobals.LOCAL_STORAGE.IS_DEV) == 'true') {
      return AppGlobals.TEST_URL;
    } else {
      return AppGlobals.BASE_URL;
    }
  }

  nodeUrl() {
    return localStorage.getItem(AppGlobals.LOCAL_STORAGE.IS_DEV) == 'true'
      ? AppGlobals.LOCAL_NODE
      : AppGlobals.NODE_SERVER;
  }
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { timeSlot } from '../models/car';
import { newDate } from '../shared/function';

@Injectable()
export class DatesService {
  constructor(private _datepipe: DatePipe) { }

  //gets merged timeslots array, returns 1 if covers all time slot, -1 if covers part, and else 0;
  isTimeSlotsInTimesSlotsArrray(
    timesSlots: timeSlot[],
    timeSlot: timeSlot
  ): number {
    if (!timesSlots) timesSlots = [];

    const startTime: number = newDate(timeSlot.start).getTime();
    const endTime: number = newDate(timeSlot.end).getTime();

    for (let i = 0; i < timesSlots.length; i++) {
      const startUsedTime: number = newDate(timesSlots[i].start).getTime();
      const endUsedTime: number = newDate(timesSlots[i].end).getTime();

      if (startUsedTime <= startTime && endUsedTime >= endTime) return 1;
      if (
        (startUsedTime > startTime && startUsedTime < endTime) ||
        (endUsedTime > startTime && endUsedTime < endTime)
      )
        return -1;
    }
    return 0;
  }

  dateToString(date: Date): string {
    return this._datepipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
  }

  datesToTimeSlot(date1: Date, date2: Date): timeSlot {
    return <timeSlot>{
      start: this.dateToString(date1),
      end: this.dateToString(date2),
    };
  }

  isTimeSlotInTimeSlot(
    checkedTimeSlot: timeSlot,
    inTimeSlot: timeSlot
  ): boolean {
    return (
      newDate(inTimeSlot.start).getTime() <=
      newDate(checkedTimeSlot.start).getTime() &&
      newDate(inTimeSlot.end).getTime() >=
      newDate(checkedTimeSlot.end).getTime()
    );
  }

  addYearsToDate(date: Date, years: number): Date {
    return new Date(date.setFullYear(date.getFullYear() + years));
  }

  roundDateSeconds(date: Date): Date {
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  newDate(string: string): Date {
    const b: Array<any> = string.split(/\D/);
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
  }
}

<app-modals-header></app-modals-header>
<div class="finish_order_modal" *ngIf="order">
  <!-- <button>למעבר להזמנה ולביצוע פעולות</button> -->
  <div class="total_exclude_modal_content">
    <div class="total_exclude_modal_header">
      <div class="total_exclude_head_image">
        <img
          src="assets/images/cars/Nissan-Micra.png"
          alt="ניסן מיקרה"
          style="width: 75%"
        />
      </div>
      <!-- <h4 class="total_modal_title">איזה כיף שאתה נוסע איתנו!</h4> -->
      <h4 class="total_modal_title" translate>SUCCESSFULLY</h4>
      <h5 class="total_modal_subtitle">
        {{ 'ORDER_NUM' | translate }} {{ order.id }}
      </h5>
    </div>
    <div class="total_exclude_modal_body">
      <div class="exclude_modal_body_wrapper" dir="ltr">
        <div class="exclude_modal_body_top">
          <div class="exclud_without_top_right">
            <h5>
              {{ order.startParking.name }}
              <!-- <span>A</span> -->
            </h5>
          </div>
          <!-- <div class="exclud_top_left">
            <a href="geo:32.0463708,34.9590766?z=14" target="_blank">
              <img src="assets/images/new-calicon.png" alt="">
            </a>
          </div> -->
        </div>
        <!-- <div class="exclude_modal_body_top">
          <div class="exclud_without_top_right">
            <h5>
             
              <span>B</span>
            </h5>
          </div>
          <div class="exclud_top_left">
            <a href="geo:32.0463708,34.9590766?z=14" target="_blank">
              <img src="assets/images/new-calicon.png" alt="">
            </a>
          </div>
        </div> -->
        <div class="exclude_modal_body_botom">
          <div class="exclude_midle_left">
            <img src="assets/images/icon_clock_bluel.png" alt="" />
            <h2 translate>FINISH</h2>
            <h5>{{ order.endTime | customDate: 'dd/MM/yyyy' }}</h5>
            <p>{{ order.endTime | customDate: 'HH:mm' }}</p>
            <!-- <p>ט"ו שבט התשע"ח</p> -->
          </div>
          <div class="exclude_midle_right">
            <img src="assets/images/icon_clock_bluer.png" alt="" />
            <h2 translate>START</h2>
            <h5>{{ order.startTime | customDate: 'dd/MM/yyyy' }}</h5>
            <p>{{ order.startTime | customDate: 'HH:mm' }}</p>
            <!-- <p>ט"ו שבט התשע"ח</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="total_exclude_modal_footer">
      <h4 style="direction: rtl">
        {{ order.timeAmount }} {{ order.periodName }}
      </h4>
      <h5>₪ {{ order.finalPrice }}</h5>

      <!-- <div class="toexclmodal_foot_btn">
        <a >
          <img src="assets/images/new-talasav.png" alt="">
          <h4>{{order.unlockCode}}</h4>
        </a>
      </div> -->

      <div class="toexclmodal_foot_btn">
        <a>
          <h4 class="blink">
            {{ 'END_ORDER_REPORT' | translate }}
            <br />
            {{ 'END_ORDER_REPORT2' | translate }}
          </h4>
          <li>
            {{ 'END_ORDER_REPORT3' | translate }}
          </li>
          <li>
            {{ 'END_ORDER_REPORT4' | translate }}
          </li>
        </a>
      </div>
    </div>
  </div>
</div>

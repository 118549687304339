import { DriverLicenseLevelEnum, GearTypeEnum, VisaTypeEnum } from '../enums';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { CreditCartDetails } from './credit-cart-details';
export class UserBody {
  firstNameHeb: string;
  lastNameHeb: string;
  nameInInvoice: string;
  addressStreetName: string;
  addressCityHebName: string;
  addressStreetNumber: number;
  addressCityId: number;
  addressNeighborhood: string;
  country: string;

  email: string;
  homePhone: number;
  cellPhone1: number;
  cellPhone2: number;

  birthday: Date;
  passportId: number;
  tz: number;

  isWantInsurance: boolean;
  routeTypeEnum: number;

  driverLicenseExpDate: Date;
  driverLicenseAddress: string;
  driverLicenseGearType: number;
  driverLicenseIssueDate: Date;
  driverLicenseLevel: number;
  driverLicenseNumber: number;

  last4Digits: string;
  couponCode: string;
  identifier: number;

  isTz: boolean;
  subscriptionStatus: number;
  constructor() {}

  public static custToUserBody(user: User): UserBody {
    return <UserBody>{
      firstNameHeb: user.firstName,
      lastNameHeb: user.lastName,
      nameInInvoice: user.nameInInvoice,
      addressStreetName: user.street,
      addressCityHebName: user.city,

      driverLicenseExpDate: user.driverLicenseExpDate,
      last4Digits: user.creditLast4Digits,
    };
  }
}

export class User {
  firstName: string;
  lastName: string;
  nameInInvoice: string;
  street: string;
  streetNumber: number;
  neighborhood: string;
  cityId: number;
  city: string;
  country: string;

  email: string;
  homePhone: number;
  cellPhone1: number;
  cellPhone2: number;

  birthday: Date;
  passportId: number;
  tz: number;

  isWantInsurance: boolean;
  routeTypeEnum: number;

  driverLicenseExpDate: Date;
  driverLicenseAddress: string;
  driverLicenseGearType: number;
  driverLicenseIssueDate: Date;
  driverLicenseLevel: number;
  driverLicenseNumber: number;

  creditLast4Digits: string;
  creditCardDetails: CreditCartDetails;

  identifier: number;

  isTz: boolean;
  subscriptionStatus: number;
  constructor() {}

  public static custToUserModel(userBody: UserBody): User {
    return <User>{
      firstName: userBody.firstNameHeb,
      lastName: userBody.lastNameHeb,
      nameInInvoice: userBody.nameInInvoice,
      street: userBody.addressStreetName,
      streetNumber: userBody.addressStreetNumber,
      city: userBody.addressCityHebName,
      cityId: userBody.addressCityId,
      neighborhood: userBody.addressNeighborhood,
      country: userBody.country,

      email: userBody.email,
      homePhone: userBody.homePhone,
      cellPhone1: userBody.cellPhone1,
      cellPhone2: userBody.cellPhone2,

      birthday: userBody.birthday,
      passportId: userBody.passportId,
      tz: userBody.tz,

      isWantInsurance: userBody.isWantInsurance,
      routeTypeEnum: userBody.routeTypeEnum,

      driverLicenseExpDate: userBody.driverLicenseExpDate,
      driverLicenseAddress: userBody.driverLicenseAddress,
      driverLicenseGearType: userBody.driverLicenseGearType,
      driverLicenseIssueDate: userBody.driverLicenseIssueDate,
      driverLicenseLevel: userBody.driverLicenseLevel,
      driverLicenseNumber: userBody.driverLicenseNumber,

      creditLast4Digits: userBody.last4Digits,
      identifier: userBody.identifier,
      isTz: userBody.isTz,
      subscriptionStatus: userBody.subscriptionStatus,
    };
  }
}

abstract class RegisteringUser {
  FirstName: string;
  LastName: string;
  Email: string;
  AddressStreetName: string;
  AddressStreetNumber: number;
  CityID: number;
  NeighborhoodName: string;
  IDOrPassport: number;
  CountryName: string;
  couponCode: string;

  AddressAbroad: string;
  CityAbroad: string;
  StreetAbroad: string;
  NumberAbroad: string;
  VisaType: VisaTypeEnum;

  updateSource: number;

  Phone1: number | string;
  Phone2: number;
  HomePhone: number;

  isWantInsurance: boolean;

  subscriptionType: number;

  Notes: string;

  Credit: Credit = new Credit();

  Photos: Photos = new Photos();

  DrivingLicense: DrivingLicense = new DrivingLicense();
  createSource = 3;
  creditLast4Digits: string;
}

export class RegisteringUserBody extends RegisteringUser {
  DateOfBirth: string;
  updateSource: number;
}

export class RegisteringUserObject extends RegisteringUser {
  // private _DateOfBirth: Date;
  // public get DateOfBirth(): Date {
  //     return this._DateOfBirth;
  // }
  // public set DateOfBirth(v: Date) {
  //     if (!isNaN(new Date(v).valueOf()))
  //         this._DateOfBirth = v;
  // }
  DateOfBirth: any;
  updateSource: number;
  constructor() {
    super();
  }
}

class Photos {
  LicenseFront: string;
  LicenseRear: string;
  PictureOfHimselfHoldingLicense: string;
  Passport: string;
  IsraelBorderPermit: string;
  Signiture: string;
}

class Credit {
  NewCreditCardNumber: string;
  //NewCreditCardValidThrough: Date;

  private _NewCreditCardValidThrough: Date;
  ForeignCreditCardHolder: boolean;
  CreditCardHolderSignature: any;
  public get NewCreditCardValidThrough(): Date {
    return this._NewCreditCardValidThrough;
  }
  public set NewCreditCardValidThrough(v: Date) {
    this._NewCreditCardValidThrough = v;
  }

  NewCreditCardCVV: string;
  NewCreditCardHolderName: string;
  FourDigits: string;
  NewCreditCardHolderID: string;
}

class DrivingLicense {
  Address: string;
  ExpirationDate: string;
  GearType: GearTypeEnum;
  IssueDate: string;
  Level: DriverLicenseLevelEnum;
  Number: number;
}

export class UsersPreference {
  clientId: number;
  parkings: number[];
}

export class UserPermission {
  email: string;
  innerID: number;
  permissions: string[];
  userID: number;
  userIID: number;
  name: string;
}

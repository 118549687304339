import {
  Component,
  OnInit,
  forwardRef,
  Inject,
  ElementRef,
  Renderer2,
} from '@angular/core';

import { DialogRef } from 'ngx-modialog-7';
import { Order } from '../../models/order';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import {
  BaseModalComponent,
  OrderModalContext,
} from '../base-modal/base-modal.component';
@Component({
  selector: 'app-finish-order-details',
  templateUrl: './finish-order-details.component.html',
  styleUrls: ['./finish-order-details.component.css'],
})
export class FinishOrderDetailsComponent
  extends BaseModalComponent<OrderModalContext>
  implements OnInit
{
  context: OrderModalContext;

  order: Order;

  constructor(
    public dialog: DialogRef<OrderModalContext>,
    elementRef: ElementRef,
    renderer: Renderer2,
    @Inject(forwardRef(() => OrderService))
    private _router: Router
  ) {
    super(dialog, elementRef, renderer);
    this.context = dialog.context;
    this.order = dialog.context.order;
    this.afterClose = dialog.context.afterCloseFn;
  }

  afterClose(): void {
    console.log('after close');
    setTimeout(() => {
      this._router.navigate(['/menu']);
    }, 1000);
  }

  beforeDismiss?(): boolean | Promise<boolean> {
    console.log('before dismiss');
    return true;
  }

  beforeClose?(): boolean | Promise<boolean> {
    console.log('before close');
    return true;
  }

  ngOnInit() {
    this.initModal();
  }
}

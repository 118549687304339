export class Uploads {
  driverLicenseFront: string;
  driverLicenseBack: string;
  driverLicenseSelfie: string;
  visa: string;
  passport: string;
  expirationDate: Date;

  firstName: string;
  lastName: string;
  id: number;

  dateAction: string;
  comment: string;
  status: number;
}

<div id="result">
  <title>סיטי קאר - סיכום הזמנה 126420</title>
  <section id="page-title" dir="rtl">
    <a id="top"></a>
    <!-- #page-title starts -->
    <div class="routerContainer">
      <div class="col-xs-10 text-center">
        <h2>סיכום הזמנה מס' 126420</h2>
        <a id="GoBack" class="linkBack" href="#"></a>
      </div>
    </div>
  </section>
  <!-- #page-title ends -->
  <section id="survey" class="fullwidth" dir="rtl">
    <!-- #items starts -->
    <div class="routerContainer">
      <div class="daihatsu-survey col-xs-12">
        <div class="col-xs-7 flush">
          <h2>
            ניסן מיקרה <img src="assets/images/cars/model/Nissan.png" alt="" />
          </h2>
          <p>סוג השכרה: שעתי</p>
          <p>סוג רכב: קטן</p>
          <p>קוד גישה לרכב: 2432</p>
          <p>&nbsp;</p>
          <p>
            <a
              type="button"
              id="EditOrder"
              class="yellow-round-btn btn sm"
              style="padding: 0px 20px"
              routerLink="/orderUpdate"
              data-dismiss="modal"
              >ערוך הזמנה</a
            >
          </p>
        </div>
        <div class="col-xs-5">
          <img
            class="img-responsive"
            src="assets/images/cars/Nissan-Micra.png"
            alt="ניסן מיקרה"
          />
        </div>
      </div>
      <div class="top_btn_wrapr fix">
        <div class="col-xs-6">
          <a href="#" class="new_btn color_beguni">פרטי הזמנה</a>
        </div>
        <div class="col-xs-6">
          <a href="#" class="new_btn color_grey">פרטי נסיעה</a>
        </div>
      </div>
      <div class="twocol">
        <!-- .twocol starts -->
        <div class="whitebox col-xs-6" style="background-color: #ebebeb">
          <p class="bluetext">בני ברק- סורוצקין 5</p>
          <span class="graytext">18:21 | 02-01-2018</span>
          <p class="bluetext">בני ברק- סורוצקין 5</p>
          <span class="graytext">20:21 | 02-01-2018</span>
        </div>
        <div class="whitebox col-xs-6">
          <p class="bluetext">בני ברק- סורוצקין 5</p>
          <span class="graytext">18:21 | 02-01-2018</span>
          <p class="bluetext">בני ברק- סורוצקין 5</p>
          <span class="graytext">20:21 | 02-01-2018</span>
        </div>
      </div>
      <!-- .twocol ends -->
      <ul class="small-list-items new">
        <li>
          <div class="col-xs-4 text-left servey-list-align">
            <p class="bluetext new_bold"><b>10 ₪</b></p>
          </div>
          <div class="col-xs-8">
            <p class="graytext"></p>
            <div class="grey_new_img">
              <img src="assets/images/order-summer-icon3.png" alt="" />
            </div>
            <div class="grey_new_text">ביטוח שעתי</div>
            <p></p>
          </div>
        </li>
        <li>
          <div class="col-xs-4 text-left servey-list-align">
            <p class="bluetext new_bold"><b>19.9 ₪</b></p>
          </div>
          <div class="col-xs-8">
            <p class="graytext"></p>
            <div class="grey_new_img">
              <img src="assets/images/order-summer-icon2.png" alt="" />
            </div>
            <div class="grey_new_text">1.00שעות</div>
            <p></p>
          </div>
        </li>
        <li>
          <div class="col-xs-4 text-left servey-list-align">
            <p class="bluetext"><b>9.00 ₪</b></p>
          </div>
          <div class="col-xs-8">
            <p class="graytext">משעה שניה 9</p>
          </div>
        </li>

        <li>
          <div class="col-xs-4 text-left servey-list-align">
            <h2 class="bluetext new_bold"><b>38.90 ₪</b></h2>
          </div>
          <!--<div class="col-xs-8">
            <h2 class="bluetext new_bold">סה"כ</h2>
          </div>-->
        </li>

        <li>
          <div class="col-xs-7 text-left servey-list-align">
            <p class="bluetext new_bold" dir="ltr">**** - 6789</p>
          </div>
          <div class="col-xs-5">
            <p class="bluetext new_bold">
              <img src="assets/images/icon_credit.png" alt="" />
              <b>אמצעי תשלום</b>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!-- #items ends -->
  <script type="text/javascript">
    $(document).ready(function () {
      $('#GoBack').click(function () {
        $('#result').load('booking-details.php');
      });
      $('#EditOrder').click(function () {
        $('#result').load('UpdateOrder/update-order.php?orderid=126420');
      });
    });
  </script>
</div>

import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements OnInit {
  isLoading = false;

  constructor(
    @Inject(forwardRef(() => LoadingService))
    private _loadingService: LoadingService
  ) {}

  ngOnInit() {
    this._loadingService.getLoading().subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }
}

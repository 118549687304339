import { Pipe, PipeTransform, Inject, forwardRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DatesService } from '../services/dates.service';
import { GlobalService } from '../services/global.service';

import { timeSlot, CarInParking } from '../models/car';
import { Order } from '../models/order';
import { Parking } from '../models/parking';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { newDate } from '../shared/function';

export class carTimeSlots {
  carId: number;
  atParking: timeSlot;
  used: timeSlot[];
}

export class parkingTimeSlots {
  parkingId: number;
  carTimeSlots: carTimeSlots[];
}

@Pipe({
  name: 'TimeSlots',
})
export class TimeSlotsPipe implements PipeTransform {
  currentOrderTimeSlot: timeSlot;

  getOrder: () => Order;

  constructor(
    private _datepipe: DatePipe,

    @Inject(forwardRef(() => DatesService))
    private _datesService: DatesService,

    private _globalService: GlobalService
  ) { }

  transform(value: any, ...args: any[]) {
    throw new Error('Method not implemented.');
  }

  registerFromOrderService(getOrder: () => Order) {
    this.getOrder = getOrder;
  }

  insertTimeSlotsInParkings(
    parkings: Parking[],
    parkingsTimeSlots: any,
    startOrderTime: Date,
    endOrderTime: Date
  ): Parking[] {
    if (!parkings) return [];
    const timeSlotsObject = parkingsTimeSlots.parkings;

    parkings.forEach((parking: Parking) => {
      parking.carsDetails.forEach((car: CarInParking) => {
        //check if the server returned good object, with time slot for every parking and car:
        if (
          timeSlotsObject &&
          timeSlotsObject[parking.id] &&
          timeSlotsObject[parking.id].cars &&
          timeSlotsObject[parking.id].cars[car.carNumber]
        ) {
          const carTimeSlots: carTimeSlots[] =
            timeSlotsObject[parking.id].cars[car.carNumber];

          car.carTimeSlots = carTimeSlots;

          //car.timeSlots
          car = this.updateCarAvailability(car, startOrderTime, endOrderTime);

          // if (car.carTimeSlots)
          //   car.carTimeSlots = car.carTimeSlots.concat(carTimeSlots);
          // else
          //   car.carTimeSlots = carTimeSlots;
        } else {
          car.availability = 1;
          car.timeSlots = [];
        }
      });
    });

    return parkings;
  }

  updateCarAvailability(
    car: CarInParking,
    startOrderTime: Date,
    endOrderTime: Date
  ): CarInParking {
    car = this.getNotAvailableTimeSlots(car, startOrderTime, endOrderTime);
    car.availability = !car.timeSlots ? 3 : car.timeSlots.length ? 2 : 1;

    //if car is not available for this order, all order time slot is in catched time slot:
    //if (car.availability == 3)
    //  car.timeSlots = [this.createTimeSlot(startOrderTime, endOrderTime)];
    return car;
  }

  private getNotAvailableTimeSlots(
    car: CarInParking,
    startOrderTime: Date,
    endOrderTime: Date
  ): CarInParking {
    const carTimeSlots: carTimeSlots[] = car.carTimeSlots;
    //car.spacesInTimeSlots = [];

    if (!carTimeSlots) {
      car.timeSlots = [];
      return car;
    }

    let isTimeSlotsInRange = false;
    let timeSlots: timeSlot[] = [];
    let lastEndTimeSlot: Date = startOrderTime;

    for (let i = 0; i < carTimeSlots.length; i++) {
      const carTimeSlot: carTimeSlots = carTimeSlots[i];

      const startParkingTime: Date = this._datesService.roundDateSeconds(
        newDate(carTimeSlot.atParking.start.replace('Z', ''))
      );
      const endParkingTime: Date = this._datesService.roundDateSeconds(
        newDate(carTimeSlot.atParking.end.replace('Z', ''))
      );

      //check if parking's time slot is in the order's range          //if the list is ordered
      if (
        !(endParkingTime.getTime() <= startOrderTime.getTime()) &&
        !(startParkingTime.getTime() >= endOrderTime.getTime())
      ) {
        isTimeSlotsInRange = true;

        //cases of time slots of not available time in the order time range:
        if (startParkingTime.getTime() > startOrderTime.getTime())
          timeSlots.push(
            this.createTimeSlot(lastEndTimeSlot, startParkingTime)
          );
        lastEndTimeSlot = endParkingTime;

        //add 10 minutes before and after every order:
        carTimeSlot.used.forEach((timeSlot) => {
          timeSlot = this.add10MinutesToTimeSlot(
            timeSlot,
            car.spacesInTimeSlots
          );
        });

        timeSlots = carTimeSlot.used;

        //time slot is null if all order's time slot is catched:
        if (!timeSlots) {
          car.timeSlots = null;
          return car;
        }
      }

      lastEndTimeSlot = endParkingTime;
    }

    if (lastEndTimeSlot.getTime() < endOrderTime.getTime())
      timeSlots.push(this.createTimeSlot(lastEndTimeSlot, endOrderTime));

    if (!isTimeSlotsInRange) car.timeSlots = null;
    else car.timeSlots = timeSlots;
    return car;
  }

  //not implemented,
  //merge array of time slots, contact closed time slots, delete duplicate cont...
  mergeTimeSlotsArray(timeSlots: timeSlot[]): timeSlot[] {
    if (!timeSlots) return null;
    function merge(ranges) {
      const result = [];
      let last;

      ranges.forEach(function (r) {
        if (r.start != r.end) {
          if (!last || newDate(r.start).getTime() > newDate(last.end).getTime())
            result.push((last = r));
          else if (newDate(r.end).getTime() > newDate(last.end).getTime())
            last.end = r.end;
        }
      });

      return result;
    }

    timeSlots.sort(function (a, b) {
      return (
        newDate(a.start).getTime() - newDate(b.start).getTime() ||
        newDate(a.end).getTime() - newDate(b.end).getTime()
      );
    });

    return merge(timeSlots);
  }

  // gets merged and ordered time slot array, and time slot to cut from it
  cutTimeSlotFromTimeSlotsArray(timeSlots: timeSlot[], timeSlot): timeSlot[] {
    // not checks if the st

    const newTimeSlots: timeSlot[] = [];
    let cuttedIndex = -1;

    for (let i = 0; i < timeSlots.length; i++) {
      const cuttedTimeSlot: timeSlot = timeSlots[i];

      // if the time slot completely covers time slot in the array:
      if (this._datesService.isTimeSlotInTimeSlot(cuttedTimeSlot, timeSlot)) {
        cuttedIndex = i;
        break;
      }

      // if the time slot is in part of time slot in the array, save the exists time slot:
      if (
        newDate(timeSlot.start).getTime() >=
        newDate(cuttedTimeSlot.start).getTime() &&
        newDate(timeSlot.start).getTime() <
        newDate(cuttedTimeSlot.end).getTime()
      ) {
        newTimeSlots.push(
          this.createTimeSlot(
            newDate(cuttedTimeSlot.start),
            newDate(timeSlot.start)
          )
        );
        cuttedIndex = i;
      }

      if (
        newDate(timeSlot.end).getTime() >
        newDate(cuttedTimeSlot.start).getTime() &&
        newDate(timeSlot.end).getTime() <= newDate(cuttedTimeSlot.end).getTime()
      ) {
        newTimeSlots.push(
          this.createTimeSlot(
            newDate(timeSlot.end),
            newDate(cuttedTimeSlot.end)
          )
        );
        cuttedIndex = i;
      }
    }

    if (cuttedIndex != -1) timeSlots.splice(cuttedIndex, 1);
    if (newTimeSlots.length) timeSlots = timeSlots.concat(newTimeSlots);

    return timeSlots;
  }

  concatTimeSlotsOfLessThenHourBetween(
    timeSlots: timeSlot[],
    startOrderTime: Date,
    endOrderTime: Date
  ): timeSlot[] {
    if (!timeSlots) return null;

    const newTimeSlots: timeSlot[] = [];
    let previousEndTime: number;
    for (let i = 0; i < timeSlots.length; i++) {
      let timeSlotToAdd: timeSlot;
      const currentStartTime = newDate(timeSlots[i].start).getTime();

      //if the space between this time slot from last time slot is less the hour:
      if (
        previousEndTime &&
        currentStartTime - previousEndTime < AppGlobals.TIMES.HOURS_IN_TIME
      ) {
        timeSlotToAdd = <timeSlot>{
          start: newTimeSlots.pop().start,
          end: timeSlots[i].end,
        };

        //if new time slot covers order time slot:
        if (
          newDate(timeSlotToAdd.start).getTime() <= startOrderTime.getTime() &&
          newDate(timeSlotToAdd.end).getTime() >= endOrderTime.getTime()
        )
          return null;
      } else timeSlotToAdd = this._globalService.copyObject(timeSlots[i]);

      newTimeSlots.push(timeSlotToAdd);
      previousEndTime = newDate(timeSlots[i].end).getTime();
    }
    return newTimeSlots;
  }

  createTimeSlot(startTime: Date, endTime: Date): timeSlot {
    return <timeSlot>{
      start: this._datepipe.transform(startTime, 'yyyy-MM-ddTHH:mm:ss'),
      end: this._datepipe.transform(endTime, 'yyyy-MM-ddTHH:mm:ss'),
    };
  }

  private isUsedTimeOfCurrentOrder(usedTimeSlot: timeSlot): boolean {
    if (!usedTimeSlot) return false;
    return (
      this._datesService
        .roundDateSeconds(newDate(usedTimeSlot.start))
        .getTime() ==
      this._datesService
        .roundDateSeconds(newDate(this.currentOrderTimeSlot.start))
        .getTime() &&
      this._datesService
        .roundDateSeconds(newDate(usedTimeSlot.end))
        .getTime() ==
      this._datesService
        .roundDateSeconds(newDate(this.currentOrderTimeSlot.end))
        .getTime()
    );
  }

  add10MinutesToTimeSlot(
    timeSlot: timeSlot,
    spacesTimeSlots?: timeSlot[]
  ): timeSlot {
    const spaceFromStart: timeSlot = <timeSlot>{
      end: timeSlot.start,
    },
      spaceFromEnd: timeSlot = <timeSlot>{
        start: timeSlot.end,
      };

    const startDate: Date = this._datesService.roundDateSeconds(
      newDate(timeSlot.start.replace('Z', ''))
    );
    timeSlot.start = this._datesService.dateToString(
      new Date(startDate.getTime() - 10 * 60000)
    );

    const endDate: Date = this._datesService.roundDateSeconds(
      newDate(timeSlot.end.replace('Z', ''))
    );
    timeSlot.end = this._datesService.dateToString(
      new Date(endDate.getTime() + 10 * 60000)
    );

    spaceFromStart.start = timeSlot.start;
    spaceFromEnd.end = timeSlot.end;

    if (spacesTimeSlots) {
      spacesTimeSlots.push(spaceFromStart);
      spacesTimeSlots.push(spaceFromEnd);
    }

    return timeSlot;
  }

  orderTimeSlotsArray(timeSlots: timeSlot[]): timeSlot[] {
    if (!timeSlots) return null;

    timeSlots = timeSlots.sort(function (t1: timeSlot, t2: timeSlot) {
      if (newDate(t1.start) > newDate(t2.start)) return 1;
      if (newDate(t1.start) < newDate(t2.start)) return -1;
      return 0;
    });

    return timeSlots;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Validator } from '../classes/index';
import { ValidatorsList } from '../classes/validators-list';

@Pipe({
  name: 'validatorsList',
})
export class ValidatorsListPipe implements PipeTransform {
  transform(value: ValidatorsList, args?: any): any {
    return value.validators
      .filter((v: Validator) => v.validator(args || value.value))
      .map((v: Validator) => v.errorMessage);
  }
}

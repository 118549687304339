import { Validator } from './';

export class ValidatorsList {
  validators: Validator[];
  value: any;

  isValid(value: any = this.value): boolean {
    if (!this.validators || !this.validators.length) return true;

    return !this.validators.find((v) => v.validator(value));
  }

  constructor(validators: Validator[]) {
    this.validators = validators;
  }
}

<app-page-title [pageTitle]="pageTitle"></app-page-title>

<div>
  <div>
    <div id="result">

      <section id="items" *ngIf="selectedTab === null">
        <li>
          <button class="logoutBtn edit-btn" (click)="logout()">
            <i class="fas fa-sign-out-alt" translate></i>
            {{ 'LOGOUT' | translate }}
          </button>
          <!-- #items starts -->

          <div class="routerContainer" style="height: -webkit-fill-available">
            <ul class="itemlist">
              <li>
                <div
                  class="col-xs-4 text-center"
                  [ngClass]="{ 'active-tab': selectedTab === 0 }"
                  (click)="userDetails()"
                >
                  <a style="text-decoration: none" id="booking-details">
                    <img src="assets/images/rmp-userIcon.png" alt="" />
                    <h4 translate>USER_DETAILS</h4>
                  </a>
                </div>

                <div
                  class="col-xs-4 text-center"
                  [ngClass]="{ 'active-tab': selectedTab === 1 }"
                  (click)="driverLicense()"
                >
                  <a style="text-decoration: none" id="booking-details">
                    <img src="assets/images/rmp-driver.png" alt="" />
                    <h4 translate>UPDATE_LICENSE</h4>
                  </a>
                </div>

                <div class="col-xs-4 text-center" (click)="update()">
                  <a style="text-decoration: none" id="booking-details">
                    <img src="assets/images/rmp-credit.png" alt="" />
                    <h4 translate>UPDATE_CREDIT</h4>
                  </a>
                </div>
              </li>
              <li>

                <div class="col-xs-4 text-center" [routerLink]="['/status']">
                  <a style="text-decoration: none" id="internal-damage-report">
                    <img src="assets/images/cash_icon.png" alt="" />
                    <h4 translate>PAYMENTS</h4>
                  </a>
                </div>

                <div class="col-xs-4 text-center" [routerLink]="['/orderList']">
                  <a style="text-decoration: none" id="booking-details">
                    <img src="assets/images/rmp-icon1.png" alt="" />
                    <h4 translate>ALL_ORDERS_DETAILS</h4>
                  </a>
                </div>

                <div
                  class="col-xs-4 text-center"
                  [ngClass]="{ 'active-tab': selectedTab === 2 }"
                  (click)="mail()"
                >
                  <a style="text-decoration: none" id="booking-details">
                    <img src="assets/images/rmp-mail.png" alt="" />
                    <h4 translate>MAIL</h4>
                  </a>
                </div>
              </li>

              <li style="margin-right: -16%">
                <div class="col-xs-4 text-center" (click)="damageReport()">
                  <a style="text-decoration: none" id="internal-damage-report">
                    <img src="assets/images/rmp-icon4.png" alt="" />
                    <h4 translate>DAMAGE_REPORT</h4>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </section>
    </div>

    <section class="fullwidth" dir="rtl">
      <div class="text-center" *ngIf="selectedTab !== null">
        <button
          class="btn-purple"
          style="width: -webkit-fill-available"
          (click)="selectedTab = null; pageTitle.title = 'PERSONAL_AREA'"
        >
          <i class="fas fa-sign-out-alt" translate></i>
          {{ 'BACK_PERSONAL_AREA' | translate }}
        </button>
      </div>

      <div style="margin-top: 3.5px">
        <ng-template [ngIf]="selectedTab === 0">
          <div>
            <form
              class="form-details"
              *ngIf="user"
              name="form"
              (ngSubmit)="f.form.valid && updateUserDetails()"
              #f="ngForm"
              novalidate
            >
              <div class="col-xs-12">
                <label class="col-xs-10 col-xs-offset-1 label-c" translate
                  >NAME</label
                >
                <div class="col-xs-10 col-xs-offset-1">
                  <div class="input-c not-editable">
                    {{ user.firstName + ' ' + user.lastName }}
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-10 col-xs-offset-1 label-c" translate>
                  NAME_INVOICE
                </label>
                <div class="col-xs-10 col-xs-offset-1">
                  <input
                    type="text"
                    class="form-control-plaintext input-c"
                    [(ngModel)]="user.nameInInvoice"
                    name="nameInInvoice"
                    #nameInInvoice="ngModel"
                  />
                </div>
              </div>

              <div class="col-xs-12">
                <label class="col-xs-5 col-xs-offset-1 label-c" translate
                  >STREET
                </label>
                <label class="col-xs-5 label-c" translate>CITY </label>
                <div class="col-xs-5 col-xs-offset-1">
                  <input
                    type="text"
                    class="form-control-plaintext input-c"
                    [(ngModel)]="user.street"
                    name="street"
                    #street="ngModel"
                  />
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    class="form-control-plaintext input-c"
                    [(ngModel)]="user.city"
                    name="city"
                    #city="ngModel"
                  />
                </div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-10 col-xs-offset-1 label-c" translate
                  >CREDIT
                </label>

                <div class="col-xs-10 col-xs-offset-1">
                  <div class="col-xs-12 no-padding" style="">
                    <div class="input-c not-editable">
                      {{ user.creditLast4Digits }}- ****-****-****
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-10 col-xs-offset-1 label-c" translate
                  >VALIDITY_DRIVING_LICENSE
                </label>
                <div class="col-xs-10 col-xs-offset-1">
                  <div class="input-c not-editable">
                    {{ driverLicenseExpDate }}
                  </div>
                </div>
              </div>
              <div class="col-xs-10 col-xs-offset-1" style="margin-top: 25px">
                <button class="blue-btn-class" translate>UPDATE_CHANGES</button>
              </div>
            </form>
          </div>
        </ng-template>
        <ng-template [ngIf]="selectedTab === 1">
          <!--
           <br>
           <div style="text-align: center;">
            <button class=" modal-close-btn" (click)="update()" translate>{{'UPDATE_CREDIT'|translate}}</button>
          </div> -->
          <form>
            <div>
              <div class="col-xs-12">
                <h1></h1>
                <label class="col-xs-10 col-xs-offset-1 label-c" translate
                  >VALIDITY_DRIVING_LICENSE</label
                >
                <app-month-input
                  [minYear]="DateNow"
                  [(ngModel)]="uploads.expirationDate"
                  name="uploads.expirationDate"
                  #uploads.expirationDate="ngModel"
                  [ngClass]="{ 'has-error-parent': checkDateNow }"
                ></app-month-input>
                <div
                  *ngIf="check.checkDateNow"
                  class="col-xs-10 text-danger"
                  translate
                >
                  LICENSE_NOT_VALID
                </div>
              </div>
              <div>
                <div class="col-xs-12">
                  <label class="col-xs-10 col-xs-offset-1 label-c" translate
                    >DRIVING_LICENSE_PHOTO</label
                  >
                  <app-image-input
                    [(ngModel)]="uploads.driverLicenseFront"
                    [isRequired]="true"
                    [maxImageSizeKb]="maxImagesSize"
                    name="driverLicenseFront"
                    #driverLicenseFront="ngModel"
                  >
                  </app-image-input>
                </div>

                <div class="col-xs-12">
                  <label class="col-xs-10 col-xs-offset-1 label-c" translate
                    >DRIVING_LICENSE_BACK_PHOTO</label
                  >
                  <app-image-input
                    [(ngModel)]="uploads.driverLicenseBack"
                    [isRequired]="true"
                    [maxImageSizeKb]="maxImagesSize"
                    name="driverLicenseBack"
                    #driverLicenseBack="ngModel"
                  >
                  </app-image-input>
                </div>
                <div class="col-xs-12">
                  <label class="col-xs-10 col-xs-offset-1 label-c" translate
                    >DRIVING_LICENSE_FRONT_PHOTO</label
                  >
                  <app-image-input
                    [(ngModel)]="uploads.driverLicenseSelfie"
                    [isRequired]="true"
                    [maxImageSizeKb]="maxImagesSize"
                    name="driverLicenseSelfie"
                    #driverLicenseSelfie="ngModel"
                  >
                  </app-image-input>
                </div>
                <div *ngIf="!_userService.user.isTz">
                  <div class="col-xs-12">
                    <label class="col-xs-10 col-xs-offset-1 label-c" translate>
                      PASSPORT</label
                    >
                    <app-image-input
                      [(ngModel)]="uploads.passport"
                      [isRequired]="true"
                      [maxImageSizeKb]="maxImagesSize"
                      name="passport"
                      #passport="ngModel"
                    >
                    </app-image-input>
                  </div>
                  <div class="col-xs-12">
                    <label class="col-xs-10 col-xs-offset-1 label-c" translate>
                      VISA</label
                    >
                    <app-image-input
                      [(ngModel)]="uploads.visa"
                      [isRequired]="true"
                      [maxImageSizeKb]="maxImagesSize"
                      name="visa"
                      #visa="ngModel"
                    >
                    </app-image-input>
                  </div>
                </div>
              </div>
              <div
                class="col-xs-10 col-xs-offset-1"
                style="margin-top: 25px; margin-bottom: 10px"
              >
                <button class="blue-btn-class" (click)="sendImage()" translate>
                  SAVE_PICTURES
                </button>
              </div>
            </div>
          </form>
        </ng-template>
        <ng-template [ngIf]="selectedTab === 3">
          <br />
          <form>
            <div>
              <div class="col-xs-12">
                <br />
                <div *ngIf="errMessage !== null">
                  <label class="col-xs-10 col-xs-offset-1 label-c2" translate>{{
                    errMessage
                  }}</label>
                </div>
                <br />
                <div
                  *ngIf="
                    preferncedParkings !== undefined &&
                    preferncedParkings.parkings.length > 0
                  "
                >
                  <label class="col-xs-10 col-xs-offset-1 label-c" translate
                    >תחנות מועדפות! באפשרותך לערוך את הרשימה</label
                  >
                </div>
                <div
                  *ngIf="
                    preferncedParkings === undefined ||
                    preferncedParkings.parkings.length === 0
                  "
                >
                  <label class="col-xs-10 col-xs-offset-1 label-c2" translate>
                    בחר 5 תחנות מועדפות מתוך הרשימה:</label
                  >
                </div>
                <div class="col-xs-10 col-xs-offset-1">
                  <ng-select
                    [items]="parkings"
                    [multiple]="true"
                    dir="rtl"
                    [maxSelectedItems]="5"
                    bindLabel="name"
                    [addTag]="false"
                    autofocus
                    placeholder="חפש תחנה..."
                    notFoundText="מידע לא נמצא..."
                    [(ngModel)]="selectedParkings"
                    [ngModelOptions]="{ standalone: true }"
                  ></ng-select>

                  <br />
                  <label
                    *ngIf="
                      selectedParkings.length > 0 && selectedParkings.length < 5
                    "
                    class="label-c"
                    translate
                  >
                    בחרת {{ selectedParkings.length }} תחנות, באפשרותך לבחור עוד
                    {{ 5 - selectedParkings.length }} תחנות.
                  </label>
                  <label
                    *ngIf="
                      selectedParkings.length > 0 &&
                      selectedParkings.length === 5
                    "
                    class="label-c"
                    translate
                  >
                    בחרת {{ selectedParkings.length }} תחנות:</label
                  >
                  <br />
                  <ol>
                    <li
                      class="label-c2"
                      *ngFor="let p of selectedParkings; let i = index"
                    >
                      {{ i + 1 }}. {{ p.name }}
                    </li>
                  </ol>
                </div>
                <div
                  *ngIf="check.checkDateNow"
                  class="col-xs-10 text-danger"
                  translate
                >
                  LICENSE_NOT_VALID
                </div>
              </div>

              <div
                class="col-xs-10 col-xs-offset-1"
                style="margin-top: 25px; margin-bottom: 10px"
              >
                <button
                  class="blue-btn-class"
                  (click)="savePrefernces()"
                  translate
                >
                  שמור העדפות
                </button>
              </div>
            </div>
          </form>
        </ng-template>
      </div>
    </section>
  </div>
</div>

<div>
  <div
    [ngClass]="{ 'has-error-parent': !isPictureValid }"
    class="col-xs-10 col-xs-offset-1"
  >
    <image-upload
      dir="ltr"
      [style]="customStyle"
      [buttonCaption]="takePictureText"
      [dropBoxMessage]="'גרור לכאן תמונה'"
      [clearButtonCaption]="'הסר'"
      [extensions]="['jpeg', 'png']"
      (uploadFinished)="onUploadFinished($event)"
      (removed)="onRemoved()"
    ></image-upload>
  </div>
</div>

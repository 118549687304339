import { Component, OnInit } from '@angular/core';
import { StatusService } from '../../services/status.service';
import { UserStatus } from '../../models/userStatus';
import PageTitle from '../../models/page-title';
import { currentDate } from '../../shared/function';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css'],
})
export class StatusComponent implements OnInit {
  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'PAYMENTS',
      previousUrl: '/menu',
    };
    return PageTitle;
  }

  selectedTab = 0;
  userStatus: UserStatus = new UserStatus();
  selectedIndex: number;
  refounds: any[] = new Array<any>();
  endTime: Date = currentDate();
  startTime: Date = new Date(currentDate().getFullYear() + '-01-01');
  isShowSearchComponent = false;

  public get Now(): Date {
    return new Date(Date.now());
  }

  isShowSmallFormat: boolean;

  constructor(private statusService: StatusService) { }

  ngOnInit() {
    this.statusService
      .getUserBalance(this.startTime, this.endTime)
      .subscribe((userStatus: UserStatus) => {
        console.log(userStatus);
        this.userStatus = userStatus;
        this.userStatus.balanceDetails.forEach(
          (bd) => (bd.TIME_CHARGED = new Date(bd.TIME_CHARGED))
        );
        this.userStatus.cashBanks.forEach(
          (cb) => (cb.ADDED = new Date(cb.ADDED))
        );
        console.log(this.userStatus);
        this.userStatus.coupons.forEach((c) => this.refounds.push(c));
        this.userStatus.gasFills.forEach((gf) => this.refounds.push(gf));
        this.refounds.forEach(
          (r) =>
          (r.DATE_REDEEMED =
            r.DATE_REDEEMED != undefined
              ? new Date(r.DATE_REDEEMED)
              : undefined)
        );
        this.refounds.forEach(
          (r) =>
          (r.VALIDITY_END =
            r.VALIDITY_END != undefined
              ? new Date(r.VALIDITY_END)
              : undefined)
        );
        this.refounds.forEach(
          (r) =>
          (r.COUPON_EXPIRE_DATE =
            r.COUPON_EXPIRE_DATE != undefined
              ? new Date(r.COUPON_EXPIRE_DATE)
              : undefined)
        );
      });
  }

  showMovments() {
    console.log(this.userStatus.balanceDetails);
    this.selectedTab = 0;
  }

  showRefunds() {
    console.log(this.userStatus.coupons.map((c) => c.NOTE));
    console.log(this.refounds);
    this.selectedTab = 1;
  }

  showCashBanks() {
    console.log(this.userStatus.cashBanks);
    this.selectedTab = 2;
  }

  openDetails(index: number) {
    if (this.selectedIndex != index) this.selectedIndex = index;
    else this.selectedIndex = null;
  }

  getStatus() {
    console.log(this.startTime);
    console.log(this.endTime);
    this.isShowSearchComponent = false;
    this.statusService
      .getUserBalance(this.startTime, this.endTime, true)
      .subscribe(
        (userStatus: UserStatus) => {
          this.refounds = [];
          this.userStatus = userStatus;
          this.userStatus.balanceDetails.forEach(
            (bd) => (bd.TIME_CHARGED = new Date(bd.TIME_CHARGED))
          );
          this.userStatus.cashBanks.forEach(
            (cb) => (cb.ADDED = new Date(cb.ADDED))
          );
          console.log(this.userStatus);
          this.userStatus.coupons.forEach((c) => this.refounds.push(c));
          this.userStatus.gasFills.forEach((gf) => this.refounds.push(gf));
          this.refounds.forEach(
            (r) =>
            (r.DATE_REDEEMED =
              r.DATE_REDEEMED != undefined
                ? new Date(r.DATE_REDEEMED)
                : undefined)
          );
          this.refounds.forEach(
            (r) =>
            (r.VALIDITY_END =
              r.VALIDITY_END != undefined
                ? new Date(r.VALIDITY_END)
                : undefined)
          );
          this.refounds.forEach(
            (r) =>
            (r.COUPON_EXPIRE_DATE =
              r.COUPON_EXPIRE_DATE != undefined
                ? new Date(r.COUPON_EXPIRE_DATE)
                : undefined)
          );
        },
        (error) => {
          console.log(error.ErrorResponse);
          console.log(error.CustomErrorMessage);
        }
      );
  }

  showSearchComponent() {
    this.isShowSearchComponent = true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isDate } from 'moment';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, pattern?: string): any {
    if (!isDate(value)) {
      return '';
    }

    const date = value;
    pattern = pattern.replace('yyyy', date.getUTCFullYear().toString());
    pattern = pattern.replace(
      'MM',
      this.formatTo2Places((date.getMonth() + 1).toString())
    );
    pattern = pattern.replace(
      'dd',
      this.formatTo2Places(date.getDate().toString())
    );
    pattern = pattern.replace(
      'mm',
      this.formatTo2Places(date.getUTCMinutes().toString())
    );
    pattern = pattern.replace(
      'HH',
      this.formatTo2Places(date.getHours().toString())
    );
    pattern = pattern.replace(
      'ss',
      this.formatTo2Places(date.getUTCSeconds().toString())
    );
    pattern = pattern.replace(
      'yy',
      date.getUTCFullYear().toString().slice(2, 4)
    );

    return pattern;
  }

  formatTo2Places(str: string): string {
    if (!str) {
      return '';
    }

    if (str.length === 1) {
      return '0' + str;
    }

    return str;
  }
}

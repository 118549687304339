class messages {
  NOT_HAVE_ACTIVE_ORDER: string;
  USER_NOT_LOGGED_IN: string;
  GLOBAL_ERROR_MESSAGE: string;
  GUEST_USER_RETURN_TO_HOME: string;
  END_ORDER_REPORTING: string;
}
class words {
  TO_REGISTRATION: string;
  TO_CONTINUE: string;
  IMPORTANT_MESSAGE: string;
}

class AppWords {
  HELLO: string;
  MESSAGES: messages;
  WORDS: words;
  FILL: string;
  CODE: string;

  // home
  SUBSCRIPTION: string;
  CONTACT_BY: string;
  DIAL: string;
  SUBSCRIBE: string;
  SKIP_REGISTERATION: string;
  CALCULATOR: string;
  UPDATE_DETAILS: string;

  ORDER_BY_LOCATION: string;
  CURRENT_ACTIVE_ORDER: string;
  ALL_ORDERS_DETAILS: string;
  ORDER_BY__STATIONS: string;
  USER_DETAILS: string;
  CUSTOMERS_SERVICE: string;
  DAMAGE_REPORT: string;
  YOUR_OPINION_IMPORTANT: string;
  PAYMENTS: string;
  SEARCH_BY_LOCATION_OPTIONS: string;
  CURRENT_LOCTION: string;
  REGISTERED_ADDRESS: string;

  //
  SELECT_RENTAL_TYPE: string;
  HOURLY: string;
  DAILY: string;
  LONG: string;
  EXPL: string;

  SELECT_TYPE_ORDER: string;
  START_TIME: string;
  ORDER_NOW: string;
  ORDER_SHABAT: string;
  HOURS_RENTAL: string;
  DAYS_RENTAL: string;
  LONG_RENTAL: string;
  FINISH_TIME: string;
  CONTINUE: string;

  // locationWords {
  SELECT_AREA: string;
  PARKING: string;

  // userProfile
  BACK_PERSONAL_AREA: string;
  PREFERENCES: string;
  UPDATE_LICENSE: string;
  MAIL: string;
  LOGOUT: string;
  NAME: string;
  NAME_INVOICE: string;
  CITY: string;
  STREET: string;
  CREDIT: string;
  VALIDITY_DRIVING_LICENSE: string;
  UPDATE_CHANGES: string;
  LICENSE_NOT_VALID: string;

  DRIVING_LICENSE_PHOTO: string;
  DRIVING_LICENSE_BACK_PHOTO: string;
  DRIVING_LICENSE_FRONT_PHOTO: string;
  VISA: string;
  PASSPORT: string;
  SAVE_PICTURES: string;
  UPDATE_CREDIT: string;

  // STATUS PAYMENTS
  YOUR_BALANCE: string;
  SEARCH_BY_DATES: string;
  START_RANGE: string;
  END_RANGE: string;
  SEARCHE_TRANSCTIONS: string;
  CASH: string;
  CREDITS: string;
  TRANSCTIONS: string;
  ORDER: string;
  RECEIVED_IN_ORDER: string;
  VALIDITY: string;
  EXPIRED: string;
  COUPON: string;
  IN_ORDER: string;
  FUEL_FILLING: string;
  FILLING_DATE: string;
  CREATED: string;
  AMOUNT: string;

  // CONTACT
  PHONE: string;
  EMAIL: string;
  WHATSAPP: string;
  TO_JOIN: string;
  MAIN_OFFICE: string;
  PO_BOX: string;
  TEL_CONTACT: string;
  FAX_CONTACT: string;
  EMAIL_CONTACT: string;
  REGISTRATION_HOURS: string;
  REGISTRATION_HOURS1: string;
  REGISTRATION_HOURS2: string;

  // ORDER LIST
  ACTIVE_ORDER: string;
  SEARCH_ORDERS: string;
  HISTORY: string;
  FUTURE_ORDERS: string;
  REPORT_FINISH: string;
  TIME_OF_RENT: string;
  BILLING_INFORMATION: string;
  FOR_PAYMENT: string;
  DESCRIPTION: string;
  COST: string;
  QUANTITY: string;
  TOTAL: string;
  SEND_TO_MAIL: string;
  EDIT: string;
  CANCEL: string;
  START: string;
  FINISH: string;
  FINISH_AT: string;
  FROM: string;
  NOT_FINAL_PRICE: string;
  SEND_APPLICTION_ON_ORDER: string;

  // DEMAGE-REPORT-TYPE
  SELECT_DEMAGE_TYPE: string;
  REPORT_BEFORE: string;
  REPORT_BEFORE_2: string;
  REPORT_BEFORE_3: string;
  REPORT_AFTER: string;
  REPORT_AFTER_2: string;
  REPORT_AFTER_3: string;

  // demage report
  REPORT_ORDER: string;
  PICTURE: string;

  // FINISH ORDER
  ORDER_NUM: string;
  SUCCESSFULLY: string;
  END_ORDER_REPORT: string;
  END_ORDER_REPORT2: string;
  END_ORDER_REPORT3: string;
  END_ORDER_REPORT4: string;
  PRICE_INCLUDE: string;
  CONFIRM_BTN: string;

  // ORDER DETILES
  REDUCE: string;
  REDUCE_MESSAGE: string;
  REDUCE2: string;
  YES: string;
  NO: string;
  PICKUP: string;
  AT: string;
  RETURN: string;
  PICKUP_NOW: string;
  RETURN_WHILE: string;
  NOTE_DIFF_PRICE: string;
  NOTE_DIFF_PRICE2: string;
  NOTE_DIFF_PRICE3: string;
  VIEW: string;
  HIDE: string;

  // car in rent
  CAR_RENTAL_CITY_CAR: string;
  GOOD_SAFE: string;
  CAR_NUM: string;
  CAR_AS_OF: string;
  UP_TO: string;
  CAR_RETURN: string;
  OFFICE_ORDER_TYPE: string;
  OFFICE_ORDER_REASON: string;
  RETURN_STATION: string;
  MUST_REPORT: string;
  IN_APP: string;
  IN_PHONE: string;
  WHERE_CAR: string;
  END_RENT: string;
  EXTENSION: string;
  REPORT: string;
  TAKEN: string;
  EXTENSION_MESSAGE: string;
  EXTENSION_MESSAGE2: string;
  HOURS: string;
  OK: string;
  REPORT_MESSAGE_EXT: string;
  REPORT_MESSAGE_EXT2: string;
  REPORT_MESSAGE_EXT3: string;
  FINISH_MESSAGE: string;
  FINISH_REPORT: string;
  FINISH_REPORT1: string;
  FINISH_REPORT2: string;
  FINISH_REPORT3: string;
  OPEN_DOOR: string;
  LOCK_MESSAGE: string;
  LOCK_MESSAGE2: string;
  GET_CODE: string;
  TRY_AGAIN: string;
  LOCK_MESSAGE_WEB: string;

  // STATION IN MAP
  ZOOM: string;
  NO_CARS: string;
  LOAD: string;
  AVAILABLE: string;
  CHANGE_TIMES: string;
  AVAILABLE_MESSAGE: string;
  OF: string;
  CHECK_TYPES: string;
  CLOSE: string;

  // ORDER_DATE_EDITOER
  ORDER_FOR: string;
  TO: string;
  NOW: string;

  // page title
  PREVIOUS: string;
  ORDER_BY_STATION: string;
  REPORT_DAMAGE: string;
  CONTACT_US: string;
  OPEN_NEW_ORDER: string;
  EDIT_ORDER: string;
  HOME: string;
  MENU: string;
  ORDERS: string;
  ORDER_UPDATE: string;
  STATION_MAP: string;
  PERSONAL_AREA: string;

  // calculator
  ORDER_TYPE: string;
  // HOURLY: string;
  // DAILY: string;

  EXTRA7: string;
  CAR_TYPE: string;
  SMALL: string;
  FAMILY_OR_FAMILY: string;
  SMALL_OR_FAMILY: string;
  FAMILY_PLUS: string;
  XL_7: string;

  BOOKING_HOURS: string;
  // START:string;
  END: string;
  CALCULATE: string;
  SUMMARY: string;
  TOTAL_PAYMENT: string;
  // DESCRIPTION:string;
  // COST:string;
  // AMOUNT:string;
  // TOTAL:string;
  BACK_TO_CALC: string;
  // CALCULATOR: string;
  // PREVIOUS:string;

  // register words
  PERSONAL_INFORMATION: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  DATE_OF_BIRTH: string;
  LESS_21: string;

  ISRAELI_CITIZEN: string;
  FOREIGN_CITIZEN: string;
  ISRAELI_CIT_QES: string;

  ID: string;
  PASSPORT_NO: string;
  DIGITS_ONLY: string;
  TZ_DIGITS_ONLY: string;
  DIGITS_OR_LETTERS_ONLY: string;
  HEBREW_ONLY: string;
  INVALID_TZ: string;

  COUNTRY: string;
  FULL_ADDRESS_ABROAD: string;
  PHOTOCOPY_OF_PASSPORT: string;
  PHOTOCOPY_OF_LICENSE: string;
  PHOTOCOPY_OF_LICENSE_REAR: string;
  // VISA: string;

  ADDRESS_INFORMATION: string;
  ADDRESS_INFORMATION_ISRAEL: string;
  ADDRESS_INFORMATION_ABROAD: string;
  NUMBER: string;
  // STREET: string;
  // CITY: string;
  NEIGHBORHOOD: string;

  CONTACT_INFORMATION: string;
  // EMAIL: string;
  VALID_EMAIL: string;
  MOBILE_PHONE: string;
  MOBILE_PHONE_2: string;
  HOME_PHONE: string;
  GMAIL_ALERT: string;

  DRIVER_LICENCE_DETAILES: string;
  LICENSE_VALIDITY: string;
  ISSUE_DATE: string;
  LICENSE_NUMBER: string;
  LICENSE_TYPE: string;
  ISRAELI_LICENSE: string;
  NEW_DRIVER: string;
  NEW_DRIVER_ALERT: string;
  NEW_DRIVER_ALERT_2: string;
  LICENSE_ADDRESS: string;
  PROP_TYPE: string;
  FACE_PHOTO: string;

  SET_SUB_TYPE: string;
  PLEASE_NOTE: string;
  SWITCHING: string;
  FIX_SUB: string;
  EASY_SUB: string;
  FIX_ALERT: string;
  EASY_ALERT: string;
  PERM_INSURANCE: string;
  PERM_INSURANCE_ALERT: string;
  // YES: string;
  // NO: string;

  FIX_SUB_TEXT: string;

  CREDIT_DETAILS: string;
  CREDIT_ALERT1: string;
  CREDIT_ALERT2: string;
  CREDIT_NUM: string;
  EXPIRY_DATE: string;
  CVV: string;
  CVV_DIGITS: string;
  CARDHOLDER_NAME: string;
  CARDHOLDER_ID: string;
  CARDHOLDER_PASS: string;

  CODE_COUPON: string;
  TERMS: string;
  CONFIRM: string;

  SUB_DETAILES: string;

  // WIZARD STEP
  SUBSCRIPTION_STEP: string;
}

export const appWords_en: AppWords = {
  HELLO: 'hello',
  CODE: 'ID: ',
  MESSAGES: {
    NOT_HAVE_ACTIVE_ORDER: 'you dont have an active order now',
    USER_NOT_LOGGED_IN:
      'you should connet by google account, please ensure your account is improved by the company office',
    GLOBAL_ERROR_MESSAGE: 'An error has occurred',
    GUEST_USER_RETURN_TO_HOME: 'You should login before ordering',

    END_ORDER_REPORTING: `From today it is mandatory to report
    <a>"End of Rent"</a>
    Immediately after the use of the vehicle.
    <br><br>
    App - on the <a>"End of Rent"</a> button
    <br>
    <a>*2319 </a> - ext. 9
    <br>
    <h5>Failure to report may result in additional charges<h5>`,
  },
  WORDS: {
    TO_REGISTRATION: 'to registration',
    TO_CONTINUE: 'continue',
    IMPORTANT_MESSAGE: 'important message!',
  },
  FILL: 'Fill out all the required fields',

  // home
  SUBSCRIPTION: 'Subscription',
  UPDATE_DETAILS: 'Update details',
  CONTACT_BY: 'Contact by google',
  DIAL: 'dial',
  SUBSCRIBE: 'Subscribe',
  SKIP_REGISTERATION: 'Skip Registration',
  CALCULATOR: 'Calculator',

  ORDER_BY_LOCATION: 'Order By Location',
  CURRENT_ACTIVE_ORDER: 'Current Active Order',
  ALL_ORDERS_DETAILS: 'All Orders',
  ORDER_BY__STATIONS: 'Order By Station',
  USER_DETAILS: 'User Details',
  CUSTOMERS_SERVICE: 'Customers Service',
  DAMAGE_REPORT: 'Damage Report',
  YOUR_OPINION_IMPORTANT: 'Your opinion is important!',
  PAYMENTS: 'Payments',
  SEARCH_BY_LOCATION_OPTIONS:
    'Your current location or your registered adrress?',
  CURRENT_LOCTION: 'Current location',
  REGISTERED_ADDRESS: 'Registered address',

  // selectTimeWords
  SELECT_RENTAL_TYPE: 'Select A Rental Type',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  LONG: 'Extra 7',
  EXPL: 'Explan',

  SELECT_TYPE_ORDER: 'Select Type Order',
  START_TIME: 'Start Time',
  ORDER_NOW: 'Order Now',
  ORDER_SHABAT: 'Order for Shabbas and holidays',
  HOURS_RENTAL: 'Hours',
  DAYS_RENTAL: 'Days',
  LONG_RENTAL: 'Extra 7',
  FINISH_TIME: 'Finish time',
  CONTINUE: 'Continue',

  // locationWords
  SELECT_AREA: 'Select The Area',
  PARKING: 'There are no cars available at the station',

  // userProfile
  BACK_PERSONAL_AREA: 'Back to the personal area',
  PREFERENCES: 'preferences',
  UPDATE_LICENSE: 'Update Documents',
  MAIL: 'Mail',
  LOGOUT: 'LOGOUT',
  NAME: 'Name',
  NAME_INVOICE: 'Name On Invoice',
  CITY: 'City',
  STREET: 'Street',
  CREDIT: 'Credit',
  VALIDITY_DRIVING_LICENSE: 'Validity of the driving license',
  UPDATE_CHANGES: 'Update Changes',
  LICENSE_NOT_VALID: "The driver's license is not valid",

  DRIVING_LICENSE_PHOTO: "Photocopy of driver's license",
  DRIVING_LICENSE_BACK_PHOTO: 'Driving license photo (rear side)',
  DRIVING_LICENSE_FRONT_PHOTO: "Face photo with driver's license",
  VISA: 'Entry Visa',
  PASSPORT: 'Photocopy of passport',
  SAVE_PICTURES: 'Save Pictures',
  UPDATE_CREDIT: 'Update Credit card',

  // status payments
  YOUR_BALANCE: 'Your Balance:',
  SEARCH_BY_DATES: 'Search for transactions by date range',
  START_RANGE: 'Start Range',
  END_RANGE: 'End Range',
  SEARCHE_TRANSCTIONS: 'Search Transactions',
  CASH: 'Cash',
  CREDITS: 'Credits',
  TRANSCTIONS: 'Transctions',
  ORDER: 'Order',
  RECEIVED_IN_ORDER: 'Received In Order:',
  VALIDITY: 'Validity:',
  EXPIRED: 'Credit Expired',
  COUPON: 'Coupon - ',
  IN_ORDER: 'in Order:',
  FUEL_FILLING: 'Fuel Filling',
  FILLING_DATE: 'Filling Date',
  CREATED: 'Created',
  AMOUNT: 'Amount:',

  // CONTACT
  PHONE: 'Phone',
  EMAIL: 'Email',
  WHATSAPP: "Whats'App",
  TO_JOIN: 'To join:',
  MAIN_OFFICE: 'main office:',
  PO_BOX: 'PO Box 310 Bney Brak',
  TEL_CONTACT: 'Tel *2319',
  FAX_CONTACT: 'Fax 076-5105106',
  EMAIL_CONTACT: 'Email citycar@citycar.co.il',
  REGISTRATION_HOURS: 'registration hours',
  REGISTRATION_HOURS1: 'Sunday to Thursday from 9:00 to 17:00',
  REGISTRATION_HOURS2: 'Friday and holiday eves 9:00 to 12:00',

  // order list
  ACTIVE_ORDER: 'Active Order',
  SEARCH_ORDERS: 'Search',
  HISTORY: 'History',
  FUTURE_ORDERS: 'Future Orders',
  REPORT_FINISH: 'Report Finish at',
  TIME_OF_RENT: 'Time of Rent',
  FOR_PAYMENT: 'For Payment',
  BILLING_INFORMATION: 'View billing details',
  DESCRIPTION: 'Description',
  COST: 'Cost',
  QUANTITY: 'Quantity',
  TOTAL: 'Total',
  SEND_TO_MAIL: 'Send me an email order detail',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  START: 'START',
  FINISH: 'FINISH',
  FINISH_AT: 'Finish at',
  FROM: 'From',
  NOTE_DIFF_PRICE: 'Note',
  NOTE_DIFF_PRICE2: 'This order is at a different rate valid these days',
  NOTE_DIFF_PRICE3: 'with changes in operating hours and daily rent',
  NOT_FINAL_PRICE: 'Not final price',
  SEND_APPLICTION_ON_ORDER: 'Send an email to customer service on this order',

  // demage-report-type
  SELECT_DEMAGE_TYPE: 'Select the type of damage report',
  REPORT_BEFORE: 'Report damage before beginning of rental',
  REPORT_BEFORE_2:
    'In this report you are exempt from payment for the reported damage',
  REPORT_BEFORE_3:
    'Damage can be reported from 10 minutes prior to the beginning of the rental time up to the actual driving time.',
  REPORT_AFTER: 'Self damage was reported after the beginning of the rental',
  REPORT_AFTER_2:
    'If damage is caused during your rental, please report it here.',
  REPORT_AFTER_3:
    'You can report from the rental time up to two hours after the rental time.',

  // demage-report
  REPORT_ORDER: 'Report damage on order number:',
  PICTURE: 'Take a Picture',

  // FINISH ORDER
  ORDER_NUM: 'order number',
  SUCCESSFULLY: 'Your order has been successfully approved',
  END_ORDER_REPORT: "You must report an 'End Order'",
  END_ORDER_REPORT2: "When you're done!",
  END_ORDER_REPORT3: 'In the app - "End of Rent" button',
  END_ORDER_REPORT4: 'Or *2319 - Extension 9',
  CONFIRM_BTN: 'Confirm',

  // order detiles
  REDUCE: 'Reduce deductible in case of damage or theft',
  REDUCE_MESSAGE: `With the addition of 5 NIS per hour / 45 NIS per day, you will reduce your deductible amount,
   In the event of damage, you will be charged a deductible of up to a total of only 300 NIS.`,
  REDUCE2: 'Reduction of deductible:',
  YES: 'Yes',
  NO: 'No',
  PICKUP: 'Pickup: ',
  AT: 'at',
  RETURN: 'Return: ',
  PICKUP_NOW: 'Pickup: Now',
  RETURN_WHILE: 'Return: While',
  VIEW: 'View details',
  HIDE: 'Hide Details',

  // CAR IN RENT
  CAR_RENTAL_CITY_CAR: 'Car rental City-Car',
  GOOD_SAFE: 'Good and safe trip!',
  CAR_NUM: 'Car number',
  CAR_AS_OF: 'You have the car as of ',
  UP_TO: 'Up to',
  CAR_RETURN: 'You must return at',
  RETURN_STATION: 'station',
  OFFICE_ORDER_TYPE: 'Office order type',
  OFFICE_ORDER_REASON: 'Order reason',
  MUST_REPORT: 'Rent finish must be reported immediately after use!',
  IN_APP: 'In the app - "End of Rent" button',
  IN_PHONE: 'Or in *2319 - ext. 9',
  WHERE_CAR: 'Where is the car?',
  END_RENT: 'End of Rent',
  EXTENSION: 'Rent extension',
  REPORT: 'Report overdue',
  TAKEN:
    'The times you requested are taken, the vehicle must be in the station from',
  EXTENSION_MESSAGE: 'You may extend your order up to this time at a cost of',
  HOURS: 'Hours',
  EXTENSION_MESSAGE2: 'You can only extend your order by',
  OK: 'Ok',
  REPORT_MESSAGE_EXT:
    'In order to avoid being charged a NIS 30 fine, please report before the end of the rental period.',
  REPORT_MESSAGE_EXT2: 'How many minutes are you going to be late?',
  REPORT_MESSAGE_EXT3: 'How many hours do you want to extend your rental time?',
  FINISH_MESSAGE: 'Have you finished using the vehicle?',
  FINISH_REPORT: 'Inform us of the termination of a rental',
  FINISH_REPORT1: 'Report',
  FINISH_REPORT2: 'Report',
  FINISH_REPORT3: 'Report',
  OPEN_DOOR: 'Open door',
  LOCK_MESSAGE:
    'To lock the car, click on the lock icon on the board that is attached to the door of the car until it locks',
  LOCK_MESSAGE2: 'Ensure the lock before leaving the vehicle',
  GET_CODE: 'Get the code',
  TRY_AGAIN: 'Try again',
  LOCK_MESSAGE_WEB: 'Opening the vehicle is possible through the application',

  // station in map
  ZOOM: 'Zoom in on the map to load the station details',
  NO_CARS: 'No vehicles were found',
  LOAD: 'did not load station details',
  AVAILABLE: 'The car is not available for some of the hours you requested',
  CHANGE_TIMES: 'Change times',
  AVAILABLE_MESSAGE: 'The car is not available for the times you requested',
  OF: 'of',
  CHECK_TYPES: 'Check the types of vehicles you want to search',
  CLOSE: 'Close',

  // ORDER_DATE_EDITOER
  ORDER_FOR: 'Order for: ',
  TO: ' To',
  NOW: ' Now',

  // page title
  PREVIOUS: 'Back',
  ORDER_BY_STATION: 'Order by Station',
  REPORT_DAMAGE: 'Report Damage',
  CONTACT_US: 'Contact Us',
  OPEN_NEW_ORDER: 'Open a New Order',
  EDIT_ORDER: 'Edit Order',
  HOME: 'Home',
  MENU: 'Menu',
  ORDERS: 'Orders',
  ORDER_UPDATE: 'Order Update',
  STATION_MAP: 'Station Map',
  PERSONAL_AREA: 'Personal Area',

  // calculator
  ORDER_TYPE: 'Order Type',
  EXTRA7: 'extra 7',
  CAR_TYPE: 'select a car type',
  SMALL: 'small',
  FAMILY_OR_FAMILY: '+family or family',
  SMALL_OR_FAMILY: 'small or family',
  FAMILY_PLUS: 'family plus',
  XL_7: ' XL | 7 seats',
  BOOKING_HOURS: 'booking hours',
  END: 'end',
  CALCULATE: 'calculate',
  SUMMARY: 'summary',
  TOTAL_PAYMENT: 'total payment',
  BACK_TO_CALC: 'back to the calculator',


  // register words
  PERSONAL_INFORMATION: 'Personal Information',
  FIRST_NAME: 'first name*',
  LAST_NAME: 'last name*',
  DATE_OF_BIRTH: 'date of birth*',
  LESS_21:
    'Note! It is not possible to register for a young driver under the age of 21',

  ISRAELI_CITIZEN: 'israeli citizen',
  FOREIGN_CITIZEN: 'foreign citizen',
  ISRAELI_CIT_QES: 'Do you have Israeli citizenship?',

  ID: 'id *',
  PASSPORT_NO: 'passport no. *',
  DIGITS_ONLY: 'digits only',
  TZ_DIGITS_ONLY: '9 digits only',
  DIGITS_OR_LETTERS_ONLY: 'digits or letters only',
  INVALID_TZ: 'invalid identity number',
  HEBREW_ONLY: 'fill this field only in Hebrew',

  COUNTRY: 'country *',
  FULL_ADDRESS_ABROAD: 'full address abroad *',
  PHOTOCOPY_OF_PASSPORT: 'Photocopy of passport *',
  PHOTOCOPY_OF_LICENSE: "Photocopy of driver's license *",
  PHOTOCOPY_OF_LICENSE_REAR: "Photocopy of driver's license (rear)*",
  // VISA: 'entry visa *',

  ADDRESS_INFORMATION: 'Address information',
  ADDRESS_INFORMATION_ISRAEL: 'Address information in Israel',
  ADDRESS_INFORMATION_ABROAD: 'Address information in abroad',
  NUMBER: 'no.*',
  // STREET: 'st.*',
  // CITY: 'city *',
  NEIGHBORHOOD: 'neighborhood *',

  CONTACT_INFORMATION: 'Contact Information',
  // EMAIL: 'Email *',
  VALID_EMAIL: 'Enter a valid email address',
  MOBILE_PHONE: 'mobile phone *',
  MOBILE_PHONE_2: 'another mobile phone *',
  HOME_PHONE: 'Home phone *',
  GMAIL_ALERT: 'To use the application You must sign up with a GMAIL account',

  DRIVER_LICENCE_DETAILES: 'Driver license details',
  LICENSE_VALIDITY: 'license validity *',
  ISSUE_DATE: 'issue date*',
  LICENSE_NUMBER: 'license number*',
  LICENSE_TYPE: 'license type*',
  ISRAELI_LICENSE: "israeli driver's license only",
  NEW_DRIVER: 'are you a new driver?',
  NEW_DRIVER_ALERT: 'prices for new driver are different than usual',
  NEW_DRIVER_ALERT_2:
    'You are a new driver for less than a year. Unable to register',
  LICENSE_ADDRESS: 'license address*',
  PROP_TYPE: 'propulsion type',
  FACE_PHOTO: "face image with driver's license *",

  SET_SUB_TYPE: 'Set subscription type',
  PLEASE_NOTE: 'Please note!',
  SWITCHING: 'Switching between programs entails a fee of 10',
  FIX_SUB: 'fixed subscription',
  EASY_SUB: 'easy subscription (for free)',
  FIX_ALERT: 'What is a fixed subscription?',
  EASY_ALERT: 'What is easy subscription?',
  PERM_INSURANCE: 'Do you want permanent insurance?',
  PERM_INSURANCE_ALERT: 'What is permanent insurance?',
  // YES: 'yes',
  //  NO: 'no',

  FIX_SUB_TEXT:
    'fixed subscription, is a 10₪ monthly payment. This track designed for people who needs to travel several times a month, and prefer to hold the existing subscription, for a peace of mind and for financial saving.',

  CREDIT_DETAILS: 'Credit Details',
  CREDIT_ALERT1: '',
  CREDIT_ALERT2: '',
  CREDIT_NUM: 'credit number*',
  EXPIRY_DATE: 'expiry date*',
  CVV: 'security Code*',
  CVV_DIGITS: 'insert up to 4 digits',
  CARDHOLDER_NAME: "cardholder's name*",
  CARDHOLDER_ID: "cardholder's ID number*",
  CARDHOLDER_PASS: "cardholder's passport number*",

  CODE_COUPON: 'coupon code',
  TERMS: 'terms and conditions',
  CONFIRM: 'CONFIRM',

  SUB_DETAILES: 'Subscription Details',

  // WIZARD STEP
  SUBSCRIPTION_STEP: 'Subscription',
  PRICE_INCLUDE: 'The price does not include payment for kilometers',
};

export const appWords_he: AppWords = {
  HELLO: 'שלום',
  CODE: 'קוד לקוח: ',
  MESSAGES: {
    NOT_HAVE_ACTIVE_ORDER: 'אין לך הזמנה פעילה כרגע',
    USER_NOT_LOGGED_IN:
      'יש להתחבר באמצעות חשבון google בלבד, נא וודא שחשבון gmail שלך מאושר במשרדי החברה',
    GLOBAL_ERROR_MESSAGE: 'אירעה שגיאה, נסה שוב',
    GUEST_USER_RETURN_TO_HOME: 'הנך נדרש להרשם לפני ביצוע הזמנה',
    END_ORDER_REPORTING: `מהיום חובה לדווח <a>"סיום השכרה"</a> מיד בתום השימוש ברכב.
    <br>
    <br> באפליקציה - בכפתור <a>"סיום השכרה"</a>
    <br>או ב<a>2319*</a> - שלוחה 9
    <br>
    <br>
    <h5> אי דיווח עלול לגרום לחיובים נוספים<h5>`,
  },
  WORDS: {
    TO_REGISTRATION: 'להרשמה',
    TO_CONTINUE: 'להמשך גלישה',
    IMPORTANT_MESSAGE: 'הודעה חשובה!',
  },
  FILL: 'מלא את כל השדות הדרושים',

  // home
  SUBSCRIPTION: 'להרשמה',
  CONTACT_BY: 'מנוי קיים',
  UPDATE_DETAILS: 'עדכון פרטים',
  DIAL: 'חייג',
  SUBSCRIBE: 'להרשמה',
  SKIP_REGISTERATION: 'צא לסיבוב וירטואלי',
  CALCULATOR: 'מחשבון נסיעה',

  ORDER_BY_LOCATION: 'הזמנה לפי מיקום',
  CURRENT_ACTIVE_ORDER: 'הזמנה פעילה',
  ALL_ORDERS_DETAILS: 'פירוט הזמנות',
  ORDER_BY__STATIONS: 'הזמנה לפי תחנה',
  USER_DETAILS: 'פרטי משתמש',
  CUSTOMERS_SERVICE: 'שירות לקוחות',
  DAMAGE_REPORT: 'דווח נזק',
  YOUR_OPINION_IMPORTANT: 'דעתכם חשובה לנו!',
  PAYMENTS: 'החיובים שלי',
  SEARCH_BY_LOCATION_OPTIONS: 'מיקומך הנוכחי או לפי הכתובת שאיתה נרשמת למערכת?',
  CURRENT_LOCTION: 'מיקום נוכחי',
  REGISTERED_ADDRESS: 'כתובתי במערכת',

  // selectTimeWords
  SELECT_RENTAL_TYPE: 'בחר סוג השכרה',
  HOURLY: 'שעתי',
  DAILY: 'יומי',
  LONG: 'אקסטרה 7',
  EXPL: 'להסבר',

  SELECT_TYPE_ORDER: 'בחר סוג השכרה',
  START_TIME: 'זמן התחלה',
  ORDER_NOW: 'הזמן לעכשיו',
  ORDER_SHABAT: 'הזמנת שבת וחג',
  HOURS_RENTAL: 'שעות',
  DAYS_RENTAL: 'ימים',
  LONG_RENTAL: 'אקסטרה 7',
  FINISH_TIME: 'זמן סיום',
  CONTINUE: 'המשך',

  // locationWords_he
  SELECT_AREA: 'בחר את האיזור הרצוי',
  PARKING: 'אין רכבים זמינים בתחנה',

  // userProfile
  BACK_PERSONAL_AREA: 'חזרה לאיזור האישי',
  MAIL: 'דואר',
  PREFERENCES: 'העדפות',
  UPDATE_LICENSE: 'עדכון רישיון',
  LOGOUT: 'התנתק',
  NAME: 'שם',
  NAME_INVOICE: 'שם בחשבונית',
  CITY: 'עיר',
  STREET: 'רחוב',
  CREDIT: 'אשראי',
  VALIDITY_DRIVING_LICENSE: 'תוקף רישיון הנהיגה',
  UPDATE_CHANGES: 'עדכן שינויים',
  LICENSE_NOT_VALID: 'רשיון הנהיגה אינו בתוקף',
  DRIVING_LICENSE_PHOTO: 'צילום רישיון הנהיגה',
  DRIVING_LICENSE_BACK_PHOTO: 'צילום רישיון הנהיגה (צד אחורי)',
  DRIVING_LICENSE_FRONT_PHOTO: 'צילום פנים (סלפי) עם רישיון הנהיגה',
  VISA: 'אשרת כניסה',
  PASSPORT: 'צילום דרכון',
  SAVE_PICTURES: 'שמירת תמונות',
  UPDATE_CREDIT: ' עדכון אשראי',

  // status payments
  YOUR_BALANCE: 'יתרתך:',
  SEARCH_BY_DATES: 'חפש תנועות לפי טווח תאריכים',
  START_RANGE: 'טווח התחלה',
  END_RANGE: 'טווח סיום',
  SEARCHE_TRANSCTIONS: 'חפש תנועות',
  CASH: 'מזומן',
  CREDITS: 'זיכויים',
  TRANSCTIONS: 'תנועות',
  ORDER: 'הזמנה',
  RECEIVED_IN_ORDER: 'זוכה בהזמנה:',
  VALIDITY: 'תוקף:',
  EXPIRED: 'פג תוקף הזיכוי',
  COUPON: 'קופון - ',
  IN_ORDER: 'בהזמנה: ',
  FUEL_FILLING: 'מילוי דלק',
  FILLING_DATE: 'תאריך מילוי',
  CREATED: 'נוצר:',
  AMOUNT: 'סכום:',

  // CONTACT
  PHONE: 'בטלפון',
  EMAIL: 'במייל',
  WHATSAPP: 'בוואטסאפ',
  TO_JOIN: 'להצטרפות:',
  MAIN_OFFICE: 'משרד ראשי:',
  PO_BOX: ' ת.ד. 310 בני ברק ',
  TEL_CONTACT: 'טל 2319* ',
  FAX_CONTACT: 'פקס 076-5105106',
  EMAIL_CONTACT: 'מייל citycar@citycar.co.il',
  REGISTRATION_HOURS: 'שעות הפעילות במוקד ההרשמה:',
  REGISTRATION_HOURS1: 'ימים א-ה 9:00 עד 17:00',
  REGISTRATION_HOURS2: 'יום ו’ וערבי חג 9:00 עד 12:00',

  // ORDER LIST
  ACTIVE_ORDER: 'הזמנה פעילה',
  SEARCH_ORDERS: 'חפש הזמנות',
  FUTURE_ORDERS: 'הזמנות עתידיות',
  HISTORY: 'היסטוריה',
  REPORT_FINISH: 'דווח סיום ב',
  TIME_OF_RENT: 'זמן השכרה',
  FOR_PAYMENT: 'לתשלום',
  BILLING_INFORMATION: 'הצג פרטי חיוב',
  DESCRIPTION: 'תיאור',
  COST: 'עלות',
  QUANTITY: 'כמות',
  TOTAL: 'סה"כ',
  SEND_TO_MAIL: 'שלח לי פירוט נסיעה למייל',
  EDIT: 'ערוך',
  CANCEL: 'בטל',
  START: 'התחלה',
  FINISH: 'סיום',
  FINISH_AT: 'סיום ב',
  FROM: 'החל מ',
  NOT_FINAL_PRICE: 'מחיר לא סופי',
  SEND_APPLICTION_ON_ORDER: 'שלח פניה למוקד על הזמנה זו',

  // demage-report-type
  SELECT_DEMAGE_TYPE: 'בחר את סוג דווח הנזק',
  REPORT_BEFORE: 'דווח נזק לפני תחילת ההשכרה',
  REPORT_BEFORE_2: 'בדווח זה הינך פטור מתשלום עבור הנזק המדווח',
  REPORT_BEFORE_3:
    'ניתן לדווח נזק החל מ10 דקות לפני תחילת זמן ההשכרה ועד זמן ההנעה בפועל.',
  REPORT_AFTER: 'דווח נזק עצמי לאחר תחילת ההשכרה',
  REPORT_AFTER_2: 'במידה ונגרם נזק בזמן ההשכרה שלך, נא דווח לנו כאן.',
  REPORT_AFTER_3: 'ניתן לדווח החל מזמן ההשכרה ועד שעתיים אחרי זמן ההשכרה.',

  // demage report
  REPORT_ORDER: 'דיווח נזק בהזמנה מספק: ',
  PICTURE: 'צלם תמונה',

  // FINISH ORDER
  ORDER_NUM: 'הזמנה מספר',
  SUCCESSFULLY: 'הזמנתך אושרה בהצלחה',
  END_ORDER_REPORT: "חובה לדווח 'סיום הזמנה'",
  END_ORDER_REPORT2: 'בתום השימוש!',
  END_ORDER_REPORT3: 'באפליקציה - כפתור "לסיום השכרה"',
  END_ORDER_REPORT4: ' או ב-2319* - שלוחה 9',
  PRICE_INCLUDE: 'המחיר אינו כולל תשלום עבור ק"מ',
  CONFIRM_BTN: 'אישור הזמנה',

  // ORDER DETAILES
  REDUCE: 'הקטנת השתתפות עצמית במקרה נזק או גניבה',
  REDUCE_MESSAGE: `בתוספת של 5 ש"ח לשעה / 45 ש"ח ליום תקטין את סכום ההשתתפות העצמית שלך,
   במקרה של נזק תיגבה ממך השתתפות עצמית עד סך 300 ש"ח בלבד.`,
  REDUCE2: ' הקטנת השתתפות עצמית:',
  YES: 'כן',
  NO: 'לא',
  PICKUP: 'איסוף: ',
  AT: ' בשעה ',
  RETURN: 'החזרה: ',
  PICKUP_NOW: 'איסוף: עכשיו',
  RETURN_WHILE: 'החזרה: בעוד',
  NOTE_DIFF_PRICE: 'לקוח יקר שים לב',
  NOTE_DIFF_PRICE2:
    'שים לב לשינוי מחירים ממחירון החברה בתאריכי הזמנתך, המחיר המעודכן יוצג בסיכום ההזמנה',
  NOTE_DIFF_PRICE3: 'לקבלת מחירון לתאריכים המבוקשים ניתן לפנות למוקד 2319*',
  VIEW: 'הצג פרטים',
  HIDE: 'הסתר פרטים',

  // car in rent
  CAR_RENTAL_CITY_CAR: 'רכב בשכירות סיטי-קאר',
  GOOD_SAFE: 'נסיעה טובה ובטוחה!',
  CAR_NUM: "רכב מס'",
  CAR_AS_OF: ' הרכב נמצא אצלך החל מ',
  UP_TO: 'עד ל',
  CAR_RETURN: 'עליך להחזיר את הרכב ב',
  RETURN_STATION: 'תחנת הרכב',
  OFFICE_ORDER_TYPE: 'סוג נסיעת משרד',
  OFFICE_ORDER_REASON: 'סיבת נסיעת משרד',
  MUST_REPORT: "חובה לדווח 'סיום השכרה' מיד בתום השימוש!",
  IN_APP: 'באפליקציה - כפתור "לסיום השכרה"',
  IN_PHONE: 'או ב-2319* - שלוחה 9',
  WHERE_CAR: 'איפה הרכב?',
  END_RENT: 'לסיום השכרה',
  EXTENSION: 'להארכת השכרה',
  REPORT: 'דווח על איחור',
  TAKEN: 'הזמנים שביקשת תפוסים, על הרכב להיות בתחנה החל מ',
  EXTENSION_MESSAGE: 'תוכל להאריך את ההזמנה עד לזמן זה בעלות של ',
  HOURS: 'שעות',
  EXTENSION_MESSAGE2: 'תוכל להאריך את הזמנתך רק ב',
  OK: 'אישור',
  REPORT_MESSAGE_EXT:
    ' על מנת להימנע מחיוב קנס של 30 ש"ח נא לדווח לפני סוף זמן השכירות. החיוב ייעשה לפי זמן השכירות בפועל.',
  REPORT_MESSAGE_EXT2: 'בכמה דקות בכוונתך לאחר?',
  REPORT_MESSAGE_EXT3: 'בכמה שעות תרצה להאריך את זמן ההשכרה?',
  FINISH_MESSAGE: 'סיימת להשתמש ברכב?',
  FINISH_REPORT: 'דווח לנו על סיום השכרה',
  FINISH_REPORT1: 'דווח',
  FINISH_REPORT2: 'דווח סיום - לא הודבק',
  FINISH_REPORT3: 'סמן כהודבק',
  OPEN_DOOR: ' פתיחת דלת',
  LOCK_MESSAGE:
    'לנעילת הרכב לחצו על אייקון המנעול שעל הקודן המוצמד לדלת הרכב עד לנעילה',
  LOCK_MESSAGE2: 'חובה לוודא נעילה לפני עזיבת הרכב!',
  GET_CODE: 'קבל את קוד הרכב',
  TRY_AGAIN: 'נסה שנית',
  LOCK_MESSAGE_WEB: 'פתיחת הרכב מתאפשרת באמצעות האפליקציה',

  // station in map
  ZOOM: 'קרב את הזום של המפה על מנת לטעון את פרטי התחנות',
  NO_CARS: 'לא נמצאו רכבים',
  LOAD: 'לא נטענו פרטי תחנה',
  AVAILABLE: 'הרכב אינו זמין בחלק מהשעות שבקשת',
  CHANGE_TIMES: 'שנה זמנים',
  AVAILABLE_MESSAGE: 'הרכב אינו זמין לזמנים שביקשת',
  OF: 'מתוך',
  CHECK_TYPES: 'סמן את סוגי הרכב שברצונך לחפש',
  CLOSE: 'סגור',

  // ORDER_DATE_EDITOER
  ORDER_FOR: 'הזמנה ל: ',
  TO: 'עד: ',
  NOW: 'עכשיו',

  // page title
  PREVIOUS: 'הקודם',
  ORDER_BY_STATION: 'הזמנה לפי תחנה',
  REPORT_DAMAGE: 'דווח נזק',
  CONTACT_US: 'צור איתנו קשר',
  OPEN_NEW_ORDER: 'פתיחת הזמנה חדשה',
  EDIT_ORDER: 'עריכת הזמנה',
  HOME: 'מסך הבית',
  MENU: 'תפריט ראשי',
  ORDERS: 'הזמנות',
  ORDER_UPDATE: 'עדכון הזמנה',
  STATION_MAP: 'מפת תחנות',
  PERSONAL_AREA: 'איזור אישי',

  // calculator
  ORDER_TYPE: 'סוג הזמנה',
  EXTRA7: 'אקסטרה 7',
  CAR_TYPE: 'בחר סוג רכב',
  SMALL: 'קטן',
  FAMILY_OR_FAMILY: 'משפחתי או משפחתי פלוס',
  SMALL_OR_FAMILY: 'קטן או משפחתי',
  FAMILY_PLUS: 'משפחתי פלוס',
  XL_7: '7 מקומות | XL',
  BOOKING_HOURS: 'שעות הזמנה',
  END: 'סיום',
  CALCULATE: 'חישוב עלות נסיעה',
  SUMMARY: 'סיכום',
  TOTAL_PAYMENT: 'סה"כ לתשלום',
  BACK_TO_CALC: 'חזרה למחשבון',


  // register words
  PERSONAL_INFORMATION: 'פרטים אישיים',
  FIRST_NAME: 'שם פרטי*',
  LAST_NAME: 'שם משפחה*',
  DATE_OF_BIRTH: 'תאריך לידה*',
  LESS_21: 'שים לב! אין אפשרות להירשם לנהג צעיר מתחת לגיל 21',

  ISRAELI_CITIZEN: 'אזרח ישראל',
  FOREIGN_CITIZEN: 'אזרח חוץ',
  ISRAELI_CIT_QES: 'האם ישלך אזרחות ישראלית?',

  ID: 'ת.ז.*',
  PASSPORT_NO: "מס' דרכון *",
  DIGITS_ONLY: 'מלא ספרות בלבד',
  TZ_DIGITS_ONLY: 'מלא 9 ספרות בלבד',
  DIGITS_OR_LETTERS_ONLY: 'מלא ספרות או אותיות בלבד',
  INVALID_TZ: 'מספר זהות לא תקין',
  HEBREW_ONLY: 'מלא שדה זה בעברית',

  COUNTRY: 'מדינה *',
  FULL_ADDRESS_ABROAD: 'כתובת מגורים מלאה בחו"ל *',
  PHOTOCOPY_OF_PASSPORT: 'צילום דרכון *',
  PHOTOCOPY_OF_LICENSE: 'צילום רישיון נהיגה *',
  PHOTOCOPY_OF_LICENSE_REAR: 'צילום רישיון נהיגה(צד אחורי) *',
  // VISA: 'אשרת כניסה*',

  ADDRESS_INFORMATION: 'פרטי מגורים',
  ADDRESS_INFORMATION_ISRAEL: 'פרטי מגורים בארץ',
  ADDRESS_INFORMATION_ABROAD: 'פרטי מגורים בחו"ל',
  NUMBER: 'מספר*',
  // STREET: 'רחוב*',
  // CITY: 'עיר *',
  NEIGHBORHOOD: 'שכונה *',

  CONTACT_INFORMATION: 'פרטי התקשרות',
  // EMAIL: 'דואר אלקטרוני *',
  VALID_EMAIL: 'הכנס כתובת דואר אלקטרוני תקינה',
  MOBILE_PHONE: 'טלפון נייד *',
  MOBILE_PHONE_2: 'טלפון נייד נוסף *',
  HOME_PHONE: 'טלפון בבית *',
  GMAIL_ALERT: 'כדי להשתמש באפליקצייה בעתיד, עליך להרשם באמצעות חשבון GMAIL',

  DRIVER_LICENCE_DETAILES: 'פרטי רישיון נהיגה',
  LICENSE_VALIDITY: 'תוקף רישיון *',
  ISSUE_DATE: 'תאריך הנפקת הרישיון *',
  LICENSE_NUMBER: 'מספר רישיון*',
  LICENSE_TYPE: 'סוג רישיון *',
  ISRAELI_LICENSE: 'רישיון ישראלי בלבד',
  NEW_DRIVER: '?האם אתה נהג חדש',
  NEW_DRIVER_ALERT: 'המחירים לנהג חדש שונים מהרגיל',
  NEW_DRIVER_ALERT_2: 'הנך נהג חדש פחות משנה. אין אפשרות לרישום',
  LICENSE_ADDRESS: 'כתובת רישיון*',
  PROP_TYPE: 'סוג הנעה*',
  FACE_PHOTO: 'צילום פנים עם רישיון הנהיגה*',

  SET_SUB_TYPE: 'הגדר סוג מנוי',
  PLEASE_NOTE: 'לתשומת ליבך!',
  SWITCHING: 'מעבר בין מסלולים כרוך בתשלום חד פעמי של 10',
  FIX_SUB: 'מנוי קבוע',
  EASY_SUB: 'מנוי קל (בחינם)',
  FIX_ALERT: 'מהו מנוי קבוע?',
  EASY_ALERT: 'מהו מנוי קל?',
  PERM_INSURANCE: 'האם אתה מעוניין בביטוח תמידי?',
  PERM_INSURANCE_ALERT: 'מהו ביטוח תמידי?',
  // YES: 'כן',
  // NO: 'לא',

  FIX_SUB_TEXT:
    'מנוי קבוע הינו מנוי רגיל בעלות של 10 ₪ דמי מנוי חודשיים. המסלול הזה נועד לאנשים שיוצאים לנסיעות מספר פעמים בחודש, ומעדיפים להחזיק במנוי קיים בשל השקט הנפשי והחיסכון הכספי.',

  CREDIT_DETAILS: 'פרטי אשראי',
  CREDIT_ALERT1:
    'הכנס כרטיס אשראי קרדיט (ולא חיוב מיידי) על שם המנוי או בן הזוג בלבד',
  CREDIT_ALERT2:
    'בסיום הרישום המערכת תבצע תפיסת מסגרת על סך 1000 ₪ אך ללא גבייה בפועל',
  CREDIT_NUM: 'מספר אשראי*',
  EXPIRY_DATE: 'תוקף אשראי*',
  CVV: 'ספרות בגב הכרטיס*',
  CVV_DIGITS: 'הכנס עד 4 ספרות',
  CARDHOLDER_NAME: 'שם בעל הכרטיס*',
  CARDHOLDER_ID: 'ת.ז. של בעל הכרטיס*',
  CARDHOLDER_PASS: 'מספר דרכון של בעל הכרטיס*',

  CODE_COUPON: 'קוד קופון',
  TERMS: 'תקנון ותנאי שימוש',
  CONFIRM: 'מאשר',

  SUB_DETAILES: 'פרטי מנוי',

  // WIZARD STEP
  SUBSCRIPTION_STEP: 'פרטי מנוי',
};

export const words_en = [appWords_en].reduce(mergeObjects, {});

export const words_he = [appWords_he].reduce(mergeObjects, {});

function mergeObjects(acc, x) {
  for (const key in x) acc[key] = x[key];
  return acc;
}

<div id="page-title">
  <a
    style="right: 3px !important"
    id="GoBack"
    class="linkBack"
    [routerLink]="['/order/selectTime']"
  ></a>
  <div class="dateContainer">
    <div class="top-order" *ngIf="!isOrderForNow">
      <b translate>{{ 'ORDER_FOR' | translate }}</b>
      {{ orderTimeAmount }} {{ order.periodName }}
      <b translate> {{ 'FROM' | translate }}</b>
      {{ order.startTime | customDate: 'HH:mm dd/MM/yyyy' }}
      <b translate> {{ 'TO' | translate }}</b>
      {{ order.endTime | customDate: 'HH:mm dd/MM/yyyy' }}
    </div>

    <div class="top-order" *ngIf="isOrderForNow">
      <span class="text-top-order-a" translate
        >{{ 'ORDER_FOR' | translate }}
      </span>
      {{ order.timeAmount }} {{ order.periodName }}
      <span class="text-top-order" translate> {{ 'FROM' | translate }}</span
      >{{ 'NOW' | translate }}
      <span class="text-top-order" translate> {{ 'TO' | translate }}</span>
      {{ order.timeAmount }} {{ order.periodName }}
    </div>
</div>
